import React, { useState , useRef, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import { BorderColor } from '@mui/icons-material';
// import Ma
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: "20px",
};
const center = {
  lat: 13.73537156171985,
  lng: 100.52735703327586,
};

export default function GetLatLngForUpload({
  setAssetLocationLatitude,
  setAssetLocationLongitude,
  asset_location_latitude,
  asset_location_longitude,
  LatDataUserClickBeforeExitPage,
  LongDataUserClickBeforeExitPage,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });


  const [LocationDataUserClickBeforeExitPage, setLocationDataUserClickBeforeExitPage] = useState(null);
  console.log(LocationDataUserClickBeforeExitPage)
  useEffect(() => {
    if (LatDataUserClickBeforeExitPage !== "" && LongDataUserClickBeforeExitPage !== "") {
      console.log("test useeffect lat data beforoe exit")
      setLocationDataUserClickBeforeExitPage({
        lat: parseFloat(LatDataUserClickBeforeExitPage),
        lng: parseFloat(LongDataUserClickBeforeExitPage),
      });
    }
  }, [LatDataUserClickBeforeExitPage, LongDataUserClickBeforeExitPage]);



  const [markerPosition, setMarkerPosition] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({});
  const mapRef = useRef();

const [isShowModalMap, setIsShowModalMap] = useState(false);

  const handleSelectTypeOfMap = () => {
    setIsShowModalMap(!isShowModalMap);
  };
  const [mapType, setMapType] = useState("terrain");
  console.log(mapType)
  const changeMapType = async (mapType) => {
    await localStorage.removeItem("mapTypeId");
    await setMapType(mapType);
    await localStorage.setItem("mapTypeId", mapType);
    handleSelectTypeOfMap();
  };

  const mapTypeLocal = localStorage.getItem("mapTypeId");
  // Modal Map Type
  // Map Type

  // Search Filther
  // useEffect(() => {
  //   setMarkerPosition({ asset_location_latitude, asset_location_longitude });
  // }, [asset_location_latitude || asset_location_longitude]);
  



  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';
  const handleClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    console.log('Latitude:', lat, 'Longitude:', lng);

    setMarkerPosition({ lat, lng });

    setAssetLocationLatitude((prevState) => ({
      ...prevState,
      asset_location_latitude: lat,
    }));

    setAssetLocationLongitude((prevState) => ({
      ...prevState,
      asset_location_longitude: lng,
    }));
  };

  //ไปหาตำแหน่งของ User

  const panToUserLocation = () => {
    if (mapRef.current) {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            setCurrentLocation(pos)
            // console.log("position pan: ", pos);
            console.log(pos.lat)
            console.log(pos.lng)  

            setAssetLocationLatitude((prevState) => ({
              ...prevState,
              asset_location_latitude: pos.lat,
            }));
        
            setAssetLocationLongitude((prevState) => ({
              ...prevState,
              asset_location_longitude: pos.lng,
            }));

            //map ref คิดว่าน่าจะอิงค์จากตำแหน่งที่มีล่าสุด เลยทำให้วาร์ปไปมา (น่าจะ)
            //ไปอ่านคอมเม้นต่อที่ const center 
            mapRef.current.panTo({ lat: pos.lat, lng: pos.lng });
            mapRef.current.setZoom(14);

          },

          (positionError) => {
            console.log("Error getting position: ", positionError);
            Swal.fire("ไม่สำเร็จ", "คุณได้ Block การเข้าถึงตำแหน่ง", "error");
          },
          { enableHighAccuracy: true }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
        Swal.fire("ไม่สำเร็จ", "คุณได้ Block การเข้าถึงตำแหน่ง", "error");
      }
    } else {
      Swal.fire("ไม่สำเร็จ", "คุณได้ Block การเข้าถึงตำแหน่ง", "error");
    }
  };

  return (
    <div className='relative w-full h-[450px] '>
      <div className="w-max h-auto  mx-auto absolute bottom-16 right-5  md:bottom-32 md:rigth-12 z-50 items-center justify-center">
        <div className="h-auto w-full flex items-center justify-center cursor-pointer drop-shadow-xl" onClick={panToUserLocation}>
          <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
            <img src="https://cdn-icons-png.flaticon.com/512/3138/3138801.png" className="w-8 h-8" />
          </div>
        </div>
      </div>
      <div className="w-max h-auto  mx-auto absolute bottom-32 right-5 z-50 items-center justify-center  ">
          <div
            className="h-auto w-full flex items-center justify-center cursor-pointer drop-shadow-xl  "
            onClick={handleSelectTypeOfMap}
          >
            <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-3d">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none" 
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Modal */}
        {isShowModalMap ? (
          <div className="  w-full min-h-80 h-auto rounded-xl mx-auto absolute bottom-[8px] p-1 z-40 ">
            <div className="w-full h-full relative ">
              <button
                className="text-red-500 absolute top-3 right-5 text-xl bg-white rounded-full z-50"
                onClick={handleSelectTypeOfMap}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="w-full h-full rounded-xl grid grid-col-12 border border-white shadow-xl">
                <div
                  className="col-span-4 relative flex flex-col rounded-xl"
                  onClick={() => changeMapType("hybrid")}
                >
                  <h1 className="absolute text-black top-3 bg-white  rounded-full p-1  text-md  left-1/2 tranform -translate-x-1/2   ">
                    มุมมองดาวเทียม
                  </h1>
                  <img
                    src={`https://postperty.com/static/media/MapType(Satellite).3cf595c87d294b1e5c7d.jpg`}
                    className="w-full h-40 object-cover rounded-xl"
                  />
                </div>
              </div>

              <div className="w-full h-full rounded-xl grid grid-col-12 mt-2.5 border border-white shadow-xl">
                <div
                  className="col-span-4 relative flex flex-col rounded-xl"
                  onClick={() => changeMapType("terrain")}
                >
                  <h1 className="absolute text-black top-3 bg-white  rounded-full p-1  text-md  left-1/2 tranform -translate-x-1/2 ">
                    มุมมองปกติ
                  </h1>
                  <img
                    src={`https://postperty.com/static/media/MapType(Terrian).fd0235c1134915a22b7e.jpg`}
                    className="w-full h-40 object-cover rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {/*  Type of map */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={{
          gestureHandling: "greedy",
          disableDefaultUI: true,
          mapTypeId: mapTypeLocal !== null ? mapTypeLocal : mapType,
        }}
        zoom={8}
        center={center}
        onClick={handleClick}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
        {LocationDataUserClickBeforeExitPage && <Marker position={LocationDataUserClickBeforeExitPage} />}
        <Marker position={currentLocation}/>
      </GoogleMap>

    </div>

  );
}

// Import statements...
"use client"
import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import axios from "axios";

const center = { lat: 13.000, lng: 100 };
const token = localStorage.getItem("token");

const TestMap = () => {
  const [markers, setMarkers] = useState([]);
  const [loadingTime, setLoadingTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const startTime = performance.now();

    const getMarkers = () => {
      axios
        .get(`https://backend-admin.postperty.com/map/opponent?itemsPerPage=50000&page=1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (isMounted) {
            setMarkers(res.data.data);
            const endTime = performance.now();
            setLoadingTime(endTime - startTime);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getMarkers();

    return () => {
      isMounted = false;
      // Set the end time when the component is unmounted or when the markers are set
      setEndTime(performance.now());
    };
  }, []); // Empty dependency array ensures this useEffect runs once when the component mounts

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `AIzaSyB_Cf-HZo-m7zw9SDxaCumnR3oIjlu35Sk`,
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // Calculate the total time taken after rendering all elements
  const totalLoadingTime = endTime ? endTime - performance.now() : null;

  return (
    <main className="w-full h-screen">
      <GoogleMap
        mapContainerStyle={{ width: "100vw", height: "95vh" }}
        center={center}
        zoom={18}
        options={{
          disableDefaultUI: true,
          gestureHandling: "greedy",
          clickableIcons: false,
          maxZoom: 20,
          minZoom: 6,
        }}
      >
        {markers.map((data) => (
          <Marker key={data.id} position={data.location} />
        ))}
      </GoogleMap>

      {console.log(totalLoadingTime)}
      {totalLoadingTime && (
        <div style={{ position: 'absolute', bottom: 0, left: 0, background: 'white', padding: '10px' }}>
          Total time after rendering all elements: {totalLoadingTime} milliseconds
        </div>
      )}
    </main>
  );
};

export default TestMap;

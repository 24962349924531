import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

export default function MyShareAsset() {
    const [expanded, setExpanded] = useState([]);
    const [myshareData, setMyShareData] = useState([]);
    const session_id = Cookies.get("jwt");

    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_API}/mlm/myshare`,
                { get: 'true' },
                { headers: { Authorization: `Bearer ${session_id}` } }
            )
            .then((res) => {
                setMyShareData(res.data.myshare);
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    function handleDropDownListMember(index) {
        setExpanded((prev) => {
            const newExpanded = [...prev];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    }

    return (
        <div className="container mx-auto p-4 md:w-8/12 lg:w-6/12">
            {/* Overview Card */}
            <motion.div
                className="p-6 mb-6 rounded-lg shadow-lg bg-blue-50 text-center"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <h1 className="text-xl font-semibold text-blue-800">จำนวนโพสต์ที่แชร์</h1>
                <motion.span className="text-4xl font-bold text-blue-600" key={myshareData.length}>
                    {myshareData.length}
                </motion.span> <span className="text-xl font-semibold text-blue-600">ทรัพย์</span>
            </motion.div>

            {/* Shared Assets List */}
            <div className="space-y-8">
                {myshareData.map((data, idxMain) => {
                    const Lead1st = data.sharee[0];
                    const another_shared = data.sharee;

                    return (
                        <motion.div
                            key={idxMain}
                            className="bg-white rounded-lg shadow-md overflow-hidden"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: idxMain * 0.1 }}
                        >
                            {/* Asset Image */}
                            <img src={data.asset_image} alt="Shared Asset" className="w-full h-48 object-cover" />

                            {/* Asset Info */}
                            <div className="p-4">
                                <div className="flex items-center">
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/1021/1021218.png"
                                        alt="leader-icon"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <h2 className="text-lg font-semibold text-gray-800">
                                        ต้นสาย: {Lead1st ? Lead1st.uname : 'ไม่พบ'}
                                    </h2>
                                </div>

                                {/* Expandable Share Info */}
                                <div
                                    className="mt-4 cursor-pointer flex items-center justify-between text-blue-600 font-medium"
                                    onClick={() => handleDropDownListMember(idxMain)}
                                >
                                    <span>{data.sharee.length} การแชร์ต่อ</span>
                                    <motion.img
                                        src="https://cdn-icons-png.flaticon.com/512/2985/2985150.png"
                                        alt="arrow-icon"
                                        className="w-5 h-5"
                                        animate={{ rotate: expanded[idxMain] ? 180 : 0 }}
                                        transition={{ duration: 0.3 }}
                                    />
                                </div>

                                {/* Animated Member List */}
                                <AnimatePresence>
                                    {expanded[idxMain] && (
                                        <motion.div
                                            className="mt-2 space-y-2 overflow-y-auto max-h-48"
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            {another_shared.map((member, idx) => (
                                                <div
                                                    key={idx}
                                                    className="flex items-center space-x-3 p-2 bg-gray-100 rounded-lg"
                                                >
                                                    <img
                                                        src={member.profile_image}
                                                        alt="profile"
                                                        className="w-8 h-8 rounded-full"
                                                    />
                                                    <span className="text-gray-700 font-medium">{member.uname}</span>
                                                </div>
                                            ))}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </motion.div>
                    );
                })}
            </div>
        </div>
    );
}

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import CountUp from "react-countup";

export default function LandingPage() {
  const token = localStorage.getItem("token");
  const [amountAssets, setAmountAssets] = useState([]);

  const [animationPlayed, setAnimationPlayed] = useState(false);

  function getAssetCounter() {
    axios
      .get(`${process.env.REACT_APP_API}/event/assetTypeCounter`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data.assetCounts;

        // Define the desired order of asset types
        const order = [
          "house",
          "townhouse",
          "condo",
          "commercial",
          "land",
          "other",
        ];

        // Sort the assets according to the defined order
        data.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));

        setAmountAssets(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function RunningNumber({ n }) {
    // Conditionally render CountUp if animationPlayed is false
    if (!animationPlayed) {
      return <CountUp end={n} duration={2} />;
    } else {
      // Render the final number without animation if animationPlayed is true
      return <span>{n.toFixed(0)}</span>;
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationPlayed(true);
    }, 2000); // Delay 2.3 seconds
    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  useEffect(() => {
    getAssetCounter();
  }, []);

  const handleAutoFilterRealEstate = (key) => {
    console.log("Key Real Estate", key);
    if (key !== "") {
      localStorage.setItem("AllowOpenGridLayoutFromAutoFilter_IndexPage", true); //use in GoogleMap.js for auto filter from click icon

      window.location.replace(`/home?autoFilter=${key}`);
    }
  };

  function handleLinkToSocialMedia(Link) {
    window.open(Link);
  }

  return (
    <main className="w-full h-full ">
      <div className="relative flex flex-col items-center  mt-2.5  w-11/12 mx-auto h-full md:w-10/12 lg:w-8/12">
        <img
          className="absolute top-2.5 right-2.5 w-14 h-14"
          src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/logo/logo-postperty.png"
        />
        <div className="w-full h-max   flex flex-col md:w-11/12 md:mt-12 ">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-3xl text-left md:text-[60px] md:text-center mt-12"
          >
            ซื้อขาย อสังหาฯ บนแผนที่
          </motion.h1>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.25, delay: 1 }}
            className="hidden text-3xl mt-2.5 text-left font-semibold bg-gradient-to-r text-gray-400 mx-auto from-blue-400 to-blue-700 bg-clip-text text-transparent md:text-center md:flex"
          >
            ดูบ้านแบบ VR 3D ช่วยชวนช่วยแชร์ ได้ค่าคอมฯ
          </motion.span>
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.25, delay: 1 }}
            className="md:hidden text-3xl mt-2.5 text-left font-semibold bg-gradient-to-r text-gray-400 from-blue-400 to-blue-700 bg-clip-text text-transparent md:text-center "
          >
            ดูบ้าน แบบ VR 3D
          </motion.span>

          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.25, delay: 1 }}
            className="md:hidden text-3xl mt-2.5 text-left font-semibold bg-gradient-to-r text-gray-400 from-blue-400 to-blue-700 bg-clip-text text-transparent md:text-center "
          >
            ช่วยชวน ช่วยแชร์ ได้ค่าคอมฯ
          </motion.span>

          <motion.div
            onClick={() => window.location.replace("/home")}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1.25, y: 0 }}
            transition={{ duration: 1, delay: 1.5 }}
            className="w-full h-[100px] mt-16 mb-16 text-3xl flex justify-center items-center bg-postperty-linear-light text-white rounded-[150px] md:h-26 lg:w-96 cursor-pointer mx-auto "
          >
            เข้าใช้งาน
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.25, delay: 2 }}
            className="flex flex-col text-left text-gray-500 w-full h-max md:flex-row md:justify-center md:items-center"
          >
            <p>แพลตฟอร์ม ซื้อขายอสังหาฯ</p>
            <p>บริการครบจบในที่เดียว</p>
            <p className="md:ml-2.5">บริษัท โพสต์เพอร์ตี้ จำกัด</p>
          </motion.div>

          <motion.div className="mt-8 h-60  md:h-auto ">
            <iframe
              width="100%"
              className="rounded-xl h-[100%] md:h-[500px]"
              src="https://www.youtube.com/embed/CIm8ZCTFXOU"
              title="About PostPerty | EP.0 ทำความรู้จัก PostPerty.com ใน 1 นาที"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </motion.div>
        </div>

        <div className="w-full h-full md:w-11/12 mt-16 ">
          <span className="text-xl mb-4">จำนวนอสังหาฯทั้งหมดของเรา</span>
          <div className="w-full h-full grid grid-cols-2 md:grid-cols-3 gap-x-2.5 ">
            {amountAssets.map((asset) => (
              <div
                key={asset.eng}
                onClick={() => handleAutoFilterRealEstate(asset.key)}
                className="flex flex-col text-center h-[10rem] items-center justify-center border rounded-xl "
              >
                <img src={asset.keyIcon} className="w-12 h-12" />
                <div className="text-3xl flex flex-col justify-center items-center">
                  <p className="text-xl">{asset.thai}</p>
                  <RunningNumber n={parseInt(asset.amount)} />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full h-full md:w-11/12 mt-16 border rounded-xl p-2.5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-2.5">
            <div className="w-full">
              <img
                src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/postperty_agent.jpg"
                className="w-full h-full object-contain  rounded-3xl"
              />
            </div>
            <div className="flex flex-col w-full h-full   ">
              <span className="text-2xl mt-2.5 md:text-4xl  mx-auto font-semibold bg-gradient-to-r text-gray-400 from-blue-400 to-blue-700 bg-clip-text text-transparent ">
                PostPerty Agent
              </span>
              <h1 className="text-xl md:text-2xl p-4 indent-3">
                เราคือ{" "}
                <span className="font-bold">บริษัทนายหน้า ที่มีพนักงาน</span>{" "}
                ดูแลลูกค้า เป็นพนักงาน
                <span className="font-bold">ของเราเอง</span>{" "}
                ที่พร้อมดูและให้คำปรึกษาตั้งแต่เริ่มจนปิดการขาย{" "}
                <span className="font-bold">
                  คิดค่า นายหน้าจากผู้ฝากขาย แค่3% เมื่อขายบ้านได้เท่านั้น
                </span>
                ไม่มีการบวกราคา หรือค่าใช้จ่ายอื่นใด เพิ่มเติม
                และสะดวกด้วยบริการ ทีมงานคอลเซ็นเตอร์ และ Customer service 24
                ชม.พร้อมให้คำปรึกษา ได้ตลอดเวลา
              </h1>
            </div>
          </div>
        </div>
        <div className="w-full h-full md:w-11/12 mt-16 border rounded-xl p-2.5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-2.5 ">
            <div
              style={{
                width: "100%",
                height: 0,
                paddingBottom: "56%",
                position: "relative",
              }}
              className=" md:order-last"
            >
              <iframe
                src="https://giphy.com/embed/QuUhaaIOWkGgDRcEQf"
                width="100%"
                height="100%"
                style={{ position: "absolute", pointerEvents: "none" }}
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              ></iframe>
            </div>

            <div className="flex flex-col w-full h-full  ">
              <span className="text-2xl mt-2.5 md:text-4xl  mx-auto font-semibold bg-gradient-to-r text-gray-400 from-blue-400 to-blue-700 bg-clip-text text-transparent ">
                Postperty 3D
              </span>
              <a
                target="_blank"
                href="https://app.cloudpano.com/tours/P1J97cdxlz"
                className="underline mx-auto"
              >
                คลิกที่นี่เพื่อดูตัวอย่าง
              </a>
              <h1 className="text-xl md:text-2xl p-4 indent-4  ">
                PostPerty 3D บริการถ่ายสถานที่ด้วยกล้อง360องศา สร้างภาพแบบ 3
                มิติ เพิ่มโอกาศปิดยอดขายได้ตั้งแต่ช่องทางออนไลน์
                ไม่ว่าจะเป็นธุรกิจร้านอาหาร คาเฟ่ ที่พัก รีสอร์ท
                และเหมาะกับธุรกิจซื้อขายอสังหาริมทรัพย์ ที่ช่วยลดการเปิดบ้านจริง
                ให้ลูกค้าดูบ้านอย่างละเอียดเหมือนได้เข้ามาดูในบ้านจริงผ่าน
                มือถือ พร้อมทำสื่อ Video
                รีวิวบ้านและออกแบบภาพกราฟิก(ทำให้ฟรีสำหรับผู้ที่เซ็นสัญญาฝาก
                PostPerty ขาย)
              </h1>
            </div>
          </div>
        </div>

        <div className="w-full h-full md:w-11/12 mt-16 border rounded-xl p-2.5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-2.5">
            {/* <div className="w-full">
              <img
                src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/media/Application_Desktop.png"
                className="w-full h-full object-contain "
              />
            </div> */}

            <div className="w-full">
              <img
                src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/media/mockup_app.png"
                className="w-full h-full object-contain max-h-[700px]"
              />
            </div>

            <div className="flex flex-col w-full h-full   ">
              <span className="text-2xl mt-4 md:text-4xl  mx-auto font-semibold bg-gradient-to-r text-gray-400 from-blue-400 to-blue-700 bg-clip-text text-transparent ">
                Postperty.com
              </span>
              <h1 className="text-xl md:text-2xl p-4  indent-3">
                <span className="font-bold">
                  เว็บไซต์ ซื้อขายอสังหาฯ บนแผนที่{" "}
                </span>
                ที่ใช้ง่ายเหมือน มีแผนที่อสังหาฯ อยู่ในมือ เลือกทำเลที่ชอบ
                หาราคาที่ใช่ได้ง่ายขึ้น สะดวกกับการ
                <span className="font-bold">ดูบ้านได้แบบ VR 3D</span>
                ลดการนัดเปิดบ้าน คัดกรองลูกค้าได้ง่ายขึ้น และด้วยระบบ
                <span className="font-bold">กระจายรายได้</span>
                มากสุดถึง2%จากราคาขาย{" "}
                <span className="font-bold">ให้กับสมาชิก</span>
                ที่ทุกคนสามารถ
                <span className="font-bold">สมัครสมาชิกได้ฟรี</span> แล้ว
                ช่วยเรา แชร์ขายทรัพย์ ถ่ายรูปป้ายขายทรัพย์ ที่เจอ ส่งมาให้
                PostPerty Agent หรือจะชวนคนมาสมัครต่อจากเราแล้ว
                เขาทำงานแบบที่กล่าวมา
                เราก็ให้คนชวนมีโอกาสได้รับส่วนแบ่งค่านายหน้า 2% ได้มากถึง10 ชั้น
                หรือเจ้าของบ้าน{" "}
                <span className="font-bold">
                  จะลงขายเอง ก็ฟรี ไม่มีค่าใช้จ่าย
                </span>
              </h1>
            </div>
          </div>
        </div>

        <motion.div
          onClick={() => window.location.replace("/home")}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1.25, y: 0 }}
          transition={{ duration: 1, delay: 1.5 }}
          className=" w-full h-[100px] mt-2.5 mb-16 text-3xl flex justify-center items-center bg-postperty-linear-light text-white rounded-[150px] md:h-26 lg:w-96 cursor-pointer mx-auto md:hidden "
        >
          เข้าใช้งาน
        </motion.div>

        <div className="w-80 mx-auto  flex flex-col mt-16">
          <div className="w-full h-14 bg-half-light-2 mt-2 rounded-xl">
            <a href="/contact">
              <div className=" h-full justify-around  flex items-center bg-green-300  rounded-full">
                <img
                  src="   https://cdn-icons-png.flaticon.com/512/2198/2198371.png "
                  alt="line-icon"
                  className="w-10 h-10   "
                ></img>{" "}
                <p className="font-medium text-center">ติดต่อ PostPerty</p>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
                  alt="arrow"
                  className="w-3 h-3"
                ></img>
              </div>
            </a>
          </div>
        </div>

        <div className="w-full h-full md:w-11/12  mt-2.5 border text-center rounded-xl p-2.5">
          <span>บริษัทโพสต์เพอร์ตี้จำกัด </span>
          <span>88/91 หมู่9 ต.บางแขม อ.เมืองนครปฐม จ.นครปฐม 73000</span>
        </div>

        <h1 className="w-full my-2.5">Online Chanel</h1>
        <div className="w-full h-[100px] grid grid-cols-3">
          <div
            className="w-full h-full flex flex-col items-center justify-center cursor-pointer "
            onClick={() =>
              handleLinkToSocialMedia("https://www.youtube.com/@postperty")
            }
          >
            <img
              className="w-12 h-12"
              src="https://cdn-icons-png.flaticon.com/512/174/174883.png"
            />
            <span className="ml-2.5">@PostPerty</span>
          </div>
          <div
            className="w-full h-full flex flex-col items-center justify-center cursor-pointer"
            onClick={() =>
              handleLinkToSocialMedia("https://www.tiktok.com/@postperty")
            }
          >
            <img
              className="w-12 h-12 "
              src="   https://cdn-icons-png.flaticon.com/512/3046/3046120.png "
            />
            <span className="ml-2.5">@PostPerty</span>
          </div>
          <div className="w-full h-full">
            <div
              className="w-full h-full flex flex-col items-center justify-center cursor-pointer "
              onClick={() =>
                handleLinkToSocialMedia(
                  "https://www.facebook.com/PostpertyDotcom"
                )
              }
            >
              <img
                className="w-12 h-12 ml-2.5"
                src="   https://cdn-icons-png.flaticon.com/512/5968/5968764.png"
              />
              <span className="ml-2.5">PostPety.com</span>
            </div>
          </div>
        </div>

        <div className="h-[200px]"></div>
      </div>
    </main>
  );
}

import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import FormatPrice from "../home/GridLayoutDisplay/FormatPrice";
import FormatDateTime from "../home/GridLayoutDisplay/FormatDateTime";
import GoogleMapIcon from "../../pages/home/GoogleMapAPI/assets/google-map-icon.png";
import Cookies from "js-cookie";
import { MdDeleteOutline } from "react-icons/md";
import Swal from "sweetalert2";
import { BiEdit } from "react-icons/bi";
import { useLocation } from "react-router-dom";
const MyPost = ({ statusCloseMap, SetStatusCloseMap }) => {

  const location = useLocation();

  useEffect(() => {
    const key = `scrollPosition_${location.pathname}`;
    const storedScrollPositionString = localStorage.getItem(key) || "0";
    const storedScrollPosition = parseInt(storedScrollPositionString, 10);

    const handleScroll = () => {
      localStorage.setItem(key, window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);

    // use setTimeout to give some delay to scroll restoration.
    setTimeout(() => window.scrollTo(0, storedScrollPosition), 2500); // 2500 milliseconds equals 2500 second

    return () => window.removeEventListener("scroll", handleScroll);
  }, [location.pathname]);

  // Save position scroll Y Before Exit page

  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const [DataAsset, setDataAsset] = useState([]);
  console.log(DataAsset);
  const [fetchPageNumber, setFetchPageNumber] = useState(1);

  function fetchData() {
    axios
      .get(`${process.env.REACT_APP_API}/asset/myassets`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const data = res.data.assets;
        console.log(data);
        setDataAsset(data);
      })
      .catch((error) => {
        // handle errors
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  const [tempDisplayRemoveAssetID, setTempDisplayRemoveAssetID] =
    useState(null);

  function handleRemoveMyAsset(assetId) {
    Swal.fire({
      title: "ยืนยันการลบโพสต์?",
      text: "คุณต้องการลบโพสต์!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34BFD9",
      cancelButtonColor: "#000",
      confirmButtonText: "ยืนยันการลบโพสต์",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_API}/event/deleteAsset`,
            {
              asset_id: assetId,
            },
            {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            Swal.fire("สำเร็จ!", "ลบโพสต์สำเร็จ", "success");

            setTempDisplayRemoveAssetID(assetId);
            fetchData();

            // window.location.reload()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  function handleCloseAsset(id_asset) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    Swal.fire({
      title: "ยืนยันการปิดการขายใช่หรือไม่?",
      text: "หลังจากการยืนยันคุณไม่สามารถย้อนกลับได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "black",
      confirmButtonText: "ยืนยันการปิดการขาย!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_API}/event/soldAsset`,
            {
              asset_id: id_asset,
            },
            {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            Swal.fire("สำเร็จ!", "ปิดการขายสำเร็จ", "success");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  return (
    <div className="bg-white lg:snap-y lg:snap-mandatory lg:h-screen overflow-auto mt-6  w-3xl">
      <div className="mx-auto px-6 lg:px-8 relative">
        <div className="mx-auto px-6 lg:px-8 ">
          <div className="flex justify-center max-w-7xl mx-auto bg-line ">
            <div className="w-full lg:mx-0 ">
              <h2
                class={
                  statusCloseMap === true
                    ? "text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mt-8"
                    : "text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                }
              >
                ประกาศของฉัน
              </h2>
              <span>จำนวน {DataAsset.length} โพสต์</span>
            </div>
          </div>
          <div
            className={
              statusCloseMap === true
                ? "mx-auto mt-10 mx grid max-w-2xl grid-cols-1 md:grid-cols-2 gap-x-1 gap-y-8 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-7xl lg:mx-auto lg:grid-cols-12"
                : "flex justify-center  items-center mx-auto mt-2.5 grid max-w-2xl grid-cols-1 gap-x-1 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16  lg:max-w-7xl md:grid-cols-12"
            }
          >
            {DataAsset.length > 0 ? (
              DataAsset.map((post) => (
                <article
                  key={post.id}
                  className={`${
                    tempDisplayRemoveAssetID != post.id
                      ? "flex flex-col"
                      : "hidden"
                  } items-center justify-between w-full mx-auto md:col-span-4 lg:col-span-3`}
                >
                  {/* <Link to={`/edit${post.type === "house" ? "/house" : post.type === "condo" ? "/condo" : post.type === "land" ? "/land" : post.type === "commercial" ? "/commercial" : post.type === "townhouse" ? "/townhouse" : ""}/${post.id}`}> */}
                  {/* <Link to={`/product/${post.id}`}> */}
                  <div
                    className={
                      post.status === false
                        ? "opacity-0"
                        : "w-full h-full  text-center text-red-400  "
                    }
                  >
                    ทรัพย์นี้ถูกขายไปแล้ว
                  </div>
                  <div
                    className={
                      post.status === true
                        ? "flex max-w-xl flex-col  opacity-30 "
                        : "flex max-w-xl flex-col  rounded border rounded-xl p-1 mx-auto w-full"
                    }
                  >
                    <div className="w-full h-60 max-h-60 relative">
                      <Link to={`/product/${post.id}`}>
                        <img
                          src={post.images_url.AssetImagesURL[0]}
                          className="min-w-full w-full h-full object-cover rounded-xl  z-10"
                        ></img>
                      </Link>
                      <MdDeleteOutline
                        className="absolute top-2 right-2 bg-red-500 text-white p-1 w-8 h-8 rounded-full"
                        onClick={() => handleRemoveMyAsset(post.id)}
                      />
                      <Link
                        to={`/edit${
                          post.type === "house"
                            ? "/house"
                            : post.type === "condo"
                            ? "/condo"
                            : post.type === "land"
                            ? "/land"
                            : post.type === "commercial"
                            ? "/commercial"
                            : post.type === "townhouse"
                            ? "/townhouse"
                            : ""
                        }/${post.id}`}
                      >
                        <BiEdit className="absolute top-2 left-2 p-1 w-8 h-8 rounded-full bg-white" />
                      </Link>

                      <div
                        className={
                          post.sold
                            ? "hidden"
                            :  "text-sm absolute bottom-2.5 left-1/2 tranform -translate-x-1/2 h-8 w-10/12 bg-red-500 text-white rounded-full  flex justify-center items-center cursor-pointer"
                        }
                        onClick={() => handleCloseAsset(post.id)}
                      >
                        หากขายแล้ว/ยกเลิกการขายกดที่นี่
                      </div>
                      <div
                        className={
                          post.sold
                            ? "absolute bottom-2.5 left-1/2 tranform -translate-x-1/2 h-8 w-10/12 bg-green-500 text-white rounded-full text-xl flex justify-center items-center cursor-pointer"
                            : "hidden"
                        }
                      >
                        ปิดการขายเรียบร้อยแล้ว
                      </div>
                    </div>

                    <div className="flex items-center gap-x-4 text-xs">
                      <time className="text-gray-500">
                        {<FormatDateTime isoDateString={post.date} />}
                      </time>
                      <a
                        href={"#"}
                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-grawy-100 "
                      >
                        {post.id}
                      </a>
                    </div>
                    <Link to={`/product/${post.id}`}>
                      <div className="group relative ">
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                          <a href={"#"}>
                            <span className="absolute inset-0" />
                            {post.title.slice(0, 23)}
                          </a>
                        </h3>
                        <h3 className=" text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                          {
                            <FormatPrice
                              number={
                                post.sell_price == null ? "" : post.sell_price
                              }
                              suffix={`${post.sell_price == null ? "" : `บาท`}`}
                            />
                          }
                        </h3>

                        <div className="flex flex-col w-full h-38">
                          <p>{post.description.slice(0, 72)}...</p>

                          <div className="w-11/12 h-auto mx-auto grid grid-cols-12 gap-1 mt-2.5">
                            <div className="grid col-span-12 col-span-4 max-sm:col-span-6 h-10">
                              <div className="grid grid-cols-12">
                                <div className="col-span-4 flex justify-center items-center ">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/2642/2642252.png"
                                    alt="bedroom-Icon"
                                    className="w-8 h-8"
                                  />
                                </div>
                                <div className="col-span-8 flex justify-center items-center">
                                  {post.rooms.Bedroom}{" "}
                                </div>
                              </div>
                            </div>

                            <div className="grid col-span-12 col-span-4 max-sm:col-span-6 h-10">
                              <div className="grid grid-cols-12">
                                <div className="col-span-4 flex justify-center items-center ">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/3565/3565091.png"
                                    alt="bedroom-Icon"
                                    className="w-6 h-6"
                                  />
                                </div>
                                <div className="col-span-8 flex justify-center items-center">
                                  {post.rooms.Bathroom}{" "}
                                </div>
                              </div>
                            </div>
                            {/* <div className="grid col-span-12 col-span-4 max-sm:col-span-6   h-10">
                          <div className="grid grid-cols-12">
                            <div className="col-span-4 flex justify-center items-center ">
                              <img src="https://cdn-icons-png.flaticon.com/512/9786/9786998.png" alt="bedroom-Icon" className="w-6 h-6" />
                            </div>
                            <div className="col-span-8 flex justify-center items-center ">{post.furniture === true ? <p>มีเฟอร์นิเจอร์</p> : <p>ไม่มีเฟอร์นิเจอร์</p>}</div>
                          </div>
                        </div> */}

                            <div className="grid col-span-12 col-span-4 max-sm:col-span-6 h-10">
                              <div className="grid grid-cols-12">
                                <div className="col-span-4 flex justify-center items-center ">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/4305/4305629.png"
                                    alt="bedroom-Icon"
                                    className="w-6 h-6"
                                  />
                                </div>
                                <div className="col-span-8 flex justify-center items-center"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {<PreformattedTextGrid  text={post.description}  />} */}
                      </div>
                    </Link>
                  </div>
                  {/* </Link> */}
                  {/* </Link> */}
                </article>
              ))
            ) : (
              <h1 className="w-full text-center col-span-12">
                คุณยังไม่มีโพสต์ที่ประกาศ
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPost;

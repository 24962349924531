import React from 'react';

const FormatDateTime = ({ isoDateString }) => {
  const formatDateAndTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      {formatDateAndTime(isoDateString)}
    </div>
  );
};

export default FormatDateTime;

import React from "react";
import { Link } from "react-router-dom";
import { HomeIcon, UserCircleIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

const navListItems = [
  { label: "หน้าหลัก", icon: HomeIcon, href: "/home" },
  { label: "ติดต่อเรา", icon: UserCircleIcon, href: "/contact" },
  { label: "ทรัพย์ที่ถูกใจ", icon: ShoppingCartIcon, href: "/cart" },
];

export default function ComplexNavbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth >= 960 && setIsNavOpen(false));
  }, []);

  return (
    <nav className="w-full px-6  mx-auto  text-black  flex z-50 backdrop-blur-sm bg-white/30 border md:hidden lg:block">
      <div className="flex items-center justify-between max-w-screen-xl mx-auto text-black">
        <Link href="#" className="mr-4 block cursor-pointer py-1.5 font-sans text-base font-semibold leading-relaxed tracking-normal text-inherit antialiased">
          <div className="w-32 h-max ">
          <img src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/logo/modern_logo_postperty.png" className="w-full"/>

          </div>
          {/* <span className="text-xl" onClick={()=>window.location.reload()}>PostPerty</span> */}
        </Link>
        <div className="hidden lg:block">
          <ul className="flex gap-6">
            {navListItems.map(({ label, href }, key) => (
              <li key={key} className="block font-medium text-black">
                <Link to={href}>{label}</Link>
              </li>
            ))}
            <li className="block font-medium text-black">
              <Link to="/profiles">โปรไฟล์</Link>
            </li>
          </ul>
        </div>
        <button className="relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-inherit transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden" type="button">
          <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
            </svg>
          </span>
        </button>
      </div>
    </nav>
  );
}

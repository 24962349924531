import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
const cookie = Cookies.get("jwt");
const token = `${cookie}`;
// import { useHistory } from 'react-router-dom';

const Point_commission = (props) => {
  // const history = useHistory();
  

  let [points, setPoints] = useState(0.00);
  // console.log(points)
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        const { point } = res.data;
        setPoints(point);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  

  return (
    <div className="flex flex-col w-full h-screen bg-postperty-linear-light items-center">
      <div className="w-96 mt-16 md:w-full">
        <div className="w-96 h-9  relative flex">
          <button
            className="bg-white w-9  h-6  absolute left-9 top top-1 rounded-3xl  "
            onClick={()=>{
              window.location.replace('/profiles')
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              alt="backword"
              className="w-4 h-4  m-auto"
            ></img>
          </button>
          <h1 className="text-white w-full text-center mt-auto mb-auto text-xl">
            คอมมิชชั่น
          </h1>
        </div>
      </div>

      <div className="bg-half-light w-80 h-28 rounded-2xl mt-14 flex flex-col  md:w-3/5">
        <p className="text-lg text-center w-full mt-4">ยอดเงินสะสมปัจจุบัน</p>
        <h1 className="text-important-green text-4xl font-medium w-full text-center h-14 mt-2">
          {`${points}`} <span className="text-4xl text-black">฿</span>{" "}
          
        </h1>
        
      </div>
      <Link className="md:w-96 mx-auto flex justify-center items-center" to={`/user/point/withdraw`}>
      <div className="bg-half-light w-80 h-12 justify-center items-center rounded-xl mt-2.5 flex flex-col  md:w-3/5">
        <p className="font-bold">ถอนเงิน</p>        
      </div>
      </Link>
      <div className="w-96 h-[230px] bg-half-light rounded-xl mt-20 md:w-3/5">
        <div className="w-80 mx-auto  mt-9 flex flex-col md:w-3/6">
          <h1 className="font-medium text-base">ช่องทางการรับเงิน</h1>
          <div className="w-full h-14 bg-half-light-2  mt-2 rounded-xl">
          <Link to={`/user/point/bank`}>

            <div className=" h-full justify-around  flex items-center">
              <img
                src="https://www.kasikornbank.com/SiteCollectionDocuments/about/img/logo/logo.png"
                alt="bank-logo"
                className="w-7 h-7   "
              ></img>{" "}
              <p className="font-medium text-center ">เอกสารการรับเงิน</p>
              <img
                src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
                alt="arrow"
                className="w-3 h-3"
              ></img>
            </div>
            </Link>

          </div>
        </div>

        {/* <div className="w-80 mx-auto  flex flex-col md:w-3/6">
          <h1 className="font-medium text-base mt-2">ยืนยันตัวตน</h1>
          <div className="w-full h-14 bg-half-light-2 mt-2  rounded-xl">
            <div className=" h-full justify-around  flex items-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/6503/6503196.png"
                alt="id-card-icon"
                className="w-7 h-7   "
              ></img>{" "}
              <p className="font-medium text-center ">บัตรประชาชน</p>
              <img
                src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
                alt="arrow"
                className="w-3 h-3"
              ></img>
            </div>
          </div>
        </div> */}

        <div className="w-80 mx-auto  flex flex-col md:w-3/6">
          <div className="w-full h-14 bg-half-light-2 mt-2 rounded-xl">
            <Link to={`/user/point/history`}>
            <div className=" h-full justify-around  flex items-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3503/3503786.png"
                alt="id-card-icon"
                className="w-7 h-7   "
              ></img>{" "}
              <p className="font-medium text-center ">ประวัติการทำรายการ</p>
              <img
                src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
                alt="arrow"
                className="w-3 h-3"
              ></img>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Point_commission;

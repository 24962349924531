import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const cookie = Cookies.get("jwt");
const token = `${cookie}`;

const HistoryCommission = () => {
  const [dataWithdrawHistory, setDataWithdrawHistory] = useState([]);

  useEffect(() => {
    const fetchDataWithdrawHistory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/banking/statement-history`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);

        const historyData = response.data.MoneyRecievedHistory;
        setDataWithdrawHistory(historyData.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataWithdrawHistory();
  }, []);

  const formatDate = (datetimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "2-digit",
    };

    const formattedDateTime = new Intl.DateTimeFormat("th-TH", options).format(new Date(datetimeString));

    return formattedDateTime;
  };

  const formatTime = (datetimeString) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    
    const formattedTime = new Intl.DateTimeFormat("th-TH", options).format(new Date(datetimeString));

    return formattedTime;
  };

  const formatPrice = (price) => {
    const formattedPrice = new Intl.NumberFormat("th-TH", { style: "currency", currency: "THB" }).format(price);
    
    // Remove the currency symbol "฿"
    const priceWithoutSymbol = formattedPrice.replace("฿", "");
    
    return priceWithoutSymbol;
  };
  
  const TimeFormatter = ({ datetime }) => {
    const formattedTime = formatTime(datetime);

    return <span>{formattedTime}</span>;
  };

  if(dataWithdrawHistory === null){
    return;
  }
  return (
    <div className="bg-postperty-linear-light h-screen w-full flex flex-col">
      <div className="w-full flex justify-center items-center md:w-full mt-16">
        <div className="w-full h-9 relative flex justify-center items-center">
          {/* <button
            className="bg-white w-9  h-6  absolute left-9 top top-1 rounded-3xl  "
            onClick={() => {
              window.location.replace("/user/point");
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              alt="backword"
              className="w-4 h-4  m-auto"
            ></img>
          </button> */}
          <h1 className="text-white w-max text-center  mt-auto mb-auto text-xl">
            ประวัติธุรกรรม
          </h1>
        </div>
      </div>

      
      <div className="w-full h-[750px]  overflow-y-auto">
      {dataWithdrawHistory.map((data, index) => {
        const formattedDate = formatDate(data.date); // Replace with the actual date value
    
        return (
          <div className=" p-2.5 w-full h-auto  mt-2.5  md:w-1/3 mx-auto " key={index}>
            <h1 className="text-white">{formattedDate}</h1>
            <div className="bg-half-light w-full h-auto p-2 rounded-l mt-2 overflow-y-auto">
              <div className="w-full h-28 grid grid-cols-12">
                <div className="col-span-2 flex">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/12740/12740855.png"
                    className="w-12 h-12 mx-auto"
                    alt="logo"
                  />
                </div>
                <div className="col-span-10 flex flex-col relative border-b-2 ">
                  <div className="h-full w-full">
                    <h1 className="font-medium">รายการคำขอ</h1>
                    <p className="text-xs absolute top-2 right-2">{formatTime(data.date)}</p>
                    <h2 className={data.reason === null ? 'hidden' : '"text-black"'}>หมายเหตุ: {data.reason}</h2>
                    {/* <h2 className={data.status === null ? 'hidden' : "text-black"}>สถานะ: {data.status}</h2> */}
                    <h2 className={data.status === "approved" ? "text-red-500 text-sm" : "text-sm text-important-green"}>{formatPrice(data.amount)} บาท</h2>
                    <p className="text-xs absolute bottom-1 left-0">{formatDate(data.date)} {formatTime(data.date)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
};

export default HistoryCommission;

import FilterPanel from "./SearchFilterModal";

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  MarkerClusterer,
  Polygon,
  Circle,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import axios from "axios";
import "./GoogleMap.css";
import MapTypeTerrainImage from "../GoogleMapAPI/assets/MapType(Terrian).jpg";
import MapTypeSatelliteImage from "../GoogleMapAPI/assets/MapType(Satellite).jpg";
import LogoPostperty from "./assets/markerIcon/production/1. LOGO-Postperty.webp";
import debounce from "lodash/debounce";
import Rating from "@mui/material/Rating";
import { ClipLoader, PacmanLoader } from "react-spinners";

import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { BiSearch } from "react-icons/bi";
import HousePostperty from "./assets/markerIcon/production/1HousePostperty.webp";
import CondoPostperty from "./assets/markerIcon/production/2CondoPostperty.webp";
import CommercialPostperty from "./assets/markerIcon/production/4CommercePostperty.webp";
import LandPostperty from "./assets/markerIcon/production/3LandPostperty.webp";
import TownHousePostperty from "./assets/markerIcon/production/5TownHouse_postperty.webp";
import { motion } from "framer-motion";
import FormatPrice from "../GridLayoutDisplay/FormatPrice";
import { FcClearFilters } from "react-icons/fc";
import useEffectOnce from "../../../hook/useEffectOnce";
import ModalDetail from "./ModalDetail";
import { useLocation } from "react-router-dom";
const session_id = Cookies.get("jwt");

function GoogleMapEmbedAPIReact({
  statusHalfGridMap,
  setStatusHalfGridMap,
  statusCloseMap,
  SetStatusCloseMap,
  isFullGridButtonOn,
  setIsFullGridButtonOn,
  setDataSearchValue,
  setSearchValue,
  statusInSessionCloseMap,
  setDataDisplay,
  setIsExpanded,
  isExpanded,
  setHeightStep,
  heightStep,
  mainDataModal,
  setMainDataModal,
}) {
  const [markersData, setMarkersData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState("");
  const [statusMap, setStatusMap] = useState("");
  const [currentLocation, setCurrentLocation] = useState({});
  const [realtimeLocation, setRealtimeLocation] = useState({
    lat: 13.787304308779046,
    lng: 100.03521153226723,
  });
  const [userBlurSearch, setUserBlurSearch] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [RadiusCircleMarkerScan, setRadiusCircleMarkerScan] = useState(8); // Initial value
  const [zoomLevelControl, setZoomLevelControl] = useState(18);

  const [isAndroid, setIsAndroid] = useState(false);
  const [isiPhone, setIsiPhone] = useState(false);
  const [isiPad, setIsiPad] = useState(false);
  const [isWindows, setisWindows] = useState(false);
  const [onMarkerScan, SetOnMarkerScan] = useState(false);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  // Function to open the FilterPanel modal
  const handleOpenFilterPanel = () => {
    setIsFilterPanelOpen(true);
  };

  // Function to close the FilterPanel modal
  const handleCloseFilterPanel = () => {
    setIsFilterPanelOpen(false);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if the user agent contains "android" to identify Android devices
    if (userAgent.indexOf("android") > -1) {
      setIsAndroid(true);
    }

    // Check if the user agent contains "iphone" to identify iPhone devices
    if (userAgent.indexOf("iphone") > -1) {
      setIsiPhone(true);
    }

    // Check if the user agent contains "ipad" to identify iPad devices
    if (userAgent.indexOf("ipad") > -1) {
      setIsiPad(true);
    }

    // Check if the user agent contains common terms associated with laptops
    if (
      userAgent.indexOf("windows") > -1 ||
      userAgent.indexOf("macintosh") > -1 ||
      userAgent.indexOf("linux") > -1
    ) {
      setisWindows(true);
    }
  }, []);

  let pageMarkerFetch = 1;

  const changeStatusMap = () => {
    // setStatusHalfGridMap(!statusHalfGridMap);
    // SetStatusCloseMap(!statusCloseMap);
    // setIsFullGridButtonOn(!isFullGridButtonOn);
    setStatusHalfGridMap(false);
    SetStatusCloseMap(true);
    setIsFullGridButtonOn(true);
    // setDataDisplay(rawDataForSearchAreaAsset); // ให้แสดงข้อมูล Asset ที่ marker scan ได้ตอนกดเปลี่ยน มุมมอง
    // setStatusHalfGridMap(true);f
    // SetStatusCloseMap(false);
    // setIsFullGridButtonOn(true);

    // setIsChangingPage(false)

    const url = new URL(window.location);
    url.searchParams.set("gridDisplay", "true");
    window.history.pushState({}, "", url);
  };

  // Oct 24 2023 I Think this is a bug $ARX

  // useEffect(() => {
  //   // The function which will be called after a delay
  //   const fetchData = async () => {
  //     if (statusInSessionCloseMap) {
  //       setStatusHalfGridMap(false);
  //       SetStatusCloseMap(true);
  //       // setIsFullGridButtonOn(!isFullGridButtonOn);
  //       sessionStorage.removeItem("statusCloseMap");
  //     }
  //   };

  //   // Setting a delay of 3 seconds before calling fetchData
  //   const timeoutId = setTimeout(fetchData, 3000); // 3000 ms = 3 s

  //   // Returning a cleanup function to clear the timeout in case the component is unmounted before the timeout completes.
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  // console.log("current location", currentLocation)
  //Markers Clusster

  const latitudeCenter = "lat" in currentLocation;
  const longtitudeCenter = "lng" in currentLocation;

  // const center = {
  //   // lat: latitudeCenter === true ? currentLocation.lat : 13.820264029261136,
  //   // lng: longitudeCenter === true ? currentLocation.lng : 100.03807435273748,

  //   // เช็ค selectedMarker เพื่อให้ ตอนโหลด map จะไป center ที่หมุดแทนที่จะ center ที่ตำแหน่งที่ fix ไว้ ป้องกัน map วาร์ป
  //   lat:
  //     selectedMarker !== ""
  //       ? selectedMarker.location
  //       : latitudeCenter === true
  //       ? currentLocation.lat
  //       : 13.820264029261136, //lattituCenter ใช้ตอนเรียก Function PanToUserLocation เพื่อแก้ปัญหา Pan ไปตำแหน่งที่ Fix ไว้ คือคณะวิศวกรรมศาสตร์มหาลัยศิลปากร
  //   lng:
  //     selectedMarker !== ""
  //       ? selectedMarker.location
  //       : longtitudeCenter === true
  //       ? currentLocation.lng
  //       : 100.03807435273748, //longtitudeCenter ใช้ตอนเรียก Function PanToUserLocation เพื่อแก้ปัญหา Pan ไปตำแหน่งที่ Fix ไว้ คือคณะวิศวกรรมศาสตร์มหาลัยศิลปากร
  // };
  const center = {
    lat:
      selectedMarker !== ""
        ? selectedMarker.location
        : latitudeCenter === true
          ? currentLocation.lat
          : 13.820264029261136,
    lng:
      selectedMarker !== ""
        ? selectedMarker.location
        : longtitudeCenter === true
          ? currentLocation.lng
          : 100.03807435273748,
  };

  // Search (Not Filther)
  const [MarkerSearchValue, setMarkerSearchValue] = useState(null);
  const [textSearchValue, setTextSearchValue] = useState("");

  const handleValueSearch = (e) => {
    const textSearch = e.target.value;

    // When user typing show that user focus set userBlur false for below function to display infowindow when userblue true only
    setUserBlurSearch(false);
    setTextSearchValue(textSearch);

    // setSearchValue(textSearch);
  };

  function handleSearchValueClicked() {
    setIsCheckedCircle(false);
    // console.log('clicked search')
    const searchText = textSearchValue;
    if (searchText.length == 0) {
      setSearchValue("");
    } else if (searchText.length > 1) {
      setSearchValue(searchText);
      setMarkerSearchValue(searchText);
    }
  }
  // Search (Not Filther)
  // const getMarkersDataAll = async () => {

  //   try {
  //     // setIsLoadingMarkerData(true)
  //     const res = await axios.get(`${process.env.REACT_APP_API}/fetchData/getMarker?page=${pageMarkerFetch}`, {
  //       headers: {
  //         Authorization: `Bearer ${checkLogin()}`,
  //       },
  //     });
  //     // console.log(res.data)
  //     const markersData = res.data.data;
  //     const totalMarkers = res.data.total
  //     setTotalMarkers(totalMarkers)
  //     // console.log(markersData);

  //     if (markersData.length > 0) {
  //       // Check for duplicates before updating state
  //       const uniqueMarkersData = removeDuplicates(markersData, 'id');

  //       // Data received, update state
  //       setMarkersData((prevMarkersData) => [...prevMarkersData, ...uniqueMarkersData]);
  //       // Increment page and fetch again after a delay
  //      setIsLoadingMarkerData(false)
  //       await new Promise((resolve) => {
  //         setTimeout(() => {
  //           // console.log('Before setPageMarkerFetch:', pageMarkerFetch);
  //           pageMarkerFetch+=1
  //           // console.log('After setPageMarkerFetch:', pageMarkerFetch);
  //           resolve();
  //         }, 6000); // 6000 milliseconds delay (6 seconds)
  //       });

  //       // Fetch next page
  //       await getMarkersDataAll();
  //     } else {
  //       // No more data, fetching stopped
  //       // console.log('Fetching stopped');
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    const getMarkersFromSearch = () => {
      axios
        .get(
          `${process.env.REACT_APP_API}/event/search-marker/${MarkerSearchValue}`,
          {
            headers: {
              Authorization: `Bearer ${checkLogin()}`,
            },
          }
        )
        .then((res) => {
          // console.log(`this is search pin`, res.data.assets);
          const markersSearch = res.data.assets;

          setMarkersData(markersSearch);

          //When search found asset auto open infowindow and display for user. this function work must user blur search only!
          if (markersSearch.length > 0 && userBlurSearch) {
            const marker = markersSearch[0];
            setSelectedMarker(marker);
            getDetailMarker(marker.id);
            setInfoWindowVisible(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (MarkerSearchValue !== null && MarkerSearchValue !== "") {
      getMarkersFromSearch();
    } else {
      // getMarkersDataAll();

      //May be work
      setInfoWindowVisible(false);
      setSelectedMarker("");
    }
  }, [MarkerSearchValue, userBlurSearch]);

  const memoizedMarkersData = useMemo(() => markersData, [markersData]);

  // Log whenever memoizedMarkersData changes
  // useEffect(() => {
  //   console.log('Memoized markersData changed:', memoizedMarkersData);
  // }, [memoizedMarkersData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 769) {
        setStatusMap("desktop");
      }

      if (window.innerWidth < 768) {
        setStatusMap("mobile");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [profileData, setProfileData] = useState(null);
  // console.log(profileData)
  useEffectOnce(() => {
    // console.log("Fetching profile data...");

    const cookie = Cookies.get("jwt");
    const token = `${cookie}`;

    axios
      .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { uname, profile_image } = res.data;
        setProfileData({
          uname,
          profile_image,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  });

  // console.log(`${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  const [map, setMap] = React.useState(null);
  const mapRef = useRef(null);

  const [mapCenter, setMapCenter] = useState(() => {
    const storedLat = localStorage.getItem("LatForRecommendedAssets");
    const storedLng = localStorage.getItem("LngForRecommendedAssets");
    return storedLat && storedLng
      ? { lat: parseFloat(storedLat), lng: parseFloat(storedLng) }
      : { lat: 13.820264029261136, lng: 100.03807435273748 };
  });

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoad = React.useCallback(
    function callback(map) {
      const defaultCenter = { lat: 13.756721, lng: 100.03807435273748 };
      const defaultZoomLevel = 14;

      const centerToUse = mapCenter || defaultCenter;

      map.setCenter(centerToUse);
      map.setZoom(defaultZoomLevel);
      const currentCenter = map.getCenter();
      // console.log(`Center: `,currentCenter)

      mapRef.current = map;
      setMap(map);
    },
    [mapCenter]
  );

  useEffect(() => {
    const storedLat = localStorage.getItem("LatForRecommendedAssets");
    const storedLng = localStorage.getItem("LngForRecommendedAssets");

    if (storedLat && storedLng) {
      const centerLat = parseFloat(storedLat);
      const centerLng = parseFloat(storedLng);

      axios
        .post(`${process.env.REACT_APP_API}/fetchData/markerScan`, {
          centerLat,
          centerLng,
          radiusKm: 8,
        })
        .then((response) => {
          if (response.data) {
            setCenterOfMap({ lat: centerLat, lng: centerLng });
            setMarkersData(response.data.markers || []);
            setDataDisplay(response.data.grid || []);
          }
        })
        .catch((err) => {
          console.error("Error fetching markers:", err);
        });
    }
  }, []);

  //ไปหาตำแหน่งของ User
  const panToUserLocation = () => {
    onCenterChanged();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Check if pos.lat has a value
          if (pos.lat !== undefined && pos.lng !== undefined) {
            setCurrentLocation(pos);

            // Set lat and lng for recommended asset when user checks current location
            localStorage.setItem("LatForRecommendedAssets", pos.lat);
            localStorage.setItem("LngForRecommendedAssets", pos.lng);

            // Check if mapRef.current is defined before calling panTo and setZoom
            if (mapRef.current) {
              mapRef.current.panTo({ lat: pos.lat, lng: pos.lng });
              mapRef.current.setZoom(14);
            } else {
              console.error("mapRef.current is undefined");
            }
          } else {
            // Handle the case when pos.lat is undefined
            console.error("Latitude is undefined");
          }
        },
        (positionError) => {
          // Swal.fire("คำเตือน", positionError, "info");
        },
        { enableHighAccuracy: true }
      );
    } else {
      Swal.fire("ไม่สำเร็จ", "ไม่ซัพพอร์ทเบราว์เซอร์ของคุณ", "error");
    }
  };
  // add
  const panToUserLocationFix = (lat, lng) => {
    onCenterChanged();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Check if pos.lat has a value
          if (lat !== undefined && lng !== undefined) {
            setCurrentLocation(pos);

            // Set lat and lng for recommended asset when user checks current location
            localStorage.setItem("LatForRecommendedAssets", pos.lat);
            localStorage.setItem("LngForRecommendedAssets", pos.lng);

            // Check if mapRef.current is defined before calling panTo and setZoom
            if (mapRef.current) {
              mapRef.current.panTo({ lat: lat, lng: lng });
              mapRef.current.setZoom(14);
            } else {
              console.error("mapRef.current is undefined");
            }
          } else {
            // Handle the case when pos.lat is undefined
            console.error("Latitude is undefined");
          }
        },
        (positionError) => {
          // Swal.fire("คำเตือน", positionError, "info");
        },
        { enableHighAccuracy: true }
      );
    } else {
      Swal.fire("ไม่สำเร็จ", "ไม่ซัพพอร์ทเบราว์เซอร์ของคุณ", "error");
    }
  };

  // Map Type

  const [isShowModalMap, setIsShowModalMap] = useState(false);

  const handleSelectTypeOfMap = () => {
    setIsShowModalMap(!isShowModalMap);
  };

  const [mapType, setMapType] = useState(
    localStorage.getItem("mapTypeId") || "hybrid"
  );

  const changeMapType = async (newMapType) => {
    await setMapType(newMapType);
    localStorage.setItem("mapTypeId", newMapType);
    // handleSelectTypeOfMap();
  };

  useEffect(() => {
    // Update the map type in localStorage whenever mapType state changes
    localStorage.setItem("mapTypeId", mapType);
    if (map) {
      map.setMapTypeId(mapType); // Ensure the map reflects the current type
    }
  }, [mapType, map]);

  let mapTypeLocal = localStorage.getItem("mapTypeId");
  if (!mapTypeLocal) {
    mapTypeLocal = "none";
    localStorage.setItem("mapTypeId", mapTypeLocal); // Optionally, save the default back to localStorage
  }
  // Modal Map Type
  // Map Type

  // Search Filther

  const [searchDataFilter, setSearchDatafilter] = useState({
    typeRealEstate: [],
    typePostRealEstate: [],
    Province: null,
    Amphure: null,
    Tambon: null,
    statusSeller: null,
    minPrice: null,
    maxPrice: null,
    firstHandRealEstate: null,
    Price: null,
    zipcode: null,
  });
  const [toggleModalDetail, setToggleModalDetail] = useState(false);
  const { typePostRealEstate, Province, zipcode } = searchDataFilter;
  const [isModalSearchFilter, setIsModalSearchFilter] = useState(false);

  const [autoFilterRealEstateValue, setAutoFilterRealEstateValue] =
    useState(null);
  const [startAutoFilterRealEstate, setStartAutoFilterRealEstate] =
    useState(false);
  const [
    stausRoleSellerSearchFilterSelected,
    setStatusRoleSellerSearchFilterSelected,
  ] = useState("");
  const [isStatusRealEstateFirstHand, setIsStatusRealEstateFirstHand] =
    useState("");
  const [typeRealEstateSelected, setTypeRealEstateSelected] = useState([]);

  async function handleAutoFilterRealEstateSwitchGridModeFromIndexPageIcon() {
    const isAllowGrid = localStorage.getItem(
      "AllowOpenGridLayoutFromAutoFilter_IndexPage"
    ); // get from LandingPage.jsx

    if (isAllowGrid == "true") {
      setIsCheckedCircle(false);
      let dataStuct = [];
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const autoFilterValue = urlParams.get("autoFilter");
      dataStuct.push(autoFilterValue);
      await setAutoFilterRealEstateValue(dataStuct);
      if (autoFilterRealEstateValue !== null && autoFilterValue !== null) {
        if (isAllowGrid == "true") {
          changeStatusMap();
          await handleSearchFilther();
          setAutoFilterRealEstateValue(null);
          localStorage.setItem(
            "AllowOpenGridLayoutFromAutoFilter_IndexPage",
            false
          );
        }
      }
    }
  }
  useEffect(() => {
    setStartAutoFilterRealEstate(true);
  }, []);

  useEffect(() => {
    handleAutoFilterRealEstateSwitchGridModeFromIndexPageIcon();
  }, [startAutoFilterRealEstate]);

  const typerealEstateSearchHandle = (type) => {
    const isSelected = typeRealEstateSelected.includes(type);
    if (isSelected) {
      setSearchDatafilter({
        ...searchDataFilter,
        typeRealEstate: searchDataFilter.typeRealEstate.filter(
          (item) => item !== type
        ),
      });
      setTypeRealEstateSelected(
        typeRealEstateSelected.filter((item) => item !== type)
      );
    } else {
      setSearchDatafilter({
        ...searchDataFilter,
        typeRealEstate: [...searchDataFilter.typeRealEstate, type],
      });
      setTypeRealEstateSelected([...typeRealEstateSelected, type]);
    }

    // ... rest of your code ...
  };

  function scrollToBottomSmooth() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }
  const handleModalSearch = async () => {
    setLoadingData(true); // optimize for faster load marker
    await setMarkersData([]); // optimize for faster load marker
    // scrollToBottomSmooth();
    await setIsModalSearchFilter(!isModalSearchFilter);
    setLoadingData(false); // optimize for faster load marker
  };

  const RoleSellerEstaeHandle = (type) => {
    setSearchDatafilter({ ...searchDataFilter, statusSeller: type });
    setStatusRoleSellerSearchFilterSelected(type);
  };

  const StatusRealEstateFirstHandHandle = (status) => {
    setSearchDatafilter({ ...searchDataFilter, firstHandRealEstate: status });
    setIsStatusRealEstateFirstHand(status);
  };

  const handleTypePost = (value) => {
    if (typePostRealEstate.includes(value)) {
      // Remove the item from the array
      const updatedTypePost = typePostRealEstate.filter(
        (item) => item !== value
      );
      setSearchDatafilter({
        ...searchDataFilter,
        typePostRealEstate: updatedTypePost,
      });
    } else {
      // Add the item to the array
      const updatedTypePost = [...typePostRealEstate, value];
      setSearchDatafilter({
        ...searchDataFilter,
        typePostRealEstate: updatedTypePost,
      });
    }
  };

  // Address Controllers
  const [addressData, setAddressData] = useState([]);
  const provinceArr = [];
  const [provinceData, setProvinceData] = useState([]);
  const DistrictsArr = [];
  const [districtsData, setdistrictsData] = useState([]);
  const TambonArr = [];
  const [TambonData, setTambonData] = useState([]);
  // console.log(provinceData);

  //เซ็ตข้อมูลจังหวัดเริ่มต้น
  useEffect(() => {
    // setProvinceData(DataProvinces)
    axios
      .get(
        "https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tambon.json"
      )
      .then((res) => {
        // console.log(res.data);
        setProvinceData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //เรียกใช้ฟังก์ชั่นนี้ เมื่อมีการเลือกจังหวัดที่ต้องการค้นหา
  function handleCallAmpuhre(IDProvinceUserSelected) {
    if (IDProvinceUserSelected !== "") {
      // console.log("user select province");
      const selectedProvince = provinceData.find(
        (province) => province.id == IDProvinceUserSelected
      );

      if (selectedProvince) {
        setSearchDatafilter({
          ...searchDataFilter,
          Province: selectedProvince.name_th,
        });
        setdistrictsData(selectedProvince.amphure);
      }
    }
  }
  //เรียกใช้ฟังก์ชั่นนี้ เมื่อมีการเลือกอำเภอที่ต้องค้นหา
  function handleCallTambon(IDAmpuhreUserSelected) {
    const selectedAmphure = districtsData.find(
      (district) => district.id == IDAmpuhreUserSelected
    );
    if (selectedAmphure) {
      setSearchDatafilter({
        ...searchDataFilter,
        Amphure: selectedAmphure.name_th,
      });
      setTambonData(selectedAmphure.tambon);
    }
  }
  //ขั้นตอนสุดท้าย เซ็ตตำบลไปใน search data filter
  function handleSetTambonSearchDataFilter(name_th) {
    setSearchDatafilter({ ...searchDataFilter, Tambon: name_th });
  }

  const callZipcode = async (zipcodeRaw) => {
    // console.log(zipcodeRaw);
    if (zipcodeRaw.length === 5) {
      axios
        .post(`${process.env.REACT_APP_API}/fetchData/getQueryLocation`, {
          zipcode: zipcodeRaw,
        })
        .then((res) => {
          // console.log(res);
          setAddressData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSetTambon = (e) => {
    const tambonFromUser = e.target.value;
    setSearchDatafilter({ ...searchDataFilter, Tambon: tambonFromUser });
  };

  const inputValue = (name) => (e) => {
    setSearchDatafilter({ ...searchDataFilter, [name]: e.target.value });
  };
  // Address Controllers

  // Search Filther

  // Fetch API Address when zipcode is empyty (search filter)

  const [RawDataAddressSearchFilter, setRawDataAddressSearchFilter] = useState(
    []
  );

  // useEffect(()=>{
  //   axios.get(`${process.env.REACT_APP_API}/fetchData/frontEndLocation`).then((res)=>{
  //     console.log(res.data)
  //     const allData = res.data;
  //     setRawDataAddressSearchFilter(allData)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // },[])

  const [provinceFromRawDataAddress, setProvinceFromRawDataAddress] =
    useState("");
  const [districtFromRawDataAddress, setDistrictFromRawDataAddress] =
    useState("");
  // console.log(RawDataAddressSearchFilter)

  const handleRawDataProvinceSelected = async (e) => {
    // console.log(`change...`)
    // console.log(e.target.value)
    const province = e.target.value;
    await setProvinceFromRawDataAddress(province);
    await setSearchDatafilter({ ...searchDataFilter, Province: province });
  };

  const DistrictFromRawDataAddress = RawDataAddressSearchFilter.find(
    (data) => data.name_th === provinceFromRawDataAddress
  );

  if (typeof DistrictFromRawDataAddress === "undefined") {
    const DataProvinceRaw = RawDataAddressSearchFilter.find(
      (data) => data.name_th === Province
    );

    if (DataProvinceRaw !== undefined) {
      const District = DataProvinceRaw.amphure;
      setDistrictFromRawDataAddress(District);
    }
    // console.log(DistrictArray)
  }

  // Fet API Address when zipcode is empyty (search filter)

  // Check Login

  const checkLogin = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  // Check Login

  // Search filther

  const getDataAssetsFromFilterSearch = () => {
    axios
      .post(
        `${process.env.REACT_APP_API}/event/query`,
        {
          bedroom: null,
          bathroom: null,
          selltype: searchDataFilter.typePostRealEstate,
          minPrice: searchDataFilter.minPrice,
          maxPrice: searchDataFilter.maxPrice,
          province: searchDataFilter.Province,
          amphure: searchDataFilter.Amphure,
          firsthand: searchDataFilter.firstHandRealEstate,
          seller_status: searchDataFilter.statusSeller,
          type_post: searchDataFilter.typePostRealEstate,
          type:
            autoFilterRealEstateValue !== null
              ? autoFilterRealEstateValue
              : searchDataFilter.typeRealEstate,
        },
        {
          params: {
            sort: "ASC",
            sortPrice: "cheapest", //cheapest //expensive
          },
        }
      )
      .then((res) => {
        const text = res.data.Success;
        const NotFoundAssets = "ไม่พบอสังหาตามความต้องการจากการค้นหาของคุณ";

        if (text !== NotFoundAssets) {
          setDataSearchValue(res.data.Query);
        }
        Swal.fire({
          imageUrl: "https://cdn-icons-png.flaticon.com/512/1040/1040999.png", // Custom icon URL
          imageWidth: 128, // Width of the icon
          imageHeight: 128, // Height of the icon
          html: `ค้นพบจำนวนอสังหาฯที่ท่านค้นหา<br>${res.data.Query.length} รายการ`,
          confirmButtonText: "ทราบแล้ว", // Change the label of the OK button
          icon: text === NotFoundAssets ? "warning" : null, // Only show the warning icon if not found, else null to use the custom icon
          timer: 4000, // Auto close after 3 seconds
          timerProgressBar: true, // Show the progress bar
          didOpen: (popup) => {
            // Start the progress bar animation
            Swal.showLoading();
          },

          willClose: () => {
            // Do something when the alert is about to close
          },
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire("ไม่สำเร็จ", "เกิดข้อผิดพลาด", "error");
      });
  };

  function getMarkersFromFilterSearch() {
    // console.log(`Get Markers filter...`);
    setIsLoadingMarker(true);

    axios
      .post(
        `${process.env.REACT_APP_API}/event/queryMarker`,
        {
          bedroom: null,
          bathroom: null,
          selltype: searchDataFilter.typePostRealEstate,
          minPrice: searchDataFilter.minPrice,
          maxPrice: searchDataFilter.maxPrice,
          // zipcode: searchDataFilter.zipcode,
          province: searchDataFilter.Province,
          amphure: searchDataFilter.Amphure,
          firsthand: searchDataFilter.firstHandRealEstate,
          seller_status: searchDataFilter.statusSeller,
          type_post: searchDataFilter.typePostRealEstate,
          type:
            autoFilterRealEstateValue !== null
              ? autoFilterRealEstateValue
              : searchDataFilter.typeRealEstate,
        },
        {
          params: {
            sort: "ASC",
            sortPrice: "cheapest", //cheapest //expensive
          },
        }
      )
      .then((res) => {
        // console.log(`These are the markers from the filter`, res.data.Markers);
        const markersFilter = res.data.Markers;
        setMarkersData(markersFilter);

        if (markersFilter.length > 0) {
          // console.log("RUNING")
          const marker = markersFilter[0];
          setSelectedMarker(marker);
          getDetailMarker(marker.id);
          setInfoWindowVisible(true);
          localStorage.setItem(
            "positionBeforeExitedPage",
            JSON.stringify(marker)
          );

          const delay =
            markersFilter.length > 2000
              ? 12000
              : markersFilter.length < 400
                ? 5000
                : 4500;
          setTimeout(() => {
            setIsLoadingMarker(false);
          }, delay);
        } else {
          setIsLoadingMarker(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Do Logic search when user click search in modal search filther
  const handleSearchFilther = () => {
    setIsModalSearchFilter(false);
    setIsCheckedCircle(false);
    // scrollToTopSmooth();

    // Close modal when search done

    //Get Assets from Filter
    getDataAssetsFromFilterSearch();

    //Get MarkersFromFilterSearch
    getMarkersFromFilterSearch();

    //After search reset (province ampuhre tambon)
    setSearchDatafilter({
      ...searchDataFilter,
      Province: null,
      Amphure: null,
      Tambon: null,
    });
  };

  const handleSelectedTypeRealEstate = (e) => {
    // console.log(e.target.value)

    const value = e.target.value;

    if (value === "") {
      setSearchDatafilter({ ...searchDataFilter, typePostRealEstate: null });
    } else {
      setSearchDatafilter({
        ...searchDataFilter,
        typePostRealEstate: e.target.value,
      });
    }
  };
  // Search filther

  const [infoWindowVisible, setInfoWindowVisible] = useState(false);

  // console.log(mainDataModal);

  const getDetailMarker = (id) => {
    setMainDataModal(null);
    axios
      .get(`${process.env.REACT_APP_API}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${session_id}`,
        },
      })
      .then((res) => {
        if (res.data) {

          setMainDataModal(res.data.asset);
          // Assuming you have a marker already created for each asset
          const asset = res.data.asset;
          const coordinates = res.data.asset.location.coordinates;

          // Center the map on the asset
          map.setCenter(coordinates);
        }
        // if(res.data){
        // }
      })
      .catch((err) => {
        // console.log(err.response.data);
        // if localStorage is not have id asset data for show detail in marker detail remove from local storage
        // localStorage.removeItem("positionBeforeExitedPage")
        // setSelectedMarker("");
      });
  };

  const [priceValue, setPriceValue] = useState(null);

  function handlePriceSearch(e) {
    const value = e.target.value;
    if (value == "1M") {
      setSearchDatafilter({
        ...searchDataFilter,
        minPrice: 0,
        maxPrice: 1000000,
      });
    } else if (value == "1MT3") {
      setSearchDatafilter({
        ...searchDataFilter,
        minPrice: 1000001,
        maxPrice: 3000000,
      });
    } else if (value == "3MT5") {
      setSearchDatafilter({
        ...searchDataFilter,
        minPrice: 3000001,
        maxPrice: 5000000,
      });
    } else if (value == "5MT10") {
      setSearchDatafilter({
        ...searchDataFilter,
        minPrice: 5000001,
        maxPrice: 10000000,
      });
    } else if (value == "Morethan10m") {
      setSearchDatafilter({
        ...searchDataFilter,
        minPrice: 10000000,
        maxPrice: 10000000000,
      });
    }
  }

  //check current position in google map before user exited to another page and when user back to google map page give current position before user exit
  useEffect(() => {
    const storedMarker = localStorage.getItem("positionBeforeExitedPage");
    if (storedMarker) {
      const marker = JSON.parse(storedMarker);
      setSelectedMarker(marker);
      getDetailMarker(marker.id);
      setInfoWindowVisible(true);
    }
  }, []);
  const confirmShowInfoWindowWithPositionMarkerID = sessionStorage.getItem(
    "confirmShowInfoWindowWithPosition"
  );

  useEffect(() => {
    const storedMarker = localStorage.getItem("positionBeforeExitedPage");
    if (storedMarker && confirmShowInfoWindowWithPositionMarkerID) {
      const marker = JSON.parse(storedMarker);
      setSelectedMarker(marker);
      getDetailMarker(marker.id);
      setInfoWindowVisible(true);
    }
  }, [confirmShowInfoWindowWithPositionMarkerID]);

  // Get Current User Locatoin Automatic
  useEffect(() => {
    panToUserLocation();
  }, []);

  const handleAddToCart = (assetId) => {
    // const assetId = dataProduct.id;
    axios
      .post(
        `${process.env.REACT_APP_API}/event/addBasket?assetId=${assetId}`,
        {
          assetId: assetId,
        },
        {
          headers: {
            Authorization: `Bearer ${checkLogin()}`,
          },
        }
      )
      .then((res) => {

        const msg = res.data.message;
        Swal.fire("สำเร็จ", `${msg}`, "success");
      })
      .catch((err) => {
        const errMessage = err.response.data.Error;
        Swal.fire("", `${errMessage}`, "warning");
        if (checkLogin() === undefined) {
          window.location.replace("/login");
        }
      });
  };

  function formatPriceComma(price) {
    return new Intl.NumberFormat("en-US").format(price);
  }
  function formatPrice(priceString) {
    const price = String(priceString);
    // Remove commas from the input string
    const noCommas = price.replace(/,/g, "");

    // Convert to number and divide by 1,000,000
    let priceInMillions = Number(noCommas) / 1_000_000;

    // Round to two decimal places
    priceInMillions =
      Math.round((priceInMillions + Number.EPSILON) * 100) / 100;

    // Convert back to string and append 'm'
    return priceInMillions;
  }

  function formatPrice_K(priceString) {
    const price = String(priceString);
    // Remove commas from the input string
    const noCommas = price.replace(/,/g, "");

    // Convert to number and divide by 100,000
    let priceInHundredThousand = Number(noCommas) / 1000;

    // Round to two decimal places
    priceInHundredThousand =
      Math.round((priceInHundredThousand + Number.EPSILON) * 100) / 100;

    // Convert back to string and append 'm'
    return priceInHundredThousand;
  }

  function handleClearFilterSearch() {
    setSearchDatafilter({
      typeRealEstate: [
        "house",
        "condo",
        "commercial",
        "land",
        "townhouse",
        "other",
      ],
      typePostRealEstate: [],
      Province: null,
      Amphure: null,
      Tambon: null,
      statusSeller: null,
      minPrice: null,
      maxPrice: null,
      firstHandRealEstate: null,
      Price: null,
      zipcode: null,
    });

    setTypeRealEstateSelected([
      "house",
      "condo",
      "commercial",
      "land",
      "townhouse",
      "other",
    ]);
    setStatusRoleSellerSearchFilterSelected("");
    setIsStatusRealEstateFirstHand("");
    Swal.fire("", "เลือกประเภทอสังหาฯทั้งหมดเรียบร้อย", "success");
  }

  function NavigationToGoogleMapLocation(location) {
    if (location && location.lat && location.lng) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`
      );
    } else {
      console.error("Invalid location object provided.");
    }
  }

  const handleRadiusCircleMarkerScan = (event) => {
    const radius = event.target.value;

    if (1 < radius < 5) {
      setZoomLevelControl(10);
    } else {
      setZoomLevelControl(5);
    }
    onCenterChanged();
    setRadiusCircleMarkerScan(radius);
  };

  const handleMarkerClick = useCallback(
    (marker) => {
      setPolygonKey((prevKey) => prevKey + 1);
      setSelectedMarker(marker);
      setIsExpanded(true);
      setHeightStep("half");

      // map.panBy(0, +90); // Move the map up by 80 pixels for 'half'

      setToggleModalDetail(true); // for user can select another marker when modaldetail is half
      panToUserLocationFix(marker.lat, marker.lng);

      getDetailMarker(marker.id);

      // Set URL parameter
      const url = new URL(window.location);
      url.searchParams.set("card", "full");
      url.searchParams.set("aid", marker.id);
      if (marker.lat !== undefined) {
        url.searchParams.set("lat", marker.lat);
      }
      if (marker.lng !== undefined) {
        url.searchParams.set("lng", marker.lng);
      }

      window.history.pushState({}, "", url);

      // setCenterOfMap(marker.location)
      // setInfoWindowVisible(true);
      localStorage.setItem("positionBeforeExitedPage", JSON.stringify(marker));
    },
    [getDetailMarker, setSelectedMarker, setInfoWindowVisible]
  );

  // Function to update heightStep
  const updateHeightStep = (newHeightStep) => {
    setHeightStep(newHeightStep);
  };

  // useEffect to handle heightStep changes and apply pan
  useEffect(() => {
    if (map && heightStep) {
      // Ensure map and heightStep are defined

      if (heightStep === "min") {
        SetOnMarkerScan(false);
        map.panBy(0, -110); // No pan for 'min', or reset if needed
        updateHeightStep("min");
      } else if (heightStep === "full") {
        map.panBy(0, -110); // Move the map up by 80 pixels for 'half'
        updateHeightStep("full");
      } else if (heightStep === "half") {
        map.panBy(0, +110); // Move the map up by 80 pixels for 'half'
        updateHeightStep("half");
      }
    }

    setTimeout(() => {
      SetOnMarkerScan(true);
    }, 3000); // Wait for 1 second before setting SetOnMarkerScan(true)

    // return () => clearTimeout(timer); // Cleanup timeout on component unmount or if dependencies change
  }, [heightStep, map]); // Dependency array listens to heightStep and map changes

  const urlParams = new URLSearchParams(window.location.search);
  const card = urlParams.get("card");

  useEffect(() => {
    // Parse the URL to find the 'card' parameter
    const urlParams = new URLSearchParams(window.location.search);
    const card = urlParams.get("card");


    // Update the height step based on the 'card' parameter value
    if (card === "full") {
      updateHeightStep("full");
    } else if (card === "half") {
      updateHeightStep("half");
    } else if (card === "min") {
      updateHeightStep("min");
    }
  }, []); // Dependency array is empty to run only once on component mount

  const soldOutIcons = {
    house:
      "https://s3.posperty.com/1HousePostpertySold.webp",

    condo:
      "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/icon/condo-soldout.svg",
    commercial:
      "https://s3.posperty.com/4CommercePostpertySold.webp",
    land: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/icon/land-soldout.svg",
    townhouse:
      "https://s3.posperty.com/5TownHouse_postpertySold.webp",
    other:
      "https://s3.posperty.com/others.png"
  };

  function soldOutIconUrl(type) {
    return soldOutIcons[type] || "";
  }

  // Refs for constraints
  const constraintsCheckCircle = useRef(null);

  // Set the circle check state to true
  function handleCenterOfPoint() {
    setIsCheckedCircle(true); // Set isCheckedCircle to true when clicked
  }

  const typeToIcon = {
    house: HousePostperty,
    condo: CondoPostperty,
    land: LandPostperty,
    commercial: CommercialPostperty,
    townhouse: TownHousePostperty,
    other: "https://s3.posperty.com/others.png"
  };

  const clustererOptions = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    gridSize: 80,
  };

  const [zoomLevelCurrentOfUser, setZoomLevelCurrentOfUser] = useState(16);
  const handleZoomChanged = debounce(() => {
    const currentZoomLevel = mapRef.current.getZoom();
    setZoomLevelCurrentOfUser(currentZoomLevel);

    // Perform actions based on the zoom levelaid
    if (currentZoomLevel > 10) {
      // Do something when zoom level is greater than 10
    } else {
      // Do something else for other zoom levels
    }
  }, 300);

  const [polygonKey, setPolygonKey] = useState(0);

  const [areaAsset, setAreaAsset] = useState([]);

  const [centerOfMap, setCenterOfMap] = useState(null);
  const [rawDataForSearchAreaAsset, setRawDataForSearchAreaAsset] = useState(
    []
  );
  const [modalContact, setModalContact] = useState(false);
  const centerChangedTimeout = useRef(null);

  // Michael
  const dragUpDistance = 70; // Allow dragging up by 170 pixels
  const dragDownDistance = 420; // Allow dragging down by 450 pixels
  const elementRef = useRef(null);
  const [constraints, setConstraints] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });

  useEffect(() => {
    const updateConstraints = () => {
      if (elementRef.current) {
        const { offsetWidth } = elementRef.current;
        setConstraints({
          left: 0,
          right: window.innerWidth + (offsetWidth - 93),
          top: -dragUpDistance,
          bottom: dragDownDistance,
        });
      }
    };

    updateConstraints();
    window.addEventListener("resize", updateConstraints);

    return () => {
      window.removeEventListener("resize", updateConstraints);
    };
  }, [dragUpDistance, dragDownDistance]);


  // useEffect(() => {
  //   onCenterChanged();
  // }, []);

  const getMarkerFromAreaCircle = () => {

    if (centerOfMap !== null && onMarkerScan == true) {
      const latCenterCircle = centerOfMap.lat;
      const lngCenterCircle = centerOfMap.lng;

      axios
        .post(`${process.env.REACT_APP_API}/fetchData/markerScan`, {
          centerLat: centerOfMap.lat,
          centerLng: centerOfMap.lng,
          radiusKm: RadiusCircleMarkerScan,
        })
        .then((res) => {

          // Clear data first
          setDataSearchValue("");
          setDataDisplay([]);

          setTimeout(() => {
            localStorage.setItem("LatForRecommendedAssets", centerOfMap.lat);
            localStorage.setItem("LngForRecommendedAssets", centerOfMap.lng);
            if (res.data.markers !== markersData) {
              if (res.data.markers.length !== 0) {
                // setIsLoadingMarker(true);
              }
              setMarkersData(res.data.markers);
              setDataDisplay(res.data.grid);

              setTimeout(() => {
                setIsLoadingMarker(false);
              }, 600); // 0.6 seconds
            }
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [isLoadingMarker, setIsLoadingMarker] = useState(false); // New state variable

  const onCenterChanged = () => {
    if (isSetCenterOfPoint) {
      setIsCheckedFollowRealtimeLocation(true);
      setIsSetCenterOfPoint(false);
    }

    if (isCheckedFollowRealtimeLocation === false) {
      setIsCheckedFollowRealtimeLocation(false);
      setIsSetCenterOfPoint(false);
    }

    if (isCheckedCircle) {
      if (map) {
        const newCenter = map.getCenter().toJSON();
        if (newCenter) {
          setCenterOfMap(newCenter);

          // Clear previous timeout, if any
          if (centerChangedTimeout.current) {
            clearTimeout(centerChangedTimeout.current);
          }

          // Set a new timeout for 3 seconds
          centerChangedTimeout.current = setTimeout(async () => {
            await getMarkerFromAreaCircle();
            setIsLoadingMarker(false); // Set loading state to false
          }, 1000);
        }
      }
    }

    if (heightStep === "half") {
      // setHeightStep("min"); // การด์บัค
    }
  };

  // Clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (centerChangedTimeout.current) {
        clearTimeout(centerChangedTimeout.current);
      }
    };
  }, []);

  const [assetData, setAssetData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getDetailMarkerMichael = async (aid) => {
    try {
      const response = await fetch(
        `https://api.postperty.com/api/product/${aid}`
      );
      const data = await response.json();
      setAssetData(data.asset);
      setMapCenter(data.asset.location.coordinates); // Set map center
    } catch (error) {
      console.error("Error fetching asset data:", error);
    } finally {
      setLoading(false);
    }
  };

  const useLocationFunc = useLocation();

  // useEffect to handle 'aid' parameter
  useEffect(() => {
    const params = new URLSearchParams(useLocationFunc.search);
    const aid = params.get("aid");
    const cardStatus = params.get("card");
    const gridDisplay = params.get("gridDisplay");

    if (aid) {
      getDetailMarker(aid);
      setIsExpanded(true);
      getDetailMarkerMichael(aid);

      if (cardStatus == "full") {
        setHeightStep("full");
      } else if (cardStatus == "half") {
        setHeightStep("half");
        setHeightStep("half");
      } else if (cardStatus == "min") {
        setHeightStep("min");
      }
      setToggleModalDetail(true);
      getDetailMarker(aid);
    }

    if (gridDisplay == "true") {
      setStatusHalfGridMap(false);
      SetStatusCloseMap(true);
      setIsFullGridButtonOn(true);
    } else {
      setStatusHalfGridMap(true);
      SetStatusCloseMap(false);
      localStorage.setItem("tempSwitchToMarkerOfGoogleMap", false);
    }
  }, [useLocationFunc.search]);

  const [isCheckedCircle, setIsCheckedCircle] = useState(true);
  const [isCheckedFollowRealtimeLocation, setIsCheckedFollowRealtimeLocation] =
    useState(false);
  const handleCheckboxCircleChange = (e) => {
    setIsCheckedCircle(!isCheckedCircle);
  };

  const [isSetCenterOfPoint, setIsSetCenterOfPoint] = useState(false);
  function handleCenterOfPoint() {
    // setIsCheckedFollowRealtimeLocation(true)
    setIsSetCenterOfPoint(true);
    if (mapRef.current) {
      mapRef.current.panTo(realtimeLocation);
      mapRef.current.setZoom(zoomLevelCurrentOfUser);
    } else {
      console.error("mapRef.current is undefined");
    }
  }

  const sendLocation = async (nameTarget) => {
    const lat = realtimeLocation.lat;
    const long = realtimeLocation.lng;
    axios
      .post(`https://overview.postperty.com/line`, {
        message: `${nameTarget}`,
        lat: lat,
        lng: long,
      })
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleChangeTypeOfGoogleMap() {
    if (mapType === "hybrid") {
      console.log("MAP terrain");
      changeMapType("terrain");
    } else if (mapType === "terrain") {
      console.log("MAP hybrid");
      changeMapType("hybrid");
    } else {
      changeMapType("hybrid");
    }
  }

  useEffect(() => {
    const fetchGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            // Check if pos.lat has a value
            if (pos.lat !== undefined && pos.lng !== undefined) {
              setRealtimeLocation(pos);
              // Check if mapRef.current is defined before calling panTo and setZoom
              if (mapRef.current && isCheckedFollowRealtimeLocation) {
                mapRef.current.panTo({ lat: pos.lat, lng: pos.lng });
                mapRef.current.setZoom(zoomLevelCurrentOfUser);
              } else {
                // console.error("mapRef.current is undefined");
              }
            } else {
              // Handle the case when pos.lat is undefined
              console.error("Latitude is undefined");
            }
          },
          (positionError) => {
            // Handle geolocation error
            console.error(positionError);
          },
          { enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by your browser");
      }
    };

    // Fetch geolocation every 2 seconds
    const fetchInterval = setInterval(fetchGeolocation, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(fetchInterval);
  }, [isCheckedFollowRealtimeLocation]); // Empty dependency array ensures the effect runs only once // 19 APR isCheckedFollowRealtimeLocation

  const mapContainerStyle = useMemo(() => {
    const baseStyle = { width: "100vw", height: "100vh" };
    if (statusMap === "desktop") {
      return { ...baseStyle, borderRadius: "1px" };
    }
    return baseStyle;
  }, [statusMap]);

  const memoizedOptions = useMemo(
    () => ({
      disableDefaultUI: true,
      mapTypeId: mapTypeLocal !== null ? mapTypeLocal : mapType,
      gestureHandling: "greedy",
      clickableIcons: false,
      maxZoom: 20,
      minZoom: 6,
      markerOptions: {
        optimized: true, // Keep optimized to true for performance
      },
    }),
    [mapTypeLocal, mapType]
  );

  useEffect(() => {
    // Get the current URL's search parameters
    const params = new URLSearchParams(window.location.search);

    // Check if "inviter" or "sharer" exists in the URL parameters
    const inviter = params.get('inviter');
    const sharer = params.get('sharer');

    // Set localStorage with the parameter value if it exists
    if (inviter) {
      localStorage.setItem("@ID_INVITER", inviter);
    } else if (sharer) {
      localStorage.setItem("@ID_INVITER", sharer);
    }
  }, []);

  const handleClick = useCallback(() => {
    setSelectedMarker("");
    setIsModalSearchFilter(false);
    setUserBlurSearch(true);
  }, [setSelectedMarker, setIsModalSearchFilter, setUserBlurSearch]);

  return isLoaded && !loadingData ? (
    <div className=" w-full  h-screen  flex justify-center  top-0 sticky  ">
      {isLoadingMarker && (
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-white bg-opacity-75 z-50">
          <PacmanLoader size={30} color={"#123abc"} loading={isLoadingMarker} />
          <p className="mt-4 text-lg text-gray-800">ระบบกำลังประมวลผล</p>
        </div>
      )}

      <GoogleMap
        ref={mapRef}
        mapContainerStyle={mapContainerStyle}
        onCenterChanged={onCenterChanged}
        onZoomChanged={handleZoomChanged}
        center={mapCenter}
        zoom={zoomLevelControl}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleClick}
        options={memoizedOptions}
      >
        {/* This is bug 
        Cannot read properties of undefined (reading 'x')
TypeError: Cannot read properties of undefined (reading 'x')
    at _.G.containsPoint (https://maps.googleapis.com/maps-api-v3/api/js/58/8/main.js:369:338)
        */}
        {memoizedMarkersData.map((marker) => (
          <Marker
            key={marker.id}
            position={marker.location}
            onClick={() => handleMarkerClick(marker)}
            options={{
              icon: {
                url: marker.sold ? soldOutIconUrl(marker.type) : typeToIcon[marker.type] || "",
                // url: typeToIcon[marker.type] || "",
                scaledSize: new window.google.maps.Size(35, 35),
              },
            }}
          />
        ))}


        {isCheckedCircle ? (
          <Circle
            center={centerOfMap}
            onClick={() => {
              // setInfoWindowVisible(false);
              setSelectedMarker("");
            }}
            radius={RadiusCircleMarkerScan * 1000} // Set the radius of the circle in meters
            options={{
              fillColor: "#00b8d2",
              fillOpacity: 0.15,
              strokeColor: "#00b8d2",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        ) : null}
        {/* <div className=" absolute    flex flex-col  border border-red-500    h-auto bg-white z-40 p-2.5  w-full md:max-w   -36 md:bottom-0 md:absolute">
          <label htmlFor="slider">
            รัศมี{" "}
            <span className="text-blue-600">{` ${RadiusCircleMarkerScan} K.M`}</span>{" "}
            จำนวนหมุด{" "}
            <span className="text-blue-600">{`${markersData.length}`}</span>
          </label>

          <label class="absolute right-2 top-3 inline-flex items-center mb-5 cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={isCheckedCircle}
              onChange={handleCheckboxCircleChange}
            />
            <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              เปิดรัศมี
            </span>
          </label>

          <label class="absolute right-[120px] top-3 inline-flex items-center mb-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isCheckedFollowRealtimeLocation}
              onChange={() =>
                setIsCheckedFollowRealtimeLocation(
                  !isCheckedFollowRealtimeLocation
                )
              }
            />
            <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              ติดตาม
            </span>
          </label>

          <input
            type="range"
            id="slider"
            name="slider"
            min="1"
            max="15"
            className="mb-4"
            value={RadiusCircleMarkerScan}
            onChange={handleRadiusCircleMarkerScan}
          />

          <div></div>
        </div> */}
        {mainDataModal ? (
          <motion.div
            initial={{ opacity: 0 }} // Start with opacity 0
            animate={{ opacity: 1 }} // Animate to opacity 1
            transition={{ duration: 1, delay: 0.5 }} // Transition duration (in seconds)
          >
            <ModalDetail
              dataMainModal={mainDataModal}
              id={selectedMarker.id}
              setHeightStep={setHeightStep}
              heightStep={heightStep}
              statusComponent={"map"}
            />
          </motion.div>
        ) : null}
        <Marker
          key="realtime-location"
          position={realtimeLocation}
          options={{
            icon: {
              url: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/media/blue_dot.png",
              scaledSize: new window.google.maps.Size(20, 20),
            },
            // animation: window.google.maps.Aninmation.BOUNCE, // Set animation to BOUNCE
          }}
        />
        <div className="w-full h-auto   left-1/2 tranform -translate-x-1/2 absolute top-2.5  z-10 md:w-11/12 p-2.5  lg:w-8/12 ">
          <div className="w-full h-[52px] bg-white rounded-full grid grid-cols-12 ">
            <Link
              className="col-span-3 flex items-center justify-center"
              to={`/`}
            >
              <div className="flex items-center justify-center">
                <img
                  src={LogoPostperty}
                  className="w-10 h-10"
                  alt="logo-postperty"
                />
              </div>
            </Link>
            <div className="col-span-6 rounded-full relative">
              <input
                onChange={handleValueSearch}
                onBlur={() => setUserBlurSearch(true)}
                type="text"
                className="w-full h-full outline-0 text-center"
                placeholder="ค้นหาด้วยคีย์เวิร์ด"
              />
              <BiSearch
                onClick={handleSearchValueClicked}
                className={
                  textSearchValue.length > 0
                    ? "absolute bg-blue-500 top-1/2 right-2  tranform -translate-y-1/2 w-8 h-8 rounded-full text-white p-1 	 cursor-pointer"
                    : "	 absolute top-1/2 right-2  tranform -translate-y-1/2 w-6 h-6 cursor-pointer"
                }
              />
            </div>

            <div className="col-span-3 flex items-center justify-center mt-1.5 relative">
              <Link to={`/profiles`}>
                {profileData !== null ? (
                  <img
                    src={profileData.profile_image}
                    alt="profile"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/512/2354/2354573.png"
                    }
                    alt="profile"
                    className="w-8 h-8 rounded-full"
                  />
                )}
                <p className="text-[10px] text-center">
                  {profileData !== null ? (
                    profileData.uname
                  ) : (
                    <span>Login</span>
                  )}
                </p>
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-12 justify-start  mt-2.5 gap-2.5 ">
            <div className="col-span-4 grid-cols-12 items-center">
              <div
                className="w-full  col-span-12 h-[38px]  flex text-white bg-postperty-linear-light opacity-95 rounded-full items-center justify-center p-1 cursor-pointer  shadow-lg"
                onClick={() => handleModalSearch()}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </div>
                <p className="text-[0.8rem]">ค้นหาทรัพย์</p>
              </div>
            </div>
            <div className="  col-span-4 items-center">
              <Link to={`/create/`}>
                <div className="w-full h-[38px]  flex bg-important-green  text-white rounded-full items-center justify-center p-2 ">
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                  </div>
                  <p className="ml-1 text-[0.8rem]">ลงประกาศ</p>
                </div>
              </Link>
            </div>
            <div className="col-span-4 w-full items-center ">
              <Link
                to={checkLogin() === undefined ? "/login" : "/lead/assets/"}
              >
                <div
                  className={
                    checkLogin() !== undefined
                      ? "w-full h-[38px] flex bg-red-600 text-white  rounded-full items-center justify-center  shadow-3d"
                      : "w-full h-[38px] flex bg-yellow-700 text-white  rounded-full items-center justify-center shadow-3d"
                  }
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                      <path
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                      />
                    </svg>
                  </div>
                  <p className="ml-1">
                    {checkLogin() === undefined ? (
                      <span className=" text-[0.7rem]">เข้าระบบ/สมัคร</span>
                    ) : (
                      <span>Lead ทรัพย์</span>
                    )}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Type of map */}
        <div className="w-18 h-auto  mx-auto absolute top-[9rem] right-4 z-10 flex flex-col items-center justify-center">
          <div
            className="h-auto w-full flex items-center justify-center cursor-pointer drop-shadow-xl  "
            onClick={handleChangeTypeOfGoogleMap}
          >
            <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-3d z-10">
              <img
                src="https://cdn-icons-png.flaticon.com/128/14056/14056826.png"
                alt="Map Icon"
                className="w-8 h-8"
              />
            </div>
          </div>
        </div>
        {/* Button Adapt to Center */}
        {/* Modal */}
        {isShowModalMap ? (
          <div className="  w-full min-h-80 h-auto rounded-xl mx-auto absolute bottom-24 z-40 ">
            <div className="w-full h-full relative ">
              <button
                className="text-red-500 absolute top-3 right-5 text-xl bg-white rounded-full z-50"
                onClick={handleSelectTypeOfMap}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="w-full h-full rounded-xl grid grid-col-12 ">
                <div
                  className="col-span-4 relative flex flex-col rounded-xl"
                  onClick={() => changeMapType("hybrid")}
                >
                  <h1 className="absolute text-black top-3 bg-white  rounded-full p-1  text-md  left-1/2 tranform -translate-x-1/2   ">
                    มุมมองดาวเทียม
                  </h1>
                  <img
                    src={MapTypeSatelliteImage}
                    className="w-full h-40 object-cover rounded-xl"
                    alt="map-type-icon"
                  />
                </div>
              </div>

              <div className="w-full h-full rounded-xl grid grid-col-12 mt-2.5">
                <div
                  className="col-span-4 relative flex flex-col rounded-xl"
                  onClick={() => changeMapType("terrain")}
                >
                  <h1 className="absolute text-black top-3 bg-white  rounded-full p-1  text-md  left-1/2 tranform -translate-x-1/2 ">
                    มุมมองปกติ
                  </h1>
                  <img
                    src={MapTypeTerrainImage}
                    className="w-full h-40 object-cover rounded-xl"
                    alt="map-type-terrain"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div
          className={
            statusHalfGridMap === false
              ? "hidden"
              : "w-18 h-auto  mx-auto absolute top-[9rem] left-5 z-10 flex flex-col items-center justify-center"
          }
        >
          <motion.div
            ref={elementRef}
            className="fixed left-1 h-auto cursor-pointer bg-blue-600 bg-opacity-70 p-4 rounded-md shadow-md"
            style={{ top: "8rem", width: "4.5rem" }}
            onClick={changeStatusMap}
            drag
            dragConstraints={constraints}
          >
            {/* <div
            className="h-auto w-full  cursor-pointer  drop-shadow-xl flex justify-center items-center mb-1"
            onClick={() => changeStatusMap()}
          > */}
            <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center drop-shadow-xl">
              <img
                src="https://cdn-icons-png.flaticon.com/512/238/238788.png"
                className="w-6 h-6"
                alt="change-status-map-icon"
              />
            </div>
            <p className="text-xs text-white mt-1 text-center">สลับมุมมอง</p>
          </motion.div>
          {!isCheckedCircle && (
            <motion.div
              ref={constraints}
              className="fixed left-1 h-auto cursor-pointer bg-blue-600 bg-opacity-70 p-4 rounded-md shadow-md"
              style={{ top: '16rem', width: '4.5rem' }}
              onClick={handleCheckboxCircleChange}
              drag
              dragConstraints={constraints}
            >
              <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center drop-shadow-xl">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/6633/6633300.png"
                  className="w-6 h-6"
                  alt="change-status-map-icon"
                />
              </div>
              <p className="text-xs text-white mt-1 text-center">ค้นหารอบรัศมี</p>
            </motion.div>
          )}
          {/* </div> */}
          {/* <span className="text-white  w-18  text-start  text-[12px]">
            Grid View
          </span> */}
        </div>
        {/* Change maps to grid layout */}= ={/* Pan to User Location */}
        <div className="w-18 h-auto mx-auto absolute top-[12rem] right-4 z-10 flex flex-col items-center justify-center mt-2">
          <div
            className="h-auto w-full flex items-center justify-center cursor-pointer drop-shadow-xl mb-1"
            onClick={panToUserLocation}
          >
            <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-3d">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3138/3138801.png"
                className="w-8 h-8"
                alt="pan-to-user-location-icon"
              />
            </div>
          </div>
          {/* <span className="text-white  w-20  text-start text-[12px]">
            My Location
          </span> */}
        </div>
        {/* Pan to User Location */}
        {/*  Search Filther */}
        {/* Search Filther */}
        {isModalSearchFilter ? (
          <FilterPanel
            typeRealEstateSelected={typeRealEstateSelected}
            typePostRealEstate={typePostRealEstate}
            handleClearFilterSearch={handleClearFilterSearch}
            typerealEstateSearchHandle={typerealEstateSearchHandle}
            setSearchDatafilter={setSearchDatafilter}
            searchDataFilter={searchDataFilter}
            provinceData={provinceData} // Pass actual province data
            handleCallAmpuhre={handleCallAmpuhre} // Implement function
            districtsData={districtsData} // Pass actual districts data
            handleCallTambon={handleCallTambon} // Implement function
            TambonData={TambonData} // Pass actual Tambon data
            handleSetTambonSearchDataFilter={handleSetTambonSearchDataFilter} // Implement function
            stausRoleSellerSearchFilterSelected={
              stausRoleSellerSearchFilterSelected
            } // Pass actual value
            RoleSellerEstaeHandle={RoleSellerEstaeHandle} // Implement function
            isStatusRealEstateFirstHand={isStatusRealEstateFirstHand} // Pass actual boolean value
            StatusRealEstateFirstHandHandle={StatusRealEstateFirstHandHandle} // Implement function
            handlePriceSearch={handlePriceSearch} // Implement function
            priceValue={priceValue} // Pass actual price value
            handleSearchFilther={handleSearchFilther}
            handleCloseModal={() => setIsModalSearchFilter(false)} // Close modal handler
          />
        ) : (
          <div></div>
        )}
      </GoogleMap>
    </div>
  ) : (
    <div className="w-full h-screen flex justify-center items-center">{`กำลังโหลดข้อมูล ${countdown}`}</div>
  );
}

export default GoogleMapEmbedAPIReact;

import { GrClose } from "react-icons/gr";
const ImagesUpload = ({ images, upload, setUpload, setPageNumber }) => {
  const handlePhotosRealEstate = async (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);

    await setUpload({ ...upload, images: [...upload.images, ...newImages] });
  };

  const removeImage = async (index) => {
    const newImages = images.filter((image, idx) => idx !== index);
    await setUpload({ ...upload, images: newImages });
  };

  const backtoMainPageOnce = () => {
    setPageNumber(1);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="mt-2.5 min-h-[1800px]">
      <div className="h-56 bg-half-light-f5 max-h-96 w-11/12 rounded-xl mx-auto relative  md:h-96">
        {images.length > 0 ? (
          <img
            className="w-full h-full object-contain rounded-xl"
            src={
              images.length > 0
                ? typeof images[0] === "string"  //Check image is old data url from server is true user map image normally but is not create objectURL
                  ? images[0]
                  : URL.createObjectURL(images[0])
                : ""
            }
          />
        ) : null}
        <div className="w-full h-full absolute top-0 opacity-0 z-40">
          <input
            type="file"
            name="images"
            className="w-full h-full"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handlePhotosRealEstate}
            multiple
          />
        </div>
        <h1 className="absolute inset-0 flex justify-center items-center z-0 text-xl text-black bg">
          <p className="bg-white opacity-80 rounded-full p-2 w-36 text-center">
            ใส่รูปภาพ
          </p>
        </h1>
      </div>

      {images.map((image, idx) => (
        <div key={idx} className="w-11/12 h-auto mx-auto mt-2.5 relative">
          <img
            className="w-full h-full object-contain rounded-xl"
            src={
              images.length > 0
                ? typeof images[idx] === "string"  //Check image is old data url from server is true user map image normally but is not create objectURL
                  ? images[idx]
                  : URL.createObjectURL(images[idx])
                : ""
            }
          />
          <button
            className="absolute top-2 right-5"
            onClick={() => removeImage(idx)}
          >
            <GrClose className="w-8 h-8 p-2  rounded-full bg-white text-white" />
          </button>
        </div>
      ))}
      <div className="w-full h-[200px] ">
        <div
          className="bg-postperty w-11/12 h-12 border mx-auto rounded-full flex justify-center items-center mt-6 cursor-pointer"
          onClick={backtoMainPageOnce}
        >
          ยืนยัน
        </div>
      </div>
    </div>
  );
};

export default ImagesUpload;

import { useEffect, useState } from "react";
import BankData from "./BanksData.json";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
const BanksChoose_PointCommission = () => {
  const session_id = Cookies.get("jwt");

  const [upload, setUpload] = useState({
    idcard_image: [],
    bankbook_image: [],
    bankName: null,
    bankAccountNumber: null,
    nameOwnerBank: null,
    email : null,
    address : null,
  });
  const { idcard_image, bankbook_image, bankName, nameOwnerBank , bankAccountNumber , email , address } = upload
  const [isUploading, setIsUploading] = useState(false)
  const handleIDCardAuth = async (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);

    // await setUpload({ ...upload, images: [{...images, ...newImages}] });
    await setUpload({ ...upload, idcard_image: newImages });
  };

  const handleBookBankAuth = async (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);

    // await setUpload({ ...upload, images: [{...images, ...newImages}] });
    await setUpload({ ...upload, bankbook_image: newImages });
  };

  const handleBankSelect = async (e) => {
    const value = e.target.value;
    await setUpload({ ...upload, bankName: value })
  }

  const inputValue = (name) => (e) => {
    setUpload({ ...upload, [name]: e.target.value });
  };


  const [statusUploaded , setStatusUploaded] = useState(false)
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API}/banking/getCredential`,{
      headers:{
        Authorization: `Bearer ${session_id}`
      }
    }).then((res)=>{
      console.log(res.data)
      if(res.data.status === "approved"){
        setStatusUploaded(true)
      }
    }).catch((err)=>{
      console.log(err)
    })
  },[])


  const handleUploadData = (e) => {
    e.preventDefault();
    setIsUploading(true);
    if (setIsUploading) {
      axios.post(`${process.env.REACT_APP_API}/banking/addCredential`, {
        idcard_image : idcard_image,
        bankbook_image : bankbook_image,
        bank : bankName,
        bankBookId : bankAccountNumber,
        fullname : nameOwnerBank,
        email : email,
        address : address
      }, {
        headers: {
          Authorization : `Bearer ${session_id}`,
          "Content-Type": "multipart/form-data", //Important For Recive image[] ต้องมี
        }
      })
        .then(async res => {
          // Handle res data
          console.log(res.data);
          await Swal.fire("อัพโหลดเอกสารเรียบร้อย" , "กำลังรอการตรวจสอบภายใน 3-5 วันทำการหลังจากส่งข้อมูล "  , "success")
          setIsUploading(false)
          localStorage.setItem("isSentDocumentWithDrawMoney" , true)
          window.location.replace("/user/point")
        })
        .catch(error => {
          // Handle error
          setIsUploading(false)
          console.error(error);
          Swal.fire("อัพโหลดเอกสารเรียบร้อย" , `${error.response.data.Error}`  , "error")

        });      
    }
  }
  return (
    <div className="flex flex-col w-full min-h-screen h-auto bg-postperty-linear-light items-center border">
    <div className="flex flex-col w-full min-h-screen h-auto bg-postperty-linear-light items-center px-2.5">

      <div className="w-96 mt-16 md:w-full">
        <div className="w-96 h-9  relative flex">
          <button
            className="bg-white w-9  h-6  absolute left-9 top top-1 rounded-3xl  "
            onClick={() => {
              window.location.replace('/user/point')
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              alt="backword"
              className="w-4 h-4  m-auto"
            ></img>
          </button>
          <h1 className="text-white w-full text-center mt-auto mb-auto text-xl">
            รายละเอียด
          </h1>
        </div>
      </div>

      <div className="w-full h-auto  min-h-[800px] bg-half-light mt-32 rounded-3xl md:w-3/5 mb-32 ">

        <div className={statusUploaded ? "hidden" : "h-56 bg-half-light-f5 max-h-96 w-11/12 rounded-xl mx-auto relative  md:h-96 mt-6"}>
          {idcard_image.length > 0 ? <img
            className="w-full h-full object-contain rounded-xl"
            src={
              idcard_image.length > 0
                ? URL.createObjectURL(idcard_image[idcard_image.length - 1])
                : ""
            }
          /> : null}
          <div className="w-full h-full absolute top-0 opacity-0 z-40">
            <input
              type="file"
              name="idcard_image"
              className="w-full h-full"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleIDCardAuth}
              multiple
            />
          </div>
          <h1 className="absolute inset-0 flex justify-center items-center z-0 text-xl opacity-25">
            <span className="w-full h-auto text-center">อัพโหลดรูปบัตรประจำตัวประชาชนเพื่อประกอบการ หักภาษี ณ ที่จ่าย</span>
          </h1>
        </div>

        <div className={statusUploaded ? "hidden" : "h-56 bg-half-light-f5 max-h-96 w-11/12 rounded-xl mx-auto relative  md:h-96 mt-2.5"}>
          {bankbook_image.length > 0 ? <img
            className="w-full h-full object-contain rounded-xl"
            src={
              bankbook_image.length > 0
                ? URL.createObjectURL(bankbook_image[bankbook_image.length - 1])
                : ""
            }
          /> : null}
          <div className={statusUploaded ? "hidden" : "w-full h-full absolute top-0 opacity-0 z-40"}>
            <input
              type="file"
              name="bankbook_image"
              className="w-full h-full"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleBookBankAuth}
              multiple
            />
          </div>
          <h1 className="absolute inset-0 flex justify-center items-center z-0 text-xl opacity-25 text-center">
            <span>อัพโหลดรูปหน้าบัญชีธนาคารเพื่อรับโอนเงิน</span>
          </h1>
        </div>


        <div className={statusUploaded ? "hidden" : " w-full  flex justify-center items-center mt-2.5 max-sm:max-w-xs mx-auto  md:w-11/12 mb-6 "}>
          <div className="w-full p-4 ">
            <form >
              <div className="border rounded">
                <select className="select w-full  border " onChange={handleBankSelect}>
                  <option disabled selected value={''}>เลือกธนาคาร</option>
                  <option>กสิกรไทย</option>
                  <option>กรุงเทพ</option>
                  <option>กรุงไทย</option>
                  <option>กรุงศรี</option>
                  <option>ซีไอเอ็มบี</option>
                  <option>ทหารไทยธนชาต</option>
                  <option>ไทยพาณิชย์</option>
                  <option>ยูโอบี</option>
                  <option>แลนด์ แอนด์ เอ้าส์</option>
                  <option>สแตนดาร์ดฯ</option>
                  <option>ออมสิน</option>
                  <option>เกียรตินาคินภัทร</option>
                  <option>ซิตี้แบงก์</option>
                  <option>อาคารสงเคราะห์</option>
                  <option>ธ.ก.ส</option>
                  <option>มิซูโฮ</option>
                  <option>ธ.อิสลาม</option>
                  <option>ทิสโก้</option>
                  <option>ไอซีบีซี(ไทย)</option>
                  <option>ไทยเครดิต</option>
                  <option>ซูมิโตโม มิตชุย</option>
                  <option>เอชเอสบีซี</option>
                  <option>บีเอ็นพี พารีบาล์</option>
                  <option>ดอยช์แบงก์ เอจี</option>
                </select>
              </div>

              <div className={statusUploaded ? "hidden" : "mt-2.5 flex flex-col justify-center items-center "}>
                <input onChange={inputValue("bankAccountNumber")} type="number" placeholder="เลขที่บัญชี หรือหมายเลขพร้อมเพย์" className=" input input-bordered input-info w-full max-sm:max-w-lg mx-auto" />
                <input onChange={inputValue("nameOwnerBank")} type="text" placeholder="ชื่อเจ้าของบัญชี" className=" input input-bordered input-info w-full max-sm:max-w-lg mx-auto mt-2.5 md:mt-0" />
                <input onChange={inputValue("email")} type="text" placeholder="อีเมล" className=" input input-bordered input-info w-full max-sm:max-w-lg mx-auto mt-2.5 md:mt-0" />
                <input onChange={inputValue("address")} type="text" placeholder="ที่อยู่เพื่อสำหรับจัดส่งเอกสารการรับเงิน" className=" input input-bordered input-info w-full max-sm:max-w-lg mx-auto mt-2.5 md:mt-0" />
              </div>




              <button type="subbmit" className="bg-postperty w-full h-12 rounded-full mt-6" onClick={(e)=>handleUploadData(e)} >{isUploading ? <span>กำลังอัพโหลด...</span> : <span>อัพโหลดข้อมูล</span> }</button>
            </form>
          </div>
        </div>
        <div className={statusUploaded ? " h-96 flex flex-col justify-center items-center " : "hidden"}>
          <h1 className="text-green-500 text-l md:text-3xl">คุณได้อัพโหลดเอกสารการการรับเงินเรียบร้อยแล้ว</h1>
          <p className="md:text-2xl">หากต้องการเปลี่ยนแปลงข้อมูล</p>
          <p className="md:text-2xl"> กรุณาติดต่อ admin หรือ call-center</p>

        </div>

      </div>
    </div>
    </div>
  );
};

export default BanksChoose_PointCommission;

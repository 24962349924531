import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import FormatDateTime from '../home/GridLayoutDisplay/FormatDateTime';
import FormatPrice from '../home/GridLayoutDisplay/FormatPrice';

const token = Cookies.get('jwt');

const assetTypes = [
  { label: 'ที่ดิน', value: 'land' },
  { label: 'บ้าน', value: 'house' },
  { label: 'ทาวน์เฮาส์', value: 'townhouse' },
  { label: 'พาณิชย์', value: 'commercial' },
  { label: 'คอนโด', value: 'condo' },
  { label: 'อื่น ๆ', value: 'other' },
];

export default function CartSystem() {
  const session_id = Cookies.get('jwt');
  const [dataCarts, setDataCarts] = useState([]);
  const [tempDisplayRemoveCartPost, setTempDisplayRemoveCartPost] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (session_id === undefined) {
      window.location.replace('/login');
    } else {
      getDataCarts(page, startDate, endDate, selectedTypes);
    }

    const savedPosition = Cookies.get('scrollPosition');
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    Cookies.set('scrollPosition', position);
  };

  const handleRemoveAsset = (postId) => {
    Swal.fire({
      title: 'ต้องการลบทรัพย์นี้ออกจากทรัพย์ที่ถูกใจ ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: 'dark',
      confirmButtonText: 'ยืนยัน!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API}/event/removeBasket`, {
          data: { assetId: postId },
          headers: { Authorization: `Bearer ${token}` },
        })
          .then(async (res) => {
            setTempDisplayRemoveCartPost(postId);
            setPage(1);
            getDataCarts(1, startDate, endDate, selectedTypes);
            await Swal.fire('ลบเรียบร้อย!', `คุณได้ลบ ${postId}. ออกจากรายการทรัพย์ที่สนใจแล้ว`, 'success');
          })
          .catch((err) => {
            Swal.fire('ไม่สำเร็จ!', `${err.response.data.Error}`, 'failed');
          });
      }
    });
  };

  const getDataCarts = async (page, startDate, endDate, types) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/event/getBasket`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, startDate, endDate, types: types.join(',') },
      });
      if (page === 1) {
        setDataCarts(response.data.assets);
      } else {
        setDataCarts((prevData) => [...prevData, ...response.data.assets]);
      }
      setHasMore(response.data.assets.length > 0);
    } catch (error) {
      console.error('Error fetching basket data:', error);
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      const newPage = page + 1;
      setPage(newPage);
      getDataCarts(newPage, startDate, endDate, selectedTypes);
    }
  };

  const handleFilterChange = () => {
    setPage(1);
    getDataCarts(1, startDate, endDate, selectedTypes);
  };

  const handleTypeChange = (type) => {
    setSelectedTypes((prevTypes) => {
      if (prevTypes.includes(type)) {
        return prevTypes.filter((t) => t !== type);
      } else {
        return [...prevTypes, type];
      }
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const applyFilterAndCloseModal = () => {
    handleFilterChange();
    closeModal();
  };

  return (
    <div className="bg-blue-50 py-8">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-blue-800">ทรัพย์ที่ถูกใจ</h2>
          <p className="mt-2 text-lg text-blue-600">
            ทรัพย์ที่คุณ กดถูกใจจะถูกรวมไว้ให้คุณ ที่นี่
          </p>
          <div className="flex flex-col items-center mt-4">
            <div className="flex flex-wrap justify-center space-x-2 mb-4 w-full">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="p-2 border border-gray-300 rounded mb-2 md:mb-0"
              />
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="p-2 border border-gray-300 rounded mb-2 md:mb-0"
              />
              <button
                onClick={handleFilterChange}
                className="p-2 bg-green-700 text-white rounded hover:bg-green-900 mb-2 md:mb-0 md:ml-2 w-32 h-10 flex items-center justify-center text-base"
              >
                กรอง
              </button>
              <button
                onClick={openModal}
                className="p-2 bg-blue-500 text-white rounded hover:bg-blue-700 md:ml-2 w-32 h-10 flex items-center justify-center text-base"
              >
                เลือกประเภท
              </button>
            </div>
          </div>
        </div>
        <div className="mt-12 grid gap-8 lg:grid-cols-3">
          {dataCarts.map((post) => (
            <motion.article
              key={post.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
              className={`relative bg-white p-6 rounded-lg shadow-md ${tempDisplayRemoveCartPost === post.id ? 'hidden' : ''}`}
              style={{ opacity: post.sold || post.ban ? 0.5 : 1 }}
            >
              {post.sold || post.ban && (
                <div className="absolute inset-0 bg-red-500 bg-opacity-25 flex items-center justify-center">
                  <p className="text-red-600 text-lg font-bold">ทรัพย์นี้ได้ทำการลบหรือขายออกไปแล้ว</p>
                </div>
              )}
              <div className="relative w-full h-60 mb-4">
                <Link to={post.status === false ? `/product/${post.id}` : '/home'}>
                  <img src={post.images_url.AssetImagesURL[0]} className="w-full h-full object-cover rounded-lg" alt="Property" />
                </Link>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5028/5028066.png"
                  className="w-8 h-8 cursor-pointer absolute top-2 right-2"
                  onClick={(event) => { event.stopPropagation(); handleRemoveAsset(post.id); }}
                  alt="Remove Icon"
                />
              </div>
              <div className="flex items-center gap-x-4 text-xs mb-4">
                <time className="text-gray-500">
                  <FormatDateTime isoDateString={post.date} />
                </time>
                <span className="bg-gray-100 text-gray-600 px-3 py-1 rounded-full">
                  {post.id}
                </span>
              </div>
              <Link to={post.status === false ? `/product/${post.id}` : '/home'}>
                <div>
                  <h3 className="text-xl font-semibold text-blue-900">
                    {post.title.slice(0, 10)}
                  </h3>
                  <h4 className="text-lg text-blue-700">
                    <FormatPrice number={post.sell_price == null ? '' : post.sell_price} suffix={`${post.sell_price == null ? '' : 'บาท'}`} />
                  </h4>
                  <p className="text-blue-600 mt-2">{post.description.slice(0, 40)}...</p>
                  <div className="flex gap-2 mt-4">
                    {post.rooms.Bedroom && (
                      <div className="flex items-center space-x-2">
                        <img src="https://cdn-icons-png.flaticon.com/512/2642/2642252.png" alt="Bedroom Icon" className="w-6 h-6" />
                        <span>{post.rooms.Bedroom}</span>
                      </div>
                    )}
                    {post.rooms.Bathroom && (
                      <div className="flex items-center space-x-2">
                        <img src="https://cdn-icons-png.flaticon.com/512/3565/3565091.png" alt="Bathroom Icon" className="w-6 h-6" />
                        <span>{post.rooms.Bathroom}</span>
                      </div>
                    )}
                    {post.type && (
                      <div className="flex items-center space-x-2">
                        <img src="https://cdn-icons-png.flaticon.com/512/4305/4305629.png" alt="Type Icon" className="w-6 h-6" />
                        <span>{post.type}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </motion.article>
          ))}
        </div>
        {hasMore && (
          <div className="flex justify-center mt-8">
            <button onClick={handleLoadMore} className="p-2 bg-blue-500 text-white rounded hover:bg-blue-700">
              โหลดเพิ่มเติม
            </button>
          </div>
        )}
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  เลือกประเภททรัพย์สิน
                </Dialog.Title>
                <div className="mt-4">
                  {assetTypes.map((type) => (
                    <label key={type.value} className="flex items-center space-x-1 my-2">
                      <input
                        type="checkbox"
                        value={type.value}
                        checked={selectedTypes.includes(type.value)}
                        onChange={() => handleTypeChange(type.value)}
                      />
                      <span>{type.label}</span>
                    </label>
                  ))}
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-700"
                    onClick={applyFilterAndCloseModal}
                  >
                    ตกลง
                  </button>
                  <button
                    type="button"
                    className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
                    onClick={closeModal}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const FilterPanel = ({
    typeRealEstateSelected,
    typePostRealEstate,
    handleClearFilterSearch,
    typerealEstateSearchHandle,
    setSearchDatafilter,
    searchDataFilter,
    provinceData,
    handleCallAmpuhre,
    districtsData,
    handleCallTambon,
    stausRoleSellerSearchFilterSelected,
    RoleSellerEstaeHandle,
    isStatusRealEstateFirstHand,
    StatusRealEstateFirstHandHandle,
    handleSearchFilther,
    handleCloseModal,  // New prop for closing modal
}) => {
    // State for managing selected price range
    const [selectedPriceRange, setSelectedPriceRange] = useState('');

    const priceRanges = [
        { label: 'ทุกช่วงราคา', value: '0-9999999' },
        { label: '0 - 1,000,000 บาท', value: '0-1000000' },
        { label: '1,000,000 - 3,000,000 บาท', value: '1000000-3000000' },
        { label: '3,000,000 - 5,000,000 บาท', value: '3000000-5000000' },
        { label: '5,000,000 - 7,000,000 บาท', value: '5000000-7000000' },
        { label: '7,000,000 - 15,000,000 บาท', value: '7000000-15000000' },
        { label: '15,000,000 - 300,000,000 บาท', value: '15000000-300000000' },
    ];

    // Save selected price range to localStorage
    const handlePriceSelectChange = (e) => {
        const value = e.target.value;
        setSelectedPriceRange(value);
        localStorage.setItem('selectedPriceRange', value); // Save to localStorage
    };

    // Load price range from localStorage on mount
    useEffect(() => {
        const savedPriceRange = localStorage.getItem('selectedPriceRange');
        if (savedPriceRange) {
            setSelectedPriceRange(savedPriceRange);
        }
    }, []);

    // Sort province data alphabetically by name_th
    const sortedProvinceData = [...provinceData].sort((a, b) => a.name_th.localeCompare(b.name_th, 'th'));

    return (
        <motion.div
            className="fixed bottom-0 md:transform md:-translate-x-1/2 w-full md:w-3/4 lg:w-1/3 xl:w-3/5 2xl:w-3/5 h-full overflow-y-auto bg-white/60 backdrop-blur-lg shadow-lg rounded-t-2xl z-50 p-4 pb-16 border border-white/20"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'spring', stiffness: 100, delay: 1.2 }}
        >
            <div className="relative w-full">
                {/* Close Modal Button */}
                <button
                    className="absolute top-4 right-4 z-50"
                    onClick={handleCloseModal}
                >
                    <img
                        src="https://cdn-icons-png.flaticon.com/512/9068/9068699.png"
                        alt="Close"
                        className="w-8 h-8"
                    />
                </button>

                {/* Clear Filter Button */}
                <button
                    className="absolute top-10 right-10 z-50 flex items-center space-x-2 bg-green-500 text-white font-bold px-4 py-2 rounded-full shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 transition-colors duration-200 ease-in-out"
                    onClick={handleClearFilterSearch}
                >
                    <span>เลือกทั้งหมด</span>
                    <img
                        src="https://cdn-icons-png.flaticon.com/512/15263/15263147.png"
                        alt="Reset"
                        className="w-6 h-6"
                    />
                </button>

                {/* Filter Content */}
                <div className="w-11/12 mx-auto mt-4">
                    <h4 className="text-center text-lg font-semibold">ค้นหาอสังหาฯ</h4>

                    {/* Property Type Filter */}
                    <div className="mt-6">
                        <p className="font-bold">ชนิดอสังหาริมทรัพย์</p>
                        <div className="grid grid-cols-3 gap-3 mt-4">
                            {['house', 'condo', 'townhouse', 'land', 'commercial', 'other'].map((type) => (
                                <motion.div
                                    key={type}
                                    className={`rounded-full w-full h-11 flex justify-center items-center ${typeRealEstateSelected.includes(type) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} cursor-pointer`}
                                    onClick={() => typerealEstateSearchHandle(type)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {
                                        type === 'house' ? 'บ้านเดี่ยว' :
                                            type === 'condo' ? 'คอนโด' :
                                                type === 'townhouse' ? 'ทาวน์เฮ้าส์' :
                                                    type === 'land' ? 'ที่ดิน' :
                                                        type === 'commercial' ? 'เชิงพาณิชย์' :
                                                            type === 'other' ? 'อื่นๆ' : ''
                                    }
                                </motion.div>
                            ))}
                        </div>
                    </div>

                    {/* Sale Type Filter */}
                    <div className="mt-6">
                        <p className="font-bold">ประเภทการขาย</p>
                        <div className="grid grid-cols-3 gap-3 mt-4">
                            {[
                                { label: 'ขาย', key: 'sell' },
                                { label: 'เช่า', key: 'rent' },
                                { label: 'เซ้ง', key: 'sell_out' },
                            ].map((data) => (
                                <motion.div
                                    key={data.key}
                                    className={`rounded-full w-full h-11 flex justify-center items-center ${typePostRealEstate.includes(data.label) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} cursor-pointer`}
                                    onClick={() =>
                                        setSearchDatafilter({
                                            ...searchDataFilter,
                                            typePostRealEstate: typePostRealEstate.includes(data.label)
                                                ? typePostRealEstate.filter((type) => type !== data.label)
                                                : [...typePostRealEstate, data.label],
                                        })
                                    }
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {data.label}
                                </motion.div>
                            ))}
                        </div>
                    </div>

                    {/* Redesigned Dropdown for Price Range */}
                    <div className="mt-6">
                        <p className="font-bold">ช่วงราคา</p>
                        <div className="relative w-full mt-4">
                            <select
                                className="w-full h-11 bg-gray-100 rounded-full text-center px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 hover:bg-gray-200 cursor-pointer appearance-none"
                                value={selectedPriceRange}
                                onChange={handlePriceSelectChange}
                            >
                                <option value="" disabled className="text-gray-400">
                                    เลือกช่วงราคา
                                </option>
                                {priceRanges.map((range) => (
                                    <option key={range.value} value={range.value} className="p-2 hover:bg-blue-100">
                                        {range.label}
                                    </option>
                                ))}
                            </select>
                            {/* Dropdown Icon */}
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <svg
                                    className="w-5 h-5 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    {/* Location Filter: Province and Amphure (No Tambon) */}
                    <div className="mt-6">
                        <p className="font-bold">จังหวัด / อำเภอ</p>
                        <div className="grid grid-cols-2 gap-3 mt-4"> {/* Changed to 2 columns */}
                            <select
                                className="w-full h-11 bg-gray-200 rounded-full text-center"
                                onChange={(e) => handleCallAmpuhre(e.target.value)}
                            >
                                <option value="">เลือกจังหวัด</option>
                                {sortedProvinceData.map((province) => (
                                    <option key={province.id} value={province.id}>
                                        {province.name_th}
                                    </option>
                                ))}
                            </select>

                            <select
                                className="w-full h-11 bg-gray-200 rounded-full text-center"
                                onChange={(e) => handleCallTambon(e.target.value)}
                            >
                                <option value="">เลือกอำเภอ</option>
                                {districtsData &&
                                    districtsData.map((district) => (
                                        <option key={district.id} value={district.id}>
                                            {district.name_th}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>

                    {/* Seller Status Filter */}
                    <div className="mt-6">
                        <p className="font-bold">สถานะผู้ขาย</p>
                        <div className="grid grid-cols-2 gap-3 mt-4">
                            {['owner', 'agent'].map((status) => (
                                <motion.div
                                    key={status}
                                    className={`rounded-full w-full h-11 flex justify-center items-center ${stausRoleSellerSearchFilterSelected === status ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} cursor-pointer`}
                                    onClick={() => RoleSellerEstaeHandle(status)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {status === 'owner' ? 'เจ้าของขายเอง' : 'ตัวแทนขาย'}
                                </motion.div>
                            ))}
                        </div>
                    </div>


                    {/* Seller Status Filter */}
                    {/* <div className="mt-6">
                        <p className="font-bold">ประเภททรัพย์</p>
                        <div className="grid grid-cols-3 gap-3 mt-4">
                            {['user', 'official', 'lead'].map((status) => (
                                <motion.div
                                    key={status}
                                    className={`rounded-full w-full h-11 flex justify-center items-center ${stausRoleSellerSearchFilterSelected === status
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-200 text-black'
                                        } cursor-pointer`}
                                    onClick={() => RoleSellerEstaeHandle(status)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {status === 'user'
                                        ? 'ผู้ใช้งานโพสต์'s
                                        : status === 'official'
                                            ? 'บริษัทโพสต์'
                                            : 'ทรัพย์ Lead'}
                                </motion.div>
                            ))}
                        </div>
                    </div> */}


                    {/* Real Estate Condition Filter */}
                    <div className="mt-6">
                        <p className="font-bold">สภาพอสังหาฯ</p>
                        <div className="grid grid-cols-2 gap-3 mt-4">
                            {['มือหนึ่ง', 'มือสอง'].map((condition, index) => (
                                <motion.div
                                    key={index}
                                    className={`rounded-full w-full h-11 flex justify-center items-center ${isStatusRealEstateFirstHand === (condition === 'มือหนึ่ง') ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} cursor-pointer`}
                                    onClick={() => StatusRealEstateFirstHandHandle(condition === 'มือหนึ่ง')}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {condition}
                                </motion.div>
                            ))}
                        </div>
                    </div>

                    {/* Search Button */}
                    <div className="flex justify-center items-center mt-6 mb-8"> {/* Added bottom margin here */}
                        <motion.button
                            className="w-full md:w-1/2 h-12 bg-blue-500 rounded-full text-white font-bold"
                            onClick={handleSearchFilther}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            ค้นหาทรัพย์
                        </motion.button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default FilterPanel;

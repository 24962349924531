import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import WindowIcon from '@mui/icons-material/Window';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import './css/styles.css';


export default function NavigationModern() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleNavigation = (url, newValue) => {
    navigate(url);
    setValue(newValue);
  };

  return (
    <main className="fixed bottom-0 min-w-[240px] w-full h-18 z-50">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label={
            <Typography variant="body2" style={{ fontFamily: 'Prompt, sans-serif', fontSize: '11.6px' }}>
              หน้าหลัก
            </Typography>
          }
          icon={<WindowIcon />}
          onClick={() => handleNavigation('/home', 0)}
          className={value === 0 ? 'active-underline' : 'inactive-underline'}
        />
        <BottomNavigationAction
          label={
            <Typography variant="body2" style={{ fontFamily: 'Prompt, sans-serif', fontSize: '11.6px' }}>
              ติดต่อเรา
            </Typography>
          }
          icon={<ChatBubbleIcon />}
          onClick={() => handleNavigation('/contact', 1)}
          className={value === 1 ? 'active-underline' : 'inactive-underline'}
        />
        <BottomNavigationAction
          label={
            <Typography variant="body2" style={{ fontFamily: 'Prompt, sans-serif', fontSize: '11.6px' }}>
              ทรัพย์ถูกใจ
            </Typography>
          }
          icon={<ShoppingCartIcon />}
          onClick={() => handleNavigation('/cart', 2)}
          className={value === 2 ? 'active-underline' : 'inactive-underline'}
        />
        <BottomNavigationAction
          label={
            <Typography variant="body2" style={{ fontFamily: 'Prompt, sans-serif', fontSize: '11.6px' }}>
              แจ้งเตือน
            </Typography>
          }
          icon={<NotificationsActiveIcon />}
          onClick={() => handleNavigation('/noti', 3)}
          className={value === 3 ? 'active-underline' : 'inactive-underline'}
        />
        <BottomNavigationAction
          label={
            <Typography variant="body2" style={{ fontFamily: 'Prompt, sans-serif', fontSize: '11.6px' }}>
              เพิ่มเติม
            </Typography>
          }
          icon={<MenuIcon />}
          onClick={() => handleNavigation('/profiles', 4)}
          className={value === 4 ? 'active-underline' : 'inactive-underline'}
        />
      </BottomNavigation>
    </main>
  );
}

import axios from "axios";
import { MdContentCopy } from "react-icons/md";
import Swal from "sweetalert2";
import { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";
import "canvas-toBlob";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion

const InviteFriend = () => {
  const [uName, setUName] = useState("");
  const inviteLink = `https://postperty.com/register?ID=${uName}`;
  const qrCodeCanvasRef = useRef(null);

  useEffect(() => {
    const token = Cookies.get("jwt");
    axios
      .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => setUName(res.data.uname))
      .catch(console.error);
  }, []);

  useEffect(() => {
    getBrochure();
  }, []);

  const [dataBrochure, setDataBrochure] = useState("");
  function getBrochure() {
    axios
      .post(
        `${process.env.REACT_APP_API}/event/getBrochure`,
        { nothing: "" },
        { headers: { Authorization: `Bearer ${Cookies.get("jwt")}` } }
      )
      .then((res) => setDataBrochure(res.data.BrochureURL))
      .catch(console.error);
  }

  const copyLinkInvite = async () => {
    await navigator.clipboard.writeText(inviteLink);
    Swal.fire(
      "คัดลอกสำเร็จ",
      "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้",
      "success"
    );
  };

  const saveQRCodeImage = () => {
    if (qrCodeCanvasRef.current) {
      const canvas = qrCodeCanvasRef.current.querySelector("canvas");
      if (canvas) {
        canvas.toBlob((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `postperty_invite_link_${uName}.png`;
          link.click();
        }, "image/png");
      }
    }
  };

  const [checkPhone, setCheckPhone] = useState({ phone: null });
  const [textCheckPhoneNumber, setTextCheckPhoneNumber] = useState(
    "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
  );

  const checkPhoneNumber = (number) => {
    if (number && number.length === 10) {
      axios
        .post(`${process.env.REACT_APP_API}/user/checkPhoneNumber`, {
          checkPhoneNumber: number,
        })
        .then((res) => {
          setTextCheckPhoneNumber(
            res.data.Status
              ? "เบอร์นี้สมัครสมาชิกแล้ว"
              : "เบอร์นี้ยังไม่เป็นสมาชิก สามารถชวนได้"
          );
        })
        .catch(console.error);
    } else {
      setTextCheckPhoneNumber("กรุณากรอกเบอร์โทร 10หลัก");
    }
  };

  const inputValue = (name) => (e) => {
    setCheckPhone({ ...checkPhone, [name]: e.target.value });
  };

  const handleCopyLinkVideo = () => {
    navigator.clipboard.writeText("https://www.youtube.com/watch?v=CIm8ZCTFXOU");
    Swal.fire(
      "คัดลอกลิงค์Videoสำเร็จ",
      "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้",
      "success"
    );
  };

  return (
    <motion.div
      className="flex flex-col items-center w-full bg-gradient-to-b from-blue-50 to-blue-100 min-h-screen px-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Header */}
      <div className="w-full mt-16 flex items-center justify-center">
        <Link to={`/profiles`}>
          <motion.button
            className="absolute left-6 bg-white shadow-md w-10 h-10 rounded-full flex items-center justify-center"
            initial={{ scale: 0.9 }}
            whileHover={{ scale: 1.1 }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              alt="back"
              className="w-4 h-4"
            />
          </motion.button>
        </Link>
        <h1 className="text-2xl text-blue-600 font-bold">แนะนำเพื่อน</h1>
      </div>

      {/* Main Container */}
      <div className="flex flex-col md:flex-row md:gap-8 w-full max-w-screen-lg mt-12">
        {/* Left Section */}
        <motion.div
          className="bg-white p-8 rounded-2xl shadow-lg w-full md:w-1/2 flex flex-col items-center mb-8 md:mb-0"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl font-bold text-gray-800">แนะนำเพื่อน</h2>
          <p className="text-xl text-gray-500 mt-2">POSTPERTY</p>
          <p className="text-center mt-6 font-semibold">ขั้นตอนที่ 1</p>
          <p className="text-center text-gray-500">
            ตรวจสอบเบอร์โทรศัพท์เพื่อนของคุณ ว่าเขาเป็นสมาชิกแล้วหรือยัง
          </p>
          <input
            type="text"
            placeholder="เบอร์โทรศัพท์"
            onChange={(e) => {
              inputValue("phone")(e);
              checkPhoneNumber(e.target.value);
            }}
            className="mt-4 w-full h-12 rounded-full bg-gray-100 text-center outline-none"
          />
          <p
            className={
              textCheckPhoneNumber.includes("ยังไม่เป็นสมาชิก")
                ? "text-green-500"
                : "text-red-500"
            }
          >
            {textCheckPhoneNumber}
          </p>

          <p className="mt-8 font-semibold">ขั้นตอนที่ 2</p>
          <p className="text-center text-gray-500">
            คัดลอกลิงค์และส่งต่อให้เพื่อนของคุณ
          </p>
          <motion.button
            className="w-full h-12 mt-4 bg-blue-100 rounded-full text-gray-800 text-sm font-medium flex justify-center items-center shadow-sm"
            onClick={copyLinkInvite}
            whileHover={{ scale: 1.05 }}
          >
            {inviteLink}
          </motion.button>
          <div className="flex flex-col items-center mt-4">
            <p className="text-gray-500">User ID ของคุณ</p>
            <h1 className="text-4xl mt-2 font-semibold">{uName}</h1>
          </div>
        </motion.div>

        {/* Right Section - QR Code */}
        <motion.div
          className="bg-white p-8 rounded-2xl shadow-lg w-full md:w-1/2 flex flex-col items-center"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-xl text-gray-700 font-semibold mb-6">
            Scan เพื่อสมัคร
          </h2>
          <div ref={qrCodeCanvasRef} className="flex justify-center">
            <QRCode value={inviteLink} bgColor="#fff" size={170} />
          </div>
          <motion.button
            onClick={saveQRCodeImage}
            className="mt-8 w-full h-12 bg-blue-500 text-white rounded-full font-medium shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            บันทึก QR Code
          </motion.button>
        </motion.div>
      </div>

      {/* Brochure Section */}
      <div className="bg-white p-6 mt-12 rounded-2xl shadow-lg w-full max-w-screen-lg">
        <motion.div
          className="relative rounded-xl overflow-hidden"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <img
            className="object-cover w-full h-64"
            src={dataBrochure}
            alt="Brochure Front"
          />
          <p
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black bg-opacity-70 px-4 py-2 rounded-full cursor-pointer"
            onClick={() => window.open(dataBrochure)}
          >
            คลิกดาวน์โหลดโบรชัวร์ด้านหน้า
          </p>
        </motion.div>
        <motion.div
          className="relative rounded-xl overflow-hidden mt-8"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <img
            className="object-cover w-full h-64"
            src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/brochure/back/โบรชัวร์_postperty_ด้านหลัง.png"
            alt="Brochure Back"
          />
          <p
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black bg-opacity-70 px-4 py-2 rounded-full cursor-pointer"
            onClick={() =>
              window.open(
                "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/brochure/back/โบรชัวร์_postperty_ด้านหลัง.png"
              )
            }
          >
            คลิกดาวน์โหลดโบรชัวร์ด้านหลัง
          </p>
        </motion.div>
      </div>

      {/* Video Copy Button */}
      <motion.button
        className="w-10/12 max-w-lg h-12 mt-12 bg-green-500 text-white rounded-full flex justify-center items-center shadow-md"
        onClick={handleCopyLinkVideo}
        whileHover={{ scale: 1.05 }}
      >
        คัดลอกวีดีโอแนะนำ postperty ส่งให้เพื่อน
      </motion.button>
    </motion.div>
  );
};

export default InviteFriend;

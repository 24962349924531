import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Snowfall from 'react-snowfall';

const Contact = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center py-10 px-4 md:px-0">
      <Snowfall snowflakeCount={200} />
      <div className="flex flex-col w-full max-w-6xl items-center md:grid md:grid-cols-12 gap-8">
        <motion.div
          className="md:col-span-12 flex flex-col items-center md:items-start w-full"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-medium mt-6 text-center md:text-left">ติดต่อเรา</h1>
          <h2 className="text-xl font-medium text-center md:text-left">
            POSTPERTY มีทีมงานที่พร้อมให้บริการ{" "}
            <span className="text-postperty mt-3">24 ชั่วโมง</span>
          </h2>
        </motion.div>

        <motion.div
          className="h-auto w-full md:w-8/12 md:ml-8 mb-8 p-4 rounded-xl md:border mx-auto mt-6 flex flex-col space-y-4 md:col-span-12 md:flex-row md:flex-wrap md:justify-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <ContactLink
            url="https://line.me/ti/p/%40postperty"
            imgSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/LINE_logo.svg/2048px-LINE_logo.svg.png"
            altText="line-icon"
            text="Line Official"
          />
          <ContactLink
            url="https://www.facebook.com/PostpertyDotcom"
            imgSrc="https://cdn-icons-png.flaticon.com/512/5968/5968764.png"
            altText="facebook-icon"
            text="FB Fanpage"
          />
          <ContactLink
            url="https://www.instagram.com/postperty/"
            imgSrc="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
            altText="instagram-icon"
            text="IG Official"
          />
          <ContactPhone
            phoneNumber="tel:02-123-3632"
            imgSrc="https://cdn-icons-png.flaticon.com/512/6784/6784196.png"
            altText="phone-icon"
            text="02-123-3632"
          />
          <ContactPhone
            phoneNumber="tel:+66910920333"
            imgSrc="https://cdn-icons-png.flaticon.com/512/6784/6784196.png"
            altText="phone-icon"
            text="091-092-0333"
          />
        </motion.div>

        <motion.div
          className="w-full h-72 md:h-80 rounded-xl mx-auto z-40 md:w-96 md:col-span-6 shadow-lg overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <iframe
            title="POSTPERTY CO,.LTD"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.1788285970681!2d100.03521695239088!3d13.78726510831891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2e7b5dfd2c139%3A0xa37e8248693e6df8!2sPOSTPERTY%20CO.%2C%20LTD.!5e0!3m2!1sth!2sth!4v1681114387115!5m2!1sth!2sth"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </motion.div>

        <motion.div
          className="w-full md:col-span-6 flex flex-col items-center mt-2.5"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="w-full h-auto mx-auto">
            <div className="flex flex-col items-center border rounded-xl p-4 shadow-lg">
              <h1 className="font-medium text-xl md:text-3xl">
                บริษัท โพสต์เพอร์ตี้ จำกัด
              </h1>
              <h1 className="font-medium text-xl md:text-2xl">
                POSTPERTY CO,.LTD
              </h1>
              <p className="mt-5 text-center">
                88/91 หมู่ 8 ต.บางแขม อ.เมืองนครปฐม จ.นครปฐม 73000
              </p>
            </div>
          </div>

          <Link to={`/jointeam`} className="mt-8">
            <motion.div
              className="h-12 w-full max-w-xs mx-auto bg-postperty-linear-light rounded-full text-white flex justify-center items-center shadow-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              ร่วมงานกับเรา
            </motion.div>
          </Link>
          <div className="mt-24"></div>
        </motion.div>
      </div>
    </div>
  );
};

const ContactLink = ({ url, imgSrc, altText, text }) => (
  <motion.div
    className="w-full h-14 bg-half-light-2 rounded-xl flex items-center justify-around p-4 shadow-sm cursor-pointer"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Link to={url} target="_blank" className="flex items-center w-full">
      <img src={imgSrc} alt={altText} className="w-10 h-10" />
      <p className="font-medium text-center flex-1">{text}</p>
      <img
        src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
        alt="arrow"
        className="w-3 h-3"
      />
    </Link>
  </motion.div>
);

const ContactPhone = ({ phoneNumber, imgSrc, altText, text }) => (
  <motion.div
    className="w-full h-14 bg-half-light-2 rounded-xl flex items-center justify-around p-4 shadow-sm cursor-pointer"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <a href={phoneNumber} className="flex items-center w-full">
      <img src={imgSrc} alt={altText} className="w-10 h-10" />
      <p className="font-medium text-center flex-1">{text}</p>
      <img
        src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
        alt="arrow"
        className="w-3 h-3"
      />
    </a>
  </motion.div>
);

export default Contact;

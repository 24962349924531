import React from 'react';
import { Link } from 'react-router-dom';

const PreformattedText = ({ text, maxLength }) => {
  if (typeof text !== 'string') {
    return null;
  }

  const words = text.split(' ');
  const truncatedText = maxLength && text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <div className="text-base max-w-full max-sm:max-w-none max-sm:h-700px overflow-hidden">
      <pre className="whitespace-pre-wrap">
        {words.map((word, index) => {
          // Check if the word starts with 'http://' or 'https://'
          if (word.startsWith('http://') || word.startsWith('https://')) {
            // Cut emojis from the start and end of the word
            const trimmedWord = word.replace(/(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu, '');
          
            return (
              <Link
                key={index}
                to={trimmedWord}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                {word}
              </Link>
            );
          }

          return <span key={index}>{word} </span>;
        })}
      </pre>
      {maxLength && text.length > maxLength && (
        <div className="text-xs text-gray-500 mt-1 cursor-pointer" title={text}>
          {truncatedText}
        </div>
      )}
    </div>
  );
};

export default PreformattedText;

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import {MdArrowBackIosNew} from "react-icons/md"

export default function DetailMessageInbox() {
    const [MessageUser, setMessageUser] = useState(null);
    const [messageData, setMessageData] = useState(null);   
    const session_id = Cookies.get("jwt");
    const messageID = sessionStorage.getItem("messageID");

    function getData(){
    
        if (messageID) {
            axios
                .post(`${process.env.REACT_APP_API}/inbox/readMessage`, {
                    "messageId": messageID
                }, {
                    headers: {
                        Authorization: `Bearer ${session_id}`
                    }
                })
                .then((res) => {
                    console.log(res.data);
                    const messageDataUser = res.data.message.user;
                    const messageData = res.data.message.message;
                    setMessageData(messageData);
                    setMessageUser(messageDataUser);
                });
        }
    }
    useEffect(() => {
        getData()
    }, []);

    useEffect(()=>{
        getData()
    },[messageID])

    function handleBackward(){
      window.location.replace('/noti')
    }

    if (MessageUser == null || messageData == null) {
      setTimeout(() => {
          getData();
      }, 1000); // Wait for 1 second (1000 milliseconds)
      
      return <div className="flex flex-col justify-center items-center h-screen w-full">กำลังโหลดข้อมูล...</div>; // Return a loading indicator
  }
    
    return (
        <div className="relative w-full h-screen md:w-10/12 md:mx-auto ">
            <MdArrowBackIosNew className="absolute text-2xl  top-6 left-5" onClick={handleBackward} />
          <div className="grid grid-cols-12 grid-center h-[70px] mt-[20px]">
            <motion.div
              className="col-span-3 flex justify-end items-center w-full h-full"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
            >
              <img className="h-[60px] rounded-full" src={MessageUser.sender.profile_image} />
            </motion.div>
            <motion.div
              className="col-span-8 flex justify-start items-start w-full h-full border-b mb-2.5"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h1 className="h-full flex flex-col w-full justify-center ml-2.5">
                <span className="font-bold">{MessageUser.sender.uname}</span>
                <span>
                  ถึง:<span className="text-gray-500">{MessageUser.receiver.uname}</span>
                </span>
              </h1>
            </motion.div>
          </div>
    
          <div className="w-full h-full md:w-7/12  md:mx-auto">
            <motion.h1 className="ml-12 text-xl font-bold mt-2.5 md:ml-2" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {messageData.title}
            </motion.h1>
    
            <motion.div
              className="w-9/12 mx-auto mt-6 h-full mx-auto break-words"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              {messageData.description}
            </motion.div>
          </div>
        </div>
      );
}
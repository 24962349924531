import { useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function SelectPostType() {
  const checkLogin = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  useEffect(() => {
    if (checkLogin() === undefined) {
      window.location.replace(`/login`);
    }
  }, []);

  const dataPosts = [
    {
      link: "/create/house",
      imgSrc: "https://images.unsplash.com/photo-1633683914992-ded93eb8b18a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1043&q=80",
      title: "ขายบ้านเดี่ยว",
      type : "house"
    },
    {
      link: "/create/townhouse",
      imgSrc: "https://images.unsplash.com/photo-1571845358026-95ef0b176d56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      title: "ขายทาวน์เฮ้าส์",
      type : "townhouse"
    },
    {
      link: "/create/condo",
      imgSrc: "https://images.unsplash.com/photo-1652878530836-b726d47cfe90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
      title: "ขายคอนโด",
      type : "condo"
    },
    {
      link: "/create/commercial",
      imgSrc: "https://plus.unsplash.com/premium_photo-1674730952140-340d151b042f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=686&q=80",
      title: "ขายอาคารพาณิชย์",
      type : "commercial_building"
    },
    {
      link: "/create/land",
      imgSrc: "https://images.unsplash.com/photo-1571845358026-95ef0b176d56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
      title: "ขายที่ดิน",
      type : "create_land"
    },
    {
      link: "/create/others",
      imgSrc: "https://images.unsplash.com/photo-1552783160-27bfdb2625d5?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "อื่นๆ (โกดัง โรงงาน)",
      type : "create_land"
    }
  ];


  function handleSelectTypeOfRealAsset(typeRealAsset){
    window.location.replace(`/create/real_asset/${typeRealAsset}`)
  }

  return (
    <div className="max-w-6xl mx-auto h-screen ">
      <div className="w-11/12 rounded-xl h-full mx-auto my-2  mt-6 ">
        <h1 className="w-full text-center text-3xl mb-6">ลงประกาศ</h1>



        {/* <div className="border border-t-500 flex justify-center rounded bg-postperty-linear-light text-white">
            <h1>ช่วยขาย</h1>
          </div>

        <Link  to={`/help/postperty`}>
            <div className="max-h-[120px] h-[120px] w-full my-2.5  rounded-xl">
              <div className="w-full h-full cursor-pointer relative rounded-xl">
                <div className="relative rounded-xl">
                  <img
                    className="object-cover object-center w-full h-[120px] rounded-xl"
                    src={`https://i.ytimg.com/vi/GKY-mGZL17M/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLClzmYMOqyuRjXY4IQYZFVO_VGkwQ`}
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center rounded-xl">
                    <h1 className="text-2xl text-white text-center ml-6">ให้ Postperty ช่วยขาย</h1>
                    <div className="ml-auto">
                      <MdKeyboardArrowRight className="text-white bg-black opacity-90 text-3xl rounded-full  cursor-pointer mr-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link> */}





          <div className="border border-t-500 flex justify-center items-center">
            <h1>ลงประกาศขายเอง</h1>
          </div>


        {dataPosts.map((data, index) => (
          <Link key={index} to={data.link} >
            <div className="max-h-[120px] h-[120px] w-full my-2.5  rounded-xl " >
              <div className="w-full h-full cursor-pointer relative rounded-xl">
                <div className="relative rounded-xl">
                  <img
                    className="object-cover object-center w-full h-[120px] rounded-xl"
                    src={data.imgSrc}
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center rounded-xl">
                    <h1 className="text-2xl text-white text-center ml-6">{data.title}</h1>
                    <div className="ml-auto">
                      <MdKeyboardArrowRight className="text-white bg-black opacity-90 text-3xl rounded-full  cursor-pointer mr-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="w-full h-[200px]"></div>
    </div>
  );
}

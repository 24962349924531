import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, Autocomplete, InfoWindow } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import { BiSearch } from 'react-icons/bi';
import axios from 'axios';
import HousePostperty from "./assets/markerIcon/production/1HousePostperty.webp";
import CondoPostperty from "./assets/markerIcon/production/2CondoPostperty.webp";
import CommercialPostperty from "./assets/markerIcon/production/4CommercePostperty.webp";
import LandPostperty from "./assets/markerIcon/production/3LandPostperty.webp";
import TownHousePostperty from "./assets/markerIcon/production/5TownHouse_postperty.webp";
import OtherProperty from "./assets/markerIcon/production/6Other.webp";

const token = localStorage.getItem("token");
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '17px',
};
const center = {
  lat: 13.73537156171985,
  lng: 100.52735703327586,
};

export default function GetLatLngOnly({
  setAssetLocationLatitude,
  setAssetLocationLongitude,
  latLeadDataUserClickBeforeExitPage,
  lngLeadDataUserClickBeforeExitPage,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [markerPosition, setMarkerPosition] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({});
  const [LeadLocationDataBeforeUserExitPage, setLeadLocationDataBeforeUserExitPage] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [markerDataForLead, setMarkerDataForLead] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [dataDetailMarker, setDataDetailMarker] = useState(null);

  useEffect(() => {
    if (latLeadDataUserClickBeforeExitPage !== null && lngLeadDataUserClickBeforeExitPage !== null) {
      setLeadLocationDataBeforeUserExitPage({
        lat: parseFloat(latLeadDataUserClickBeforeExitPage),
        lng: parseFloat(lngLeadDataUserClickBeforeExitPage),
      });
    }
  }, [latLeadDataUserClickBeforeExitPage, lngLeadDataUserClickBeforeExitPage]);

  const mapRef = useRef();
  const autocompleteRef = useRef(null);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  const handleClick = (event) => {
    setSelectedMarker(null)
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    console.log('Latitude:', lat, 'Longitude:', lng);

    setMarkerPosition({ lat, lng });

    setAssetLocationLatitude((prevState) => ({
      ...prevState,
      asset_location_latitude: lat,
    }));

    setAssetLocationLongitude((prevState) => ({
      ...prevState,
      asset_location_longitude: lng,
    }));
  };

  const getMarker = (lat, lng) => {
    axios.post(`${process.env.REACT_APP_API}/fetchData/markerScan`, {
      centerLat: lat,
      centerLng: lng,
      radiusKm: 2
    }).then((res) => {
      console.log(res.data);
      setMarkerDataForLead(res.data.markers);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getDetailMarker = (id) => {
    console.log("Detail Marker", id);
    axios
      .get(`${process.env.REACT_APP_API}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setDataDetailMarker(res.data.asset);
        const markerData = markerDataForLead.find(marker => marker.id === id);
        console.log("Seledted Markererrrrrr" ,  dataDetailMarker)
        setSelectedMarker(markerData); // Ensure marker data including location is set
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const panToUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setCurrentLocation(pos);

          setAssetLocationLatitude((prevState) => ({
            ...prevState,
            asset_location_latitude: pos.lat,
          }));

          setAssetLocationLongitude((prevState) => ({
            ...prevState,
            asset_location_longitude: pos.lng,
          }));

          mapRef.current.panTo({ lat: pos.lat, lng: pos.lng });
          mapRef.current.setZoom(17);

          getMarker(pos.lat, pos.lng);
        },
        (positionError) => {
          console.log('Error getting position: ', positionError);
          Swal.fire('ไม่สำเร็จ', 'คุณได้ Block การเข้าถึงตำแหน่ง', 'error');
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      Swal.fire('ไม่สำเร็จ', 'คุณได้ Block การเข้าถึงตำแหน่ง', 'error');
    }
  };

  const handleSearch = () => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setBounds(mapRef.current.getBounds());
      const place = autocompleteRef.current.getPlace();

      if (place && place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        setMarkerPosition({ lat, lng });

        setAssetLocationLatitude((prevState) => ({
          ...prevState,
          asset_location_latitude: lat,
        }));

        setAssetLocationLongitude((prevState) => ({
          ...prevState,
          asset_location_longitude: lng,
        }));

        setCurrentLocation({ lat, lng });

        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }
    }
  };

  const handlePlaceSelect = () => {
    setSearchValue(autocompleteRef.current.getPlace().formatted_address);
  };

  const typeToIcon = {
    house: HousePostperty,
    condo: CondoPostperty,
    land: LandPostperty,
    commercial: CommercialPostperty,
    townhouse: TownHousePostperty,
    other: OtherProperty
  };

  return (
    <div className='relative w-full h-full'>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={{
          gestureHandling: 'greedy',
          disableDefaultUI: true,
          mapTypeId: 'hybrid',
          clickableIcons: false,
        }}
        zoom={16}
        center={center}
        onClick={handleClick}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}

        {markerDataForLead.length > 0 && markerDataForLead.map((data) => (
          <Marker
            key={data.id}
            options={{
              icon: {
                url: typeToIcon[data.type] || "",
                scaledSize: new window.google.maps.Size(35, 35),
              }
            }}
            position={data.location}
            onClick={() => getDetailMarker(data.id)}
          />
        ))}

        {selectedMarker && dataDetailMarker && (
          <InfoWindow
            position={selectedMarker.location}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className='w-full h-[200px]'>
              {/* Add your content here */}
              <img className='w-full h-full' src={dataDetailMarker.images_url.AssetImagesURL[0]}/>
            </div>
          </InfoWindow>
        )}

        {LeadLocationDataBeforeUserExitPage && <Marker position={LeadLocationDataBeforeUserExitPage} />}
        {currentLocation && (
          <Marker
            position={currentLocation}
            options={{
              icon: {
                url: `https://cdn-icons-png.flaticon.com/512/12108/12108316.png`,
                scaledSize: new window.google.maps.Size(40, 40),
              },
              animation: window.google.maps.Animation.BOUNCE,
              zIndex: 1,
              clickable: false,
            }}
          />
        )}
      </GoogleMap>
      <div className='w-max h-auto mx-auto absolute bottom-16 right-5 md:bottom-32 md:right-12 z-50 items-center justify-center'>
        <div
          className='h-auto w-full flex items-center justify-center cursor-pointer drop-shadow-xl'
          onClick={panToUserLocation}
        >
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center'>
            <img
              src='https://cdn-icons-png.flaticon.com/512/3138/3138801.png'
              className='w-8 h-8'
              alt='user-location'
            />
          </div>
        </div>
      </div>
      <div className='w-11/12 grid grid-cols-12 h-auto mx-auto absolute top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 md:w-9/12'>
        <div className='col-span-9 w-full'>
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={handlePlaceSelect}
          >
            <input
              type='text'
              placeholder='ค้นหาตำแหน่งใกล้เคียง'
              className='w-full py-2 px-4 rounded-full text-center outline-none focus:ring-2 focus:ring-blue-400'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Autocomplete>
        </div>
        <button
          className='col-span-3 flex justify-center items-center ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full'
          onClick={handleSearch}
        >
          <BiSearch />
        </button>
      </div>
    </div>
  );
}

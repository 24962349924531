import { Link } from "react-router-dom"
import AgentImage from "../../pages/index/img/postperty_agent.jpg"
export default function SelectPostAssets(){
    return (
    <main className="w-full h-full">
        <div className="relative w-full h-full ">
            <div className="w-10/12 h-auto flex justify-center itemsc-center mx-auto  mt-12 ">
                <span className="text-2xl">เลือกประเภทการโพสต์อสังหาฯ</span>
            </div>
            <div className="flex flex-col">
            <Link to={`/help/postperty`}>
            <div className="border  h-[200px] w-10/12 mx-auto mt-2.5 rounded-2xl bg-f3f3fb flex justify-center items-center md:w-6/12">
                <div className="relative w-11/12 bg-white rounded-xl  h-[90%]">
                    <div className="text-l relative w-full h-full rounded-xl ">
                    <img className="w-full h-full object-contain" src={AgentImage} />
                    <span className="absolute bottom-0 left-1/2 tranform -translate-x-1/2 -translate-y-1/2 bg-white  text-black  rounded-xl text-center text-xl w-11/12 mx-auto">ให้ PostPertyช่วยขาย {`>`}</span>
                    </div>
     
                </div>
            </div>
            </Link>
            <Link to={`/create/select`}>
            <div className="border  h-[200px] w-10/12 mx-auto mt-2.5 rounded-2xl bg-f3f3fb flex justify-center items-center md:w-6/12">
                <div className="relative w-11/12 bg-white rounded-xl   h-[90%]">
                <img className="w-full h-full object-cover" src="https://www.klungbaan.com/wp-content/uploads/2023/04/1.%E0%B8%9B%E0%B8%81%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C-%E0%B9%80%E0%B8%9F%E0%B8%AA.jpg" />
                    <span className="text-l absolute bottom-[-10px] rounded-full  w-10/12 h-8 flex justify-center items-center text-center left-1/2 tranform  bg-white -translate-x-1/2 -translate-y-1/2">ลงประกาศขายเอง</span>
                </div>
            </div>
            </Link>

            </div>

        </div>
    </main>
    )
}
import { useEffect } from "react"
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import localforage from "localforage";


const Logout = () => {
    // const askLogout = () => {
    //     Swal.fire({
    //       title: "ต้องการออกจากระบบ ?",
    //       text: "การที่คุณออกจากระบบจะไม่สามารถใช้งานฟังก์ชั่นบางอย่างได้!",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#34BFD9",
    //       cancelButtonColor: "#000",
    //       confirmButtonText: "ยืนยันการออกจากระบบ!",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         Swal.fire("สำเร็จ!", "ออกจากระบบเรียบร้อย", "success");
    //         Cookies.remove("jwt");
    //         window.location.reload();
    //       }
    //     });
    //   };
    useEffect( async() => {
        await Cookies.remove("jwt");
        const removeUploadData = async () => {
            try {
              await localforage.removeItem("uploadData");
              window.location.replace('/login');
            } catch (err) {
              console.error("Error removing upload data", err);
            }
          };

          removeUploadData()
        

    }, [])
    return (
        <div className="w-screen h-screen flex justify-center items-center">กำลังออกจากระบบ..</div>
    )
}

export default Logout   
import axios from "axios";
import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GetLatLngOnly from "../home/GoogleMapAPI/GetLatLngOnlyPure";
import Swal from "sweetalert2";
import ImagesUpload from "../../post/ImagesUpload";
import Cookies from "js-cookie";
import localforage from "localforage";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'; // Import Framer Motion

// Initialize localForage
const dbName = "DataLead";
localforage.config({
  name: dbName,
  storeName: "Data_Lead_Asset", // Should be alphanumeric, with underscores.
  description: "Store(lat,lng,phone_number,description,images)",
});

const EditLead = () => {
  // Validate Phone Number
  const [textCheckPhoneNumber, setTextCheckPhoneNumber] = useState(
    "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
  );

  const navigate = useNavigate();

  const checkPhoneNumber = (number) => {
    // Define a regex pattern for non-digits and minus sign
    const nonDigitAndMinusRegex = /[^0-9-]/g;

    // Remove non-digit characters and minus sign from the number string
    const cleanedNumber = number.replace(nonDigitAndMinusRegex, "");

    // Replace any minus sign with an empty string
    const finalNumber = cleanedNumber.replace(/-/g, "");

    if (finalNumber.length < 10) {
      console.log("กรุณากรอกให้ครบ 10 หลัก");
    } else if (finalNumber.length > 10) {
      setTextCheckPhoneNumber("กรุณาระบุเบอร์โทรแค่ 10 หลัก");
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API}/event/checkExistedlead/${finalNumber}`
        )
        .then((res) => {
          console.log(res.data.message);
          const message = res.data.message;
          setTextCheckPhoneNumber(message);
          // Additional logic based on the response if needed
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };



  const [upload, setUpload] = useState({
    lat: null,
    lng: null,
    phone_number: "",
    description: "",
    images: [],
    asset_type: "house",
    type_post: ["ขาย"],
    removeimg: [""],
  });

  const [typeOfLead, setTypeOfLead] = useState(null);
  const [leadId, setLeadId] = useState(null);

  const { lat, lng, phone_number, description, images, asset_type, type_post } =
    upload;

  const currentURL = window.location.href;
  const parts = currentURL.split("/");
  const lead_id = parts[parts.length - 1];

  const handleDeleteLead = async () => {
    try {
      const token = Cookies.get("jwt"); // Ensure JWT is available
      const assetId = leadId; // Assuming `leadId` is the asset ID you're using
      const response = await axios.post(
        `${process.env.REACT_APP_API}/event/deleteAsset`,
        { asset_id: assetId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Asset deleted successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
      // Optional: navigate or refresh the page after deletion
    } catch (error) {
      console.error("Error deleting the asset:", error);
      Swal.fire({
        icon: 'error',
        title: 'Failed to delete asset',
        text: error.response?.data?.message || 'Something went wrong',
      });
    }
  };

  function getInitDataEditLead() {
    const session_id = Cookies.get("jwt");

    axios
      .post(
        `${process.env.REACT_APP_API}/fetchdata/get-imperfect-lead`,
        {
          lead_id: lead_id,
        },
        {
          headers: {
            Authorization: `Bearer ${session_id}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        const data = res.data;

        setTypeOfLead(data.type);
        setLeadId(data.id);
        setLengthOfDetailPost(res.data.description.length);
        const imageData = res.data.images_url.AssetImagesURL;
        setUpload({
          ...upload,
          phone_number: res.data.contact.PhoneNumber,
          description: res.data.description,
          asset_type: res.data.type,
          type_post: res.data.type_post.Type,
          lat: res.data.location.coordinates.lat,
          lng: res.data.location.coordinates.lng,
          images: imageData,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    console.log("this is iamge", upload);
    // axios.post(`${process.env.REACT_APP_API}/fetchdata/get-imperfect-lead`, {
    //     lead_id: lead_id
    // },{
    //     headers:{
    //         Authorization : `Bearer ${localStorage.getItem("token")}`
    //     }
    // })
    // .then((res) => {
    //     console.log(res.data)
    // })
    // .catch(error => {
    //     console.log(error)
    // });
  }

  useEffect(() => {
    getInitDataEditLead();
  }, []);

  const [isValidLocation, setIsValidLocation] = useState(false);

  useEffect(() => {
    if (phone_number.length < 10) {
      setTextCheckPhoneNumber(`กรุณากรอกเบอร์โทรศัพท์ 10 หลัก`);
    }
  }, [phone_number]);

  // useEffect(() => {
  //     checkPhoneNumber(upload.phone_number)
  // }, [upload])

  const inputValue = (name) => (e) => {
    setUpload({ ...upload, [name]: e.target.value });
  };

  // Google Map
  const [assetLocationLatitude, setAssetLocationLatitude] = useState(null);
  const [assetLocationLongitude, setAssetLocationLongitude] = useState(null);

  useEffect(() => {
    setUpload((prevUpload) => ({
      ...prevUpload,
      lat:
        assetLocationLatitude !== null
          ? assetLocationLatitude.asset_location_latitude
          : "",
      lng:
        assetLocationLongitude !== null
          ? assetLocationLongitude.asset_location_longitude
          : "",
    }));
  }, [assetLocationLatitude, assetLocationLongitude]);

  // Text Report
  const [lengthOfDetailPost, setLengthOfDetailPost] = useState(0);
  const handleDetailPostLimit = (e) => {
    const lengthOfDetail = e.target.value.length;
    const textOfDetail = e.target.value;
    const maxLimitPostLength = 999;

    if (lengthOfDetail <= maxLimitPostLength) {
      setLengthOfDetailPost(lengthOfDetail);
      setUpload((prevUpload) => ({
        ...prevUpload,
        description: textOfDetail,
      }));
    } else {
      Swal.fire({
        icon: "error",
        title: "เกินจำนวนตัวอักษรที่กำหนด",
        text: "คุณสามารถกรอกข้อความได้สูงสุด 1000 ตัวอักษร",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  // Scroll to Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Image upload
  const [pageNumber, setPageNumber] = useState(1);
  const openImageUploads = () => {
    setPageNumber(1.5);
    scrollToTop();
  };

  const handleImageChange = (images) => {
    setUpload((prevUpload) => ({
      ...prevUpload,
      images,
    }));
  };

  // Upload data to dashboard admin
  const [isUploading, setIsUploading] = useState(false);
  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };
  const handleUpdateLeadAsset = () => {
    setIsUploading(true);
    let token = getToken();
    axios
      .post(
        `${process.env.REACT_APP_API}/update/editlead`,
        {
          lead_id: lead_id,
          asset_type: upload.asset_type,
          type_post: upload.type_post,
          description: upload.description,
          phone_number: upload.phone_number,
          lat: upload.lat,
          lng: upload.lng,
          images: upload.images,
          removeimg: upload.removeimg,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(async (res) => {
        setIsUploading(false);
        await Swal.fire(
          "ส่งข้อมูลสำเร็จ",
          "*ในกรณีที่คุณอัพเดทรูปภาพ รูปภาพอัพเดทหน้าทรัพย์ภายในระยะเวลา 30วินาที - 1 นาที",
          "success"
        );
        window.location.replace(`/profile/mylead`);
      })
      .catch((err) => {
        setIsUploading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err.response.data.Error}`,
        });
      });
  };

  function handleCheckboxValidLocation() {
    setIsValidLocation(!isValidLocation);
    if (isValidLocation && lat === null && lng === null) {
      setUpload({ ...upload, lat: null, lng: null });
    }
  }

  const listTypeAssets = [
    { label: "บ้านเดี่ยว", key: "house", Col_Span: 4},
    { label: "คอนโด", key: "condo", Col_Span: 4 },
    { label: "ทาวน์เฮ้าส์", key: "townhouse", Col_Span: 4 },
    { label: "ที่ดิน", key: "land", Col_Span: 4 },
    { label: "อาคารพาณิชย์", key: "commercial", Col_Span: 4 },
    { label: "โกดัง/โรงงาน", key: "other", Col_Span: 4 },
  ];

  function handleWarningUser(typeOfLead) {
    Swal.fire({
      title: "คำเตือน?",
      text: "การแก้ไข leadอย่างละเอียดเมื่อแก้ไขเสร็จสิ้นจะทำให้ Lead ของคุณถูกย้ายไปที่ประกาศของฉัน!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34BFD9",
      cancelButtonColor: "#000",
      confirmButtonText: "ดำเนินการต่อ",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(
          `/edit${
            typeOfLead === "house"
              ? "/house"
              : typeOfLead === "condo"
              ? "/condo"
              : typeOfLead === "land"
              ? "/land"
              : typeOfLead === "commercial"
              ? "/commercial"
              : typeOfLead === "townhouse"
              ? "/townhouse"
              : ""
          }/${leadId}`
        );
        // text: "การแก้ไข leadอย่างละเอียดเมื่อแก้ไขเสร็จสิ้นจะทำให้ Lead ของคุณถูกย้ายไปที่ประกาศของฉัน",
      }
    });
  }

  return (
    <div className="w-11/12 overflow-y-auto  h-auto  mx-auto md:w-8/12 mt-3">
      {/* {pageNumber === 1.5 ? (
        <ImagesUpload
          images={images}
          upload={upload}
          setUpload={setUpload}
          setPageNumber={setPageNumber}
        />
      ) : null} */}
      <div className={pageNumber === 1 ? "w-full h-auto border " : "hidden"}>
        <p className="mt-12 ml-1 text-xl">แจ้งทรัพย์ให้ PostPerty (Lead)</p>

        <div className="w-full h-auto  p-2 rounded-xl my-2.5 border ">
          <p>
            ตรวจสอบ สถานะเจ้าของทรัพย์ <br />
            ด้วยเบอร์โทรที่ติดประกาศ
          </p>
          <form className="flex flex-col justify-center items-center">
            <div className=" flex flex-col justify-center items-center w-full h-full">
              <input
                // value={check_phoneNumber}
                type="number"
                defaultValue={upload.phone_number}
                placeholder="เบอร์โทรศัพท์"
                onChange={(e) => {
                  inputValue("phone_number")(e);
                  checkPhoneNumber(e.target.value);
                }}
                className="borer w-10/12 h-12 rounded-full ounded mt-3 text-center bg-half-light-2 outline-0 md:w-3/4 text-base placeholder-gray-300"
              ></input>
              <p
                className={
                  textCheckPhoneNumber ===
                  "เบอร์โทรนี้ไม่ได้เป็นสมาชิก สามารถส่งข้อมูลเข้ามาได้"
                    ? "text-green-500  mt-3.5 w-3/4 text-center"
                    : textCheckPhoneNumber === "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
                    ? "text-black text-base opacity-40  text-center   mt-3.5"
                    : "text-red-500 text-center    mt-3.5  w-3/4"
                }
              >
                {textCheckPhoneNumber}
              </p>
              {/* <div className="flex justify-end w-3/4  text-postperty">

                        </div> */}
            </div>
          </form>
        </div>

        <div className="w-full h-max flex flex-col  justify-center items-center border rounded-xl">
          <div className="w-full p-2 ">
            <ImagesUpload
              images={upload.images}
              upload={upload}
              setUpload={setUpload}
              setPageNumber={setPageNumber}
            />
          </div>
        </div>

        <div className="border p-2 rounded-full  w-max mt-2.5  flex">
          <label>
            <input
              type="checkbox"
              checked={isValidLocation}
              onChange={handleCheckboxValidLocation}
            />
            <span className="ml-2.5">ไม่มีตำแหน่ง / ไม่ทราบตำแหน่ง</span>
          </label>
          {/* <p>{isValidLocation ? "Checked" : "Unchecked"}</p> */}
        </div>

        <div
          className={
            isValidLocation
              ? "hidden"
              : "w-full mt-2.5 p-6  h-[500px] md:h-[700px] mb-2.5 md:col-span-6 md:mb-0 relative"
          }
        >
          <div className="w-8 h-8 absolute right-[-10px] top-32 md:hidden">
            <MdKeyboardArrowUp className="w-8 h-8" />
          </div>
          <div className="w-8 h-8 absolute right-[-10px] top-[350px] md:hidden">
            <MdKeyboardArrowDown className="w-8 h-8" />
          </div>
          <GetLatLngOnly
            setAssetLocationLatitude={setAssetLocationLatitude}
            setAssetLocationLongitude={setAssetLocationLongitude}
            latLeadDataUserClickBeforeExitPage={lat}
            lngLeadDataUserClickBeforeExitPage={lng}
          />
        </div>

        <div className="w-full h-36 grid grid-cols-12 gap-x-2.5 gap-y-1.5 p-2 border rounded-xl mt-2.5">
          <p className="col-span-12">ประเภทอสังหาฯ</p>
          {listTypeAssets.map((data, idx) => {
            const colSpanClass = `col-span-${data.Col_Span}`;
            const isSelected = data.key === asset_type;
            const backgroundClass = isSelected
              ? "bg-postperty-linear-light text-white"
              : "bg-half-light-f5 text-black";

            return (
              <div
                key={idx}
                className={`rounded-full w-full h-12 flex justify-center items-center cursor-pointer ${colSpanClass} ${backgroundClass}`}
                onClick={() => setUpload({ ...upload, asset_type: data.key })}
              >
                {data.label}
              </div>
            );
          })}
        </div>

        <div className="w-full h-24 grid grid-cols-12 gap-x-2.5 gap-y-1.5 p-2 border rounded-xl mt-2.5">
          <p className="col-span-12">ประเภทการขาย</p>
          {[
            { label: "ขาย", key: "sell", colSpan: 4 },
            { label: "เช่า", key: "rent", colSpan: 4 },
            { label: "เซ้ง", key: "sell_out", colSpan: 4 },
          ].map((data, idx) => {
            const colSpanClass = `col-span-${data.colSpan}`;
            const isSelected = type_post.includes(data.label);
            const backgroundClass = isSelected
              ? "bg-postperty-linear-light text-white"
              : "bg-half-light-f5 text-black";

            return (
              <div
                key={idx}
                className={`rounded-full w-full h-12 flex justify-center items-center cursor-pointer ${colSpanClass} ${backgroundClass}`}
                onClick={() => {
                  if (isSelected) {
                    setUpload({
                      ...upload,
                      type_post: type_post.filter(
                        (type) => type !== data.label
                      ),
                    });
                  } else {
                    setUpload({
                      ...upload,
                      type_post: [...type_post, data.label],
                    });
                  }
                }}
              >
                {data.label}
              </div>
            );
          })}
        </div>

        <div className="w-full h-auto border   ">
          {/*  Detail Post  */}
          <div className="w-full h-96  p-2    rounded-full mt-2.5 border  ">
            <h1 className="text-l my-2.5">
              รายละเอียด <span>{lengthOfDetailPost}/999</span>
            </h1>
            <textarea
              defaultValue={description}
              onChange={(e) => {
                handleDetailPostLimit(e);
              }}
              className="w-full h-80 rounded-xl outline-0 bg-half-light-f5 p-4"
            />
          </div>
          {/* Detail Post  */}
        </div>

        <button
          className="bg-red-700 w-full rounded-full h-12 text-white  mt-2.5 "
          onClick={handleUpdateLeadAsset}
        >
          {isUploading ? (
            <span>
              กำลังอัพโหลด... <span className="inline-block">⌛</span>
            </span>
          ) : (
            <span>อัพเดทข้อมูล Lead</span>
          )}
        </button>

        <div onClick={() => handleWarningUser(typeOfLead)}>
          <div className="bg-postperty-linear-light w-full rounded-full h-12 text-white  mt-2.5 flex justify-center items-center">
            แก้ไขอย่างละเอียด
          </div>
        </div>

        {/* New button for deleting the asset */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleDeleteLead}
          className="bg-red-600 w-full rounded-full h-12 text-white mt-4 flex justify-center items-center"
        >
          ลบประกาศ
        </motion.button>
      </div>

      <div className="w-full h-[200px]"></div>
    </div>
  );
};

export default EditLead;

import { Link } from "react-router-dom";
const TeamData = [
  {
    img: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/6.png",
    title: "พนักงาน บริการลูกค้า",
    loc: "(ประจำภูมิภาค ทุกจังหวัด)",
    description: `หน้าที่ความรับผิดชอบ
  เข้าเก็บข้อมูล ถ่ายรูปบ้าน รับเอกสาร จากเจ้าของบ้าน
      - พาลูกค้าไปทำธุรกรรม ที่ สนง.ที่ดิน 
      - ช่วยเหลือให้คำแนะนำ ลูกค้า ยื่นกู้ซื้อบ้านกับ ธนาคาร
      
      คุณสมบัติ
      - มีความซื่อสัตย์ สุจริต
      - มี service mind รักงานบริการ อัธยาศัยดี ยิ้มแย้ม แจ่มใส
      - ขับรถได้ มีใบอนุญาติขับขี่
      - พร้อมเรียนรู้ เทคโนโลยีที่ช่วยในการทำงาน เช่นใช้แอพ ถ่ายรูป 3D
      - ไม่จำกัดเพศ อายุไม่เกิน 45 ปี
      - วุฒิการศึกษา ม.6ขึ้นไป ไม่จำกัดสาขา
      
      ผลตอบแทน 
      - เงินเดือน 15,000-20,000 (ตามความสามารถและพื้นที่ รับผิดชอบ)
      - ค่าCom ปิดการขาย หลังละ 1,000-3,000
      - มีรถ ประจำตำแหน่งให้ 1คัน พร้อมน้ำมัน
      - เครื่องแบบ พนักงาน
      - ประกันสังคม
      - ประกันชีวิต
      - ท่องเที่ยวประจำปี
      - โบนัสตามผลประกอบการ`,
  },

  {
    img: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/3.png",
    title: "พนักงาน Call center ",
    loc: "(Work from anyware)",
    description: `หน้าที่ความรับผิดชอบ
        - รับสายลูกค้า ตอบปัญหาต่างๆ แนะนำบริการของบริษัท ประสานงาน แก้ปัญหาผ่านระบบ 
        
        คุณสมบัติ
        - รักงานบริการ 
        - มีไหวพริบ แก้ไขปัญหาเฉพาะหน้าได้ดี 
        - ใช้ภาษาสุภาพ พูดจาเพราะ น้ำเสียงน่าฟัง
        - พร้อมเรียนรู้ เสมอ
        - วุฒิการศึกษา ม.6ขึ้นไป ไม่จำกัดสาขา 
        - ไม่จำกัดเพศ อายุไม่เกิน 45 ปี
        
        ผลตอบแทน 
        - เงินเดือน 15,000-20,000 (ตามความสามารถและหน้าที่ รับผิดชอบ)
        - ค่าCom ปิดการขาย หลังละ 500-3,000
        - มีโทรศัพท์ พร้อมAPP ระบบCallcenter ให้ใช้งาน เลือกสถานที่ทำงานได้(หลังจากผ่านทดลองงาน)
        - ประกันสังคม
        - ประกันชีวิต
        - ท่องเที่ยวประจำปี
        - โบนัสตามผลประกอบการ
`,
  },

  {
    img: "https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official/employees/4.png",
    title: "พนักงาน Admin",
    loc: "",
    description: `Admin ดูแล ช่องทางสื่อสาร online 
        หน้าที่รับผิดชอบ
        - Monitor ข้อมูลบนโลก online เกี่ยวกับบริษัทฯ ด้วยเครื่องมือ social listening
        - รับเรื่องร้องเรียน ข้อแนะนำต่างๆ จากลูกค้า และที่ส่งต่อมาจาก ทีม Call center
        - ดูแล update ข้อมูลข่าวสาร บนสื่อ online ของ บริษัทฯ
        - ติดตาม ข่าวสาร ที่เกี่ยวข้อง กับวงการ อสังหาฯ วิเคราะห์ และนำมา ใช้ให้เกิดประโยชน์สูงสุด กับ บริษัท
        - ทำงานที่บ้านได้ (เน้นผลลัพธ์ ของงาน)
        - เลือกสลับ วันเวลาหยุดได้ ตามความจำเป็น
        
        คุณสมบัติ
        - มีความซื่อสัตย์ และมีความรับผิดชอบสูง
        - สนใจ โลก online ใช้เครื่องมือ social listening เป็น
        - ไม่จำกัด วุฒิ อายุ เพศ (เน้นความสามารถ ผลงาน และทัศนคติที่ดี)
        - กระตือรือร้น พร้อมเรียนรู้เสมอ
        - ยินดีรับ ผู้พิการ ทางร่างกาย ที่สามารถ ทำหน้าที่นี้ได้
        

        ผลตอบแทน
        - เงินเดือน 15,000-20,000 (ตามความสามารถและหน้าที่ รับผิดชอบ)
        - มีโทรศัพท์, computer notebook ให้ใช้งาน ทำงานที่บ้านได้
        - ประกันสังคม
        - ประกันชีวิต
        - ท่องเที่ยวประจำปี
        - โบนัสตามผลประกอบการ`,
  },
];

export default function JoinTeam() {
  return (
    <div className="grid grid-cols-12 gap-x-2.5  max-w-6xl mx-auto ">
      {TeamData.map((data) => {
        return (
          <div className="relative col-span-12   bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 md:col-span-4">
            <a href="#">
              <div className="h-[300px]">
                <img
                  className="rounded-t-lg  h-full w-full object-cover"
                  src={data.img}
                  alt=""
                />
              </div>
            </a>
            <div className="p-5">
              <a href="#">
                <h5 className=" text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {data.title}
                </h5>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {" "}
                  {data.loc}
                </h5>
              </a>
              <p className="tion: `mb-3 font-normal text-gray-700 dark:text-gray-400 whitespace-pre-line">
                {data.description}
              </p>
            </div>

            <div className="mt-12"></div>
            <Link to={`https://forms.gle/rhjJJ7X5iqE7aLrF8`} target="_blank">
              <div className="absolute bottom-4 left-1/2 tranform -translate-x-1/2 rounded shadow-xl h-12 w-[200px] bg-postperty-linear-light mx-auto text-white flex justify-center items-center text-2xl">
                สมัคร
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

import axios from "axios";
import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GetLatLngOnly from "../../../pages/home/GoogleMapAPI/GetLatLngOnlyPure";
import Swal from "sweetalert2";
import ImagesUpload from "../../ImagesUpload";
import Cookies from "js-cookie";

import localforage from "localforage";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
// Initialize localForage
const dbName = "DataHelpPostperty";
localforage.config({
    name: dbName,
    storeName: "DataHelpPostperty", // Should be alphanumeric, with underscores.
    description: "Store_Data",
});

const HelpSellPostperty = () => {
    // Validate Phone Number
    const [textCheckPhoneNumber, setTextCheckPhoneNumber] = useState(
        "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
    );

    const checkPhoneNumber = (number) => {
        if (number.length < 10) {
            console.log("กรุณากรอกให้ครบ 10 หลัก");
        }

        if (number.length > 10) {
            setTextCheckPhoneNumber("กรุณาระบุเบอร์โทรแค่ 10หลัก");
        }

        if (number !== "" && number.length === 10) {
            axios
                .get(`${process.env.REACT_APP_API}/event/checkExistedlead/${number}`)
                .then((res) => {
                    console.log(res.data.message);
                    const message = res.data.message;
                    // setTextCheckPhoneNumber(message);
                    // if (res.data.Status === true) {
                    //     setTextCheckPhoneNumber("เบอร์นี้เป็นสมาชิกแล้ว ส่งข้อมูลได้ แต่อาจไม่ได้รับค่า นายหน้า");
                    // } else {
                    //     setTextCheckPhoneNumber("เบอร์นี้ยังไม่ได้เป็นสมาชิก");
                    // }
                })
                .catch((err) => { });
        }
    };

    const [upload, setUpload] = useState({
        phone_number: "",
        description: "",
        images: [],
    });
    const {  description, images } = upload;



    const [isValidLocation, setIsValidLocation] = useState(false);

    // Store upload data in IndexedDB when it changes
    useEffect(() => {
        const storeUploadData = async () => {
            try {
                await localforage.setItem("HelpSellPostPerty", upload);
            } catch (err) {
                console.error("Error storing upload data", err);
            }
        };
        storeUploadData();
    }, [upload]);

    // Retrieve upload data from IndexedDB on first render
    useEffect(() => {
        const retrieveUploadData = async () => {
            try {
                const savedUploadData = await localforage.getItem("HelpSellPostPerty");
                if (savedUploadData) {
                    setUpload(savedUploadData);
                }
            } catch (err) {
                console.error("Error retrieving upload data", err);
            }
        };
        retrieveUploadData();
    }, []);

    const handleRemoveDataInIndexedDB = () => {
        localforage
            .dropInstance({
                name: dbName,
            })
            .then(() => {
                console.log(`Successfully removed database: ${dbName}`);
            })
            .catch((err) => {
                console.error(`Error removing database: ${dbName}`, err);
            });
    };


    // useEffect(() => {
    //     if (phone_number.length < 10) {
    //         setTextCheckPhoneNumber(`กรุณากรอกเบอร์โทรศัพท์ 10 หลัก`);
    //     }
    // }, [phone_number]);

    const inputValue = (name) => (e) => {
        setUpload({ ...upload, [name]: e.target.value });
    };

    // Google Map
    const [assetLocationLatitude, setAssetLocationLatitude] = useState(null);
    const [assetLocationLongitude, setAssetLocationLongitude] = useState(null);

    useEffect(() => {
        setUpload((prevUpload) => ({
            ...prevUpload,
            lat:
                assetLocationLatitude !== null
                    ? assetLocationLatitude.asset_location_latitude
                    : "",
            lng:
                assetLocationLongitude !== null
                    ? assetLocationLongitude.asset_location_longitude
                    : "",
        }));
    }, [assetLocationLatitude, assetLocationLongitude]);

    // Text Report
    const [lengthOfDetailPost, setLengthOfDetailPost] = useState(0);
    const handleDetailPostLimit = (e) => {
        const lengthOfDetail = e.target.value.length;
        const textOfDetail = e.target.value;
        const maxLimitPostLength = 999;

        if (lengthOfDetail <= maxLimitPostLength) {
            setLengthOfDetailPost(lengthOfDetail);
            setUpload((prevUpload) => ({
                ...prevUpload,
                description: textOfDetail,
            }));
        } else {
            Swal.fire({
                icon: "error",
                title: "เกินจำนวนตัวอักษรที่กำหนด",
                text: "คุณสามารถกรอกข้อความได้สูงสุด 1000 ตัวอักษร",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };

    // Scroll to Top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Image upload
    const [pageNumber, setPageNumber] = useState(1);
    const openImageUploads = () => {
        setPageNumber(1.5);
        scrollToTop();
    };

    const handleImageChange = (images) => {
        setUpload((prevUpload) => ({
            ...prevUpload,
            images,
        }));
    };

    // Upload data to dashboard admin
    const [isUploading, setIsUploading] = useState(false);
    const getToken = () => {
        const session_id = Cookies.get("jwt");
        return session_id;
    };
    const handleHelpSellPostpertyendToDashboardAdmin = () => {
        setIsUploading(true);
        let token = getToken();
        axios
            .post(
                `${process.env.REACT_APP_API}/asset/post/postperty-handle-asset`,
                {

                    phone_number: upload.phone_number,
                    description: upload.description,
                    images: upload.images,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data", //Important For Recive image[] ต้องมี
                    },
                }
            )
            .then(async (res) => {
                handleRemoveDataInIndexedDB();
                await Swal.fire("ส่งข้อมูลสำเร็จ", "", "success");
                window.location.replace(`/home`);
                setIsUploading(false);
            })
            .catch((err) => {
                setIsUploading(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${err.response.data.Error}`,
                });
            });
    };



    const listTypeAssets = [
        { label: 'บ้านเดี่ยว', key: 'house', Col_Span: 6 },
        { label: 'คอนโด', key: 'condo', Col_Span: 6 },
        { label: 'ทาวน์เฮ้าส์', key: 'townhouse', Col_Span: 4 },
        { label: 'ที่ดิน', key: 'land', Col_Span: 4 },
        { label: 'อาคารพาณิชย์', key: 'commercial', Col_Span: 4 },

    ]

    return (
        <div className="w-11/12 overflow-y-auto  h-auto  mx-auto md:w-8/12 mt-3">
            <div className={pageNumber === 1 ? "w-full h-auto border " : "hidden"}>
                <div className="w-full h-22 flex flex-col justify-center items-center mt-4">
                    <div className="flex justify-center items-center  mx-auto border  rounded-xl p-2.5">
                        <p className="ml-1 text-xl">ติดต่อ PostPerty</p>
                        <img src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/official.png" className="w-8 h-8" />
                    </div>


                    <div className="w-full grid grid-cols-12 gap-x-2.5 mt-2.5 p-4">
                        <Link to={"https://line.me/ti/p/%40postperty"} target="_blank" className="col-span-6 h-12 flex justify-center items-center rounded-3xl bg-green-400 text-white">
                            <div>Line OA</div>
                        </Link>
                        <a href="tel:02-123-3632" className="col-span-6 h-12 flex justify-center rounded-2xl items-center rounded-full border bg-postperty-linear-light text-white">
                            <div>เบอร์โทรศัพท์</div>
                        </a>
                    </div>
                </div>

                <div className="w-full h-auto  p-2 rounded-xl my-2.5 border ">
                    {/* <p>
            ตรวจสอบ สถานะเจ้าของทรัพย์ <br />
            ด้วยเบอร์โทรที่ติดประกาศ
          </p> */}
                    <form className="flex flex-col justify-center items-center">
                        <div className=" flex flex-col justify-center items-center w-full h-full">
                            <input
                                // value={check_phoneNumber}
                                type="text"
                                defaultValue={upload.phone_number}
                                placeholder="เบอร์โทรศัพท์"
                                onChange={(e) => {
                                    inputValue("phone_number")(e);
                                    checkPhoneNumber(e.target.value);
                                }}
                                className="borer w-10/12 h-12 rounded-full ounded mt-3 text-center bg-half-light-2 outline-0 md:w-3/4 text-base placeholder-gray-300"
                            ></input>
                            <p
                                className={
                                    textCheckPhoneNumber ===
                                        "เบอร์โทรนี้ไม่ได้เป็นสมาชิก สามารถส่งข้อมูลเข้ามาได้"
                                        ? "text-green-500  mt-3.5 w-3/4 text-center"
                                        : textCheckPhoneNumber === "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
                                            ? "text-black text-base opacity-40  text-center   mt-3.5"
                                            : "text-red-500 text-center    mt-3.5  w-3/4"
                                }
                            >
                                {textCheckPhoneNumber}
                            </p>
                        </div>
                    </form>
                </div>

                <div className="w-full h-max flex flex-col  justify-center items-center border rounded-xl">
                    <div className="w-full p-2 ">
                        <ImagesUpload
                            images={images}
                            upload={upload}
                            setUpload={setUpload}
                            setPageNumber={setPageNumber}
                        />
                    </div>
                </div>
                
                <div className="w-full h-auto border   ">
                    {/*  Detail Post  */}
                    <div className="w-full h-96  p-2    rounded-full mt-2.5 border  ">
                        <h1 className="text-l my-2.5">
                            รายละเอียด <span>{lengthOfDetailPost}/999</span>
                        </h1>
                        <textarea
                            defaultValue={description}
                            onChange={(e) => {
                                // inputValue("detail_post");
                                handleDetailPostLimit(e);
                            }}
                            className="w-full h-80 rounded-xl outline-0 bg-half-light-f5 p-4"
                        />
                    </div>
                    {/* Detail Post  */}
                </div>

                <div className="w-full grid grid-cols-12 gap-x-2.5  mt-2.5 p-4">
                      
                        <a href="tel:02-123-3632" className="col-span-12  h-12 flex justify-center rounded-full items-center  border">
                            <div>ติดต่อเจ้าหน้าที่ทันที</div>
                        </a>
                    </div>

                
                <button
                    className="bg-red-700 w-full rounded-full h-12 text-white  mt-2.5 "
                    onClick={handleHelpSellPostpertyendToDashboardAdmin}
                >
                    {isUploading ? (
                        <span>
                            กำลังอัพโหลด...{" "}
                            <span className="inline-block">⌛</span>
                        </span>
                    ) : (
                        <span>ส่งข้อมูลให้ PostPerty</span>
                    )}
                </button>
            </div>

            <div className="w-full h-[200px]"></div>
        </div>
    );
};

export default HelpSellPostperty;

import { Link, useLocation } from "react-router-dom";
import PreformattedText from "./PreformattedText";
import { useEffect, useState, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { AiFillHeart } from "react-icons/ai";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import FormatPrice from "../home/GridLayoutDisplay/FormatPrice";
import { formatPhoneNumber } from "../../lib/LibFunc";
import { BiMapAlt } from "react-icons/bi";
//Context Menu
import { MyContextMapAndGrid } from "../../../App";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import BeatLoader from "react-spinners/BeatLoader";

const cookie = Cookies.get("jwt");
const token = `${cookie}`;

const getToken = () => {
  const session_id = Cookies.get("jwt");
  return session_id;
};

const ViewProduct = () => {
  // Context GoogleMap And Grid Layout
  const { SetStatusCloseMap, setStatusHalfGridMap } =
    useContext(MyContextMapAndGrid);

  const [idAsset, setIdAsset] = useState("");
  const [idAssetStatus, setIdAssetStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ID_invite, setID_invite] = useState(null);
  const [LinkShare, setLinkShare] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  function getProductId(urlString) {
    const url = new URL(urlString);
    const parts = url.pathname.split("/");
    // Assuming that the product id is always the last part in the pathname
    // console.log(parts)
    return parts[parts.length - 1];
  }

  const changeStatusMap = () => {
    SetStatusCloseMap(false);
    setStatusHalfGridMap(true);
  };

  function changeUIDToUname(UID) {
    axios
      .post(
        `${process.env.REACT_APP_API}/event/convert-uname-uid`,
        {
          target_uid: UID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        console.log(res.data);
        const UserNameID = res.data.target_uname;
        await setID_invite(UserNameID);
        getIdFromUrl(UserNameID); // Pass UserNameID to getIdFromUrl
        localStorage.setItem("@HAVE_INVITER_URL", true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getIdFromUrl = (ID_invite) => {
    // Add ID_invite parameter to getIdFromUrl
    const IDLocal = localStorage.getItem("@ID_INVITER");
    if (IDLocal === null || IDLocal !== ID_invite) {
      if (ID_invite !== null) {
        console.log("SetID...");
        localStorage.setItem("@ID_INVITER", ID_invite);
      } else {
        if (ID_invite !== null) {
          localStorage.setItem("@ID_INVITER", ID_invite);
        }
      }
    }
  };

  useEffect(() => {
    const UID_Invite = searchParams.get("sharer");
    changeUIDToUname(UID_Invite);
    if (UID_Invite) {
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let fullURL = window.location.href;
    // Log the full URL to the console
    const idAsset = getProductId(fullURL);
    console.log(idAsset);
    setIdAsset(idAsset);
    setIdAssetStatus(true);
  }, []);

  const [dataProduct, setDataProduct] = useState([]);

  console.log(dataProduct);
  const [ownerData, setOwnerData] = useState("");
  const [allowShareAsset, setAllowShareAsset] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  console.log(`allow share`, allowShareAsset);
  let fullURL = window.location.href;
  const sharer = getSharerFromURL(fullURL);
  console.log(`This is sharer : `, sharer);
  useEffect(() => {
    if (idAsset) {
      if (sharer !== null && idAsset) {
        axios
          .get(
            `${process.env.REACT_APP_API}/product/${idAsset}?sharer=${sharer}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("This is debug code view", res.data);
            setIsDisplay(res.data.data.display);
            setDataProduct(res.data.asset);

            setOwnerData(res.data.ownerData);
            setAllowShareAsset(res.data.Share);
            setIsLoadingData(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .get(`${process.env.REACT_APP_API}/product/${idAsset}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setIsDisplay(res.data.data.display);
            setDataProduct(res.data.asset);
            setOwnerData(res.data.ownerData);
            setAllowShareAsset(res.data.Share);
            setIsLoadingData(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [idAssetStatus]);

  const [imagesDisplayProduct, setImagesDispalyProduct] = useState([]);
  if (dataProduct != "") {
    const images = dataProduct.images_url.AssetImagesURL;
    console.log(images);
    // setImagesDispalyProduct(images)
  }

  // Slide Funtion
  const [currentIndexSlide, setCurrentIndexSlide] = useState(0);
  const [startX, setStartX] = useState(0); // Initial touch position
  const slider = useRef(null); // Ref for the slider div

  // Rest of your component's state

  const prevSlide = () => {
    const isFirstSlide = currentIndexSlide === 0;
    const newIndex = isFirstSlide
      ? dataProduct.images_url.AssetImagesURL.length - 1
      : currentIndexSlide - 1;
    setCurrentIndexSlide(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide =
      currentIndexSlide === dataProduct.images_url.AssetImagesURL.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndexSlide + 1;
    setCurrentIndexSlide(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndexSlide(slideIndex);
  };

  const touchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const touchMove = (e) => {
    const touchDown = e.touches[0].clientX;
    // Compare the start and end positions
    if (touchDown - startX > 70) {
      // 70 is the minimum swipe length, you can adjust it to your needs
      // Swipe Right
      prevSlide();
    } else if (touchDown - startX < -70) {
      // Swipe Left
      nextSlide();
    }
  };

  useEffect(() => {
    // Check for touch capabilities
    if (!isLoadingData) {
      if ("ontouchstart" in window) {
        // If touch capabilities exist, then it's likely a mobile device.
        // Therefore, we simply return and don't attach the event listeners.
        return;
      }

      const sliderElement = slider.current;

      sliderElement.addEventListener("touchstart", touchStart);
      sliderElement.addEventListener("touchmove", touchMove);

      return () => {
        // Cleanup the event listener on component unmount
        sliderElement.removeEventListener("touchstart", touchStart);
        sliderElement.removeEventListener("touchmove", touchMove);
      };
    }
  }, [startX]); // Update the event listeners whenever startX changes

  useEffect(() => {
    // Attach keydown event listener to the window

    if (!isLoadingData) {
      window.addEventListener("keydown", handleKeyDown);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  });

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      prevSlide();
    } else if (event.key === "ArrowRight") {
      nextSlide();
    }
  };

  // Share Real Estate

  function getSharerFromURL(urlString) {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);
    console.log(params);
    // Get the 'sharer' query parameter
    return params.get("sharer");
  }
  const copyLinkShare = async () => {
    await navigator.clipboard.writeText(LinkShare);
    Swal.fire({
      icon: "success",
      title: "Link Copied",
      text: "Link Copied",
    });
  };

  // useEffect(() => {
  //   handleShareAssetMLM()
  // }, [])

  const handleShareAssetMLM = () => {
    let fullURL = window.location.href;
    const sharer = getSharerFromURL(fullURL); // Assuming this function is defined elsewhere
    const urlSegments = fullURL.split("/");
    const assetID = urlSegments[urlSegments.length - 1];
    console.log("This is the asset ID:", assetID);

    console.log(sharer);
    if (sharer === null) {
      //Secret key กวนตีน Hacker ไม่มีผลอะไรกับ Frontend และ Backend
      axios
        .post(
          `${process.env.REACT_APP_API}/mlm/share?aid=${assetID}`,
          { SecretKey: "HelloHacker" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          const shareURL = res.data.shareurl; //ไม่จำเป็น ไปดูฟังก์ชั่น copyLinkShareAsset เพราะว่าสร้างแชร์ด้วยวิธี manual เพราะ iPhone copy ไม่ได้
          setLinkShare(shareURL); //ไม่จำเป็น ไปดูฟังก์ชั่น copyLinkShareAsset เพราะว่าสร้างแชร์ด้วยวิธี manual เพราะ iPhone copy ไม่ได้

          Swal.fire("คัดลอกสำเร็จ", `${res.data.message}`, "success");
        })
        .catch((err) => {
          Swal.fire("ไม่สำเร็จ", err.response.data.Error, "error");
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API}/mlm/share?aid=${idAsset}&sharer=${sharer}`,
          { SecretKey: "HelloHacker" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          const shareURL = res.data.shareurl; //ไม่จำเป็น ไปดูฟังก์ชั่น copyLinkShareAsset เพราะว่าสร้างแชร์ด้วยวิธี manual เพราะ iPhone copy ไม่ได้
          setLinkShare(shareURL); //ไม่จำเป็น ไปดูฟังก์ชั่น copyLinkShareAsset เพราะว่าสร้างแชร์ด้วยวิธี manual เพราะ iPhone copy ไม่ได้

          Swal.fire("คัดลอกสำเร็จ", `${res.data.message}`, "success");
        })
        .catch((err) => {
          Swal.fire("ไม่สำเร็จ", err.response.data.Error, "error");
        });
    }
  };

  const copyLinkShareAsset = async (isLogin) => {
    if (!isLogin) {
      handleSaveLocationURL("/login", true);
    }
    // await navigator.clipboard.writeText(LinkShare);
    // ทีต้องดึง sharer มาจาก localstorage เพราะว่าต้องการสร้างลิงค์แชร์แบบ manual เพราะ iPhone ไม่สามารถก็อบด้วยวิธีปกติได้
    // sharer คือ id ของเจ้าของคนที่กำลังจะแชร์ดังนั้น เมื่อcopy share สำเร็จให้ยิง api post เพื่อสร้างสายแชร์
    const sharer = localStorage.getItem("uid");
    const UidOfUser = localStorage.getItem("uidOfUser");
    if (sharer !== null) {
      await navigator.clipboard.writeText(
        `https://share.postperty.com/api/mlm/follow?aid=${idAsset}&sharer=${sharer}`
      );
    } else {
      await navigator.clipboard.writeText(
        `https://share.postperty.com/api/mlm/follow?aid=${idAsset}&sharer=${UidOfUser}`
      );
    }
    handleShareAssetMLM(); // สร้าง สายแชร์
    // Swal.fire(
    //   "คัดลอกสำเร็จ",
    //   "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้",
    //   "success"
    // );
  };

  // Share Real Estate

  // Add to cart

  const handleAddToCart = () => {
    const assetId = dataProduct.id;
    axios
      .post(
        `${process.env.REACT_APP_API}/event/addBasket?assetId=${assetId}`,
        {
          assetId: assetId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire("สำเร็จ", "เพิ่มเข้าทรัพย์ที่ถูกใจเรียบร้อย", "info");
      })
      .catch((err) => {
        const errMessage = err.response.data.Error;
        Swal.fire("", `${errMessage}`, "warning");
      });
  };

  // Add to cart

  // Send data to dashboard admin (log ให้ admin เห็นว่า มีใครโทรเข้ามา โชว์ id ทรัพย์ timestamp uid  และ อื่นๆ และ รอรับ contact ของเจ้าของโพสต์ เพื่อแสดงในติดต่อผู้ขาย)

  const [ownerContactDataFromServer, setOwnerContactDataFromServer] =
    useState("");
  const [AssetContactDataFromServer, setAssetContactDataFromServer] =
    useState("");
  console.log(ownerContactDataFromServer);
  const handleSendDataToDashboardAdmin = () => {
    let fullURL = window.location.href;
    const sharer = getSharerFromURL(fullURL);
    console.log(sharer);

    axios
      .post(
        `${process.env.REACT_APP_API}/event/getContact`,
        {
          assetId: dataProduct.id,
          OwnerUid: ownerData.uid,
          sharer: sharer !== null ? sharer : undefined, // check url current asset have sharer if it have send sharer but is havn't send undefinded
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(`This is modal contact`, res.data);
        // console.log(res)
        const OwnerContact = res.data.OwnerContact;
        const AssetContact = res.data.AssetContact;
        setAssetContactDataFromServer(AssetContact);
        setOwnerContactDataFromServer(OwnerContact);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.OwnerContact);
      });
  };
  // Send data to dashboard admin

  // Report asset

  // console.log(dataProduct.id)
  const [reportDataUpload, setReportDataUpload] = useState({
    reason: [],
  });
  const [lengthOfDetailPost, setLengthOfDetailPost] = useState(0);
  const [prevValue, setPrevValue] = useState("");

  const { reason } = reportDataUpload;
  const handleReportAsset = (value) => {
    console.log(value);
    if (reason.includes(value)) {
      // Remove the item from the array
      const updatedReport = reason.filter((item) => item !== value);
      setReportDataUpload({ ...reportDataUpload, reason: updatedReport });
    } else {
      // Add the item to the array
      const updatedReport = [...reason, value];
      setReportDataUpload({ ...reportDataUpload, reason: updatedReport });
    }
  };
  const handleDetailPostLimit = (e) => {
    const lengthOfDetail = e.target.value.length; // Get the length of the input
    const textOfDetail = e.target.value; // Get the actual input
    const maxLimitPostLength = 180; // Define the maximum length of the input

    // If the input is not longer than the maximum length...
    if (lengthOfDetail <= maxLimitPostLength) {
      // ... and if the textarea is now empty but wasn't before, and the previous value is in the reason array
      if (
        textOfDetail === "" &&
        prevValue !== "" &&
        reason.includes(prevValue)
      ) {
        // Remove the previous value from the reason array
        handleReportAsset(prevValue);
      }
      // ... or if the textarea is not empty, and the previous value was not empty, and the previous value is in the reason array
      else if (
        textOfDetail !== "" &&
        prevValue !== "" &&
        reason.includes(prevValue)
      ) {
        // Replace the old value with the new value in the reason array
        const updatedReport = reason.map((item) =>
          item === prevValue ? textOfDetail : item
        );
        setReportDataUpload({ ...reportDataUpload, reason: updatedReport });
      }
      // ... or if the textarea is not empty and the current value is not in the reason array
      else if (textOfDetail !== "" && !reason.includes(textOfDetail)) {
        // Add the new value to the reason array
        handleReportAsset(textOfDetail);
      }

      // Update prevValue to the current value of the textarea
      setPrevValue(textOfDetail);
      // Update the length of the post
      setLengthOfDetailPost(lengthOfDetail);
    } else {
      // If the input is too long, show a warning and trim the input to the maximum length
      Swal.fire("ระวัง!", "ป้อนข้อความได้สูงสุด 180 ตัวอักษร", "warning");
      const trimmedText = e.target.value.slice(0, maxLimitPostLength);
      e.target.value = trimmedText;
      setLengthOfDetailPost(trimmedText.length);
    }
  };

  const handleReportSendToDashboardAdmin = () => {
    if (reason.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_API}/report/asset`,
          {
            reason,
            assetId: idAsset,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          Swal.fire("รายงานสำเร็จ", res.data.Success, "success");
        })
        .catch((err) => {
          Swal.fire("ไม่สำเร็จ", err.response.data.Error, "error");
        });
    } else {
      Swal.fire("ไม่สำเร็จ", "กรุณาเลือกเหตุผลการรายงาน", "error");
    }
  };
  // Report asset

  // click to zoom image
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // console.log(moda)
  const [lightboxImage, setLightboxImage] = useState("");
  // Click to zoom image

  function handleCompareAssets() {
    const id_asset = idAsset; // assuming idAsset is defined somewhere in your code
    console.log("cllicked asset: " + id_asset);

    // Try to retrieve existing array from localStorage and parse it
    // If no array exists, default to an empty array
    const existingAssets =
      JSON.parse(localStorage.getItem("compare_asset")) || [];

    // Push the new id_asset to the array
    console.log(existingAssets);
    if (!existingAssets.includes(id_asset)) {
      existingAssets.push(id_asset);
    }

    // Stringify the updated array and store it back in localStorage
    localStorage.setItem("compare_asset", JSON.stringify(existingAssets));
  }

  const Nearby_location = ({ dataArray, title }) => {
    if (!dataArray || dataArray.length === 0) return null;
    if (title == "customName") return null;
    // Translation object containing English as keys and Thai as values
    const titleTranslations = {
      schools: "โรงเรียน",
      airports: "สนามบิน",
      bts: "บีทีเอส",
      mrt: "เอ็มอาร์ที",
      hospitals: "โรงพยาบาล",
      shoppingMalls: "ห้างสรรพสินค้า",
      trainStations: "สถานีรถไฟ",
      customName: "อื่นๆ",
    };

    // Get the Thai title from the translation object, if it exists, else use the original title
    const thaiTitle = titleTranslations[title] || title;

    return (
      <div className="grid col-span-12 col-span-12 max-sm:col-span-6 h-full p-2.5 border rounded-xl">
        <div className="col-span-12 bg-postperty-linear-light  text-white text-center rounded-xl">
          {thaiTitle}
        </div>
        {/* Only the first 6 items from dataArray will be mapped and displayed */}
        {dataArray.slice(0, 3).map((data, idx) => {
          return (
            <div className="col-span-12 h-auto border rounded-l mt-1.5 p-2.5">
              {idx + 1 + ")" + " " + data.name + " " + data.distance + "km"}
            </div>
          );
        })}
      </div>
    );
  };

  // DropDown Nearby Location
  const [expanded, setExpanded] = useState(false);
  function handleDropDownListMember(index) {
    setExpanded(!expanded);
  }

  function handleSwitchToGoogleMapAndOpenMarker(idAsset) {
    // console.log(idAsset)
    axios
      .post(`${process.env.REACT_APP_API}/fetchdata/get-latlng`, {
        asset_id: idAsset,
      })
      .then(async (res) => {
        // console.log(res.data)
        await localStorage.setItem(
          "positionBeforeExitedPage",
          JSON.stringify(res.data)
        );

        // เซ็ต state ใน localSotrage เพื่อใช้ useEffect ในหน้า Grid เมื่อเจอ State นี้ (tempSwitchToMarkerOfGoogleMap) จะทำกับ switch ไปหน้า map เลย แทนที่จะค้างอยู่หน้า Grid
        localStorage.setItem("tempSwitchToMarkerOfGoogleMap", true);
        // changeStatusMap();
        sessionStorage.setItem("statusCloseMap", false);
        window.location.replace("/home");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNavigationToSwitchGoogleMap(location) {
    if (location && location.lat && location.lng) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`
      );
    } else {
      console.error("Invalid location object provided.");
    }
  }

  function handleSaveLocationURL(urlRedirect, isAllowToSaveLocationURL) {
    if (sharer !== null || isAllowToSaveLocationURL) {
      // Save the current URL to sessionStorage before redirecting
      sessionStorage.setItem(
        "tempURLProductForBackwardAfterLoginOrRegister",
        fullURL
      );

      // Construct new URL using the protocol and host from the current URL and the given urlRedirect
      let newURL =
        window.location.protocol + "//" + window.location.host + urlRedirect;

      // Redirect to the new URL
      window.location.replace(newURL);
    } else {
      // window.location.replace(urlRedirect);
    }
  }

  function handleCloseNewTab() {
    const isNewTab = localStorage.getItem("isNewTab") === "true";

    if (isNewTab) {
      // If it’s a new tab, attempt to close it
      localStorage.removeItem("isNewTab");
      window.close();
    } else {
      // If it’s not a new tab, navigate back in history
      window.history.back();
    }
  }

  if (isLoadingData) {
    return (
      <div className="flex flex-col w-full h-screen  justify-center items-center ">
        <BeatLoader color="#000" />
        <span className="mt-2.5">กำลังโหลดข้อมูล</span>
      </div>
    );
  }

  return (
    <div className="flex bg-white md:bg-white h-[1099px] max-sm:overflow-y-auto">
      <div className="w-full  h-auto flex flex-col items-center   ">
        <div className="w-96  md:w-full  ">
          <div className="w-96 h-9  relative flex">
            {/* <button
                            className="bg-white w-9  h-6  absolute left-9 top top-1 rounded-3xl  "
                        >
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
                                alt="backword"
                                className="w-4 h-4  m-auto"
                            ></img>
                        </button> */}
            <h1 className="text-black w-full text-center mt-auto mb-auto text-base ">
              {/* {dataProduct.id} */}
            </h1>
          </div>
        </div>
        <div className="w-11/12 h-auto mx-auto  md:grid md:grid-cols-12    ">
          <div className="w-full max-sm:h-[260px] md:h-[800px]  md:col-span-12 lg:col-span-8  max-h-800px  mt-4 relative group ">
            <AiOutlineArrowLeft
              className="absolute left-0 top-[-35px] border bg-white rounded-full p-1  text-white bg-postperty-linear-light w-[150px] w-8 h-8 cursor-pointer"
              onClick={() => handleCloseNewTab()}
            />

            {/* <div
                            style={{ backgroundImage: `url(${dataProduct != "" ? dataProduct.images_url.AssetImagesURL[currentIndexSlide] : ""})` }}
                            className="w-full h-full rounded-2xl bg-center bg-contain bg-no-repeat bg-black duration-100 "
                            onClick={() => { setModalIsOpen(true); setLightboxImage(dataProduct.images_url.AssetImagesURL[currentIndexSlide]); }}>
                
                            </div> */}
            {/* <img src="https://www.postperty.com/Assets/CarreersX.jpg" className="w-full h-full rounded-2xl bg-center bg-contain bg-no-repeat bg-black duration-100 border border-red-500" /> */}
            <div ref={slider} className=" w-full h-[300px] md:h-full relative">
              {/* <div className={dataProduct.sold ? "absolute top-1/2 left-1/2 tranform -translate-x-1/2 -translate-y-1/2 rotate-45 w-[300px] text-center text-white bg-black " : 'hidden'}>.</div> */}
              {/* <img src={`https://cdn-icons-png.flaticon.com/512/3643/3643173.png`} className={dataProduct.sold ? "absolute top-1/2  left-1/2 tranform -translate-x-1/2 -translate-y-1/2 w-[99px] h-[99px] md:h-[150px] md:w-[150px]" : 'hidden'} /> */}
              {dataProduct.sold ? (
                <img
                  className="absolute left-1/2 top-1/2 transform -translate-x-1/2 w-full h-full -translate-y-1/2 Z-0"
                  src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/icon/SOLD%20OUT.svg"
                  alt="sold-out-icon"
                />
              ) : null}
              <div
                style={{
                  backgroundImage: `url(${
                    dataProduct != ""
                      ? dataProduct.images_url.AssetImagesURL[currentIndexSlide]
                      : ""
                  })`,
                }}
                className={`w-full h-full bg-center bg-contain bg-no-repeat bg-black duration-100 mt-6 ${
                  isZoomed ? "zoomed" : "rounded-xl"
                }`}
                onClick={() => setIsZoomed(!isZoomed)}
              ></div>
            </div>

            {/* Left Arrow */}
            <div className=" group-hover:block absolute top-1/2 -translate-x-0 translate-y-1/2 tranform left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div>
            {/* Right Arrow */}
            <div className=" group-hover:block absolute top-1/2 -translate-x-0 translate-y-1/2 tranform right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div>

            {/*    ID Asset */}
            <div className="border rounded shadow-l h-18 flex flex-col mt-2.5 p-1.5">
              <div className="w-full flex justify-between">
                <span>{idAsset}</span>

                <span>ผู้เข้าชม {dataProduct.views} ครั้ง</span>
              </div>

              {/* <div className="border border-green-500 h-12 w-full flex justify-center items-center mt-2.5">
                <span className="" onClick={handleCompareAssets}>เปรียบเทียบอสังหาฯ</span>
              </div> */}
            </div>

            {/* Modal */}
            {modalIsOpen && (
              <div
                className="fixed z-10 inset-0 flex items-center justify-center overflow-auto bg-black bg-opacity-50 rounded-md border border-red-500"
                onClick={() => setModalIsOpen(false)}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="relative bg-white mx-auto p-4  md:w-[900px]  rounded-md"
                >
                  <img src={lightboxImage} alt="" className="w-full h-auto" />
                  {/* Left Arrow */}
                  <div className="absolute top-[50%] -translate-x-1/2 -translate-y-1/2 left-12 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                    <button
                      className="focus:outline-none"
                      onClick={() => {
                        const newIndex =
                          currentIndexSlide - 1 >= 0
                            ? currentIndexSlide - 1
                            : dataProduct.images_url.AssetImagesURL.length - 1;
                        setCurrentIndexSlide(newIndex);
                        setLightboxImage(
                          dataProduct.images_url.AssetImagesURL[newIndex]
                        );
                      }}
                    >
                      <BsChevronCompactLeft size={30} />
                    </button>
                  </div>
                  {/* Right Arrow */}
                  <div className="absolute top-[50%] -translate-x-1/2 -translate-y-1/2 right-4 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                    <button
                      className="focus:outline-none"
                      onClick={() => {
                        const newIndex =
                          (currentIndexSlide + 1) %
                          dataProduct.images_url.AssetImagesURL.length;
                        setCurrentIndexSlide(newIndex);
                        setLightboxImage(
                          dataProduct.images_url.AssetImagesURL[newIndex]
                        );
                      }}
                    >
                      <BsChevronCompactRight size={30} />
                    </button>
                  </div>
                  <button
                    className="absolute top-0 right-4 mt-4"
                    onClick={() => setModalIsOpen(false)}
                  >
                    <div
                      className="w-10 h-10 rounded-full bg-red-500 flex justify-center items-center text-white"
                      alt="close-btn"
                    >
                      X
                    </div>
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="w-full   mx-auto  rounded-2xl mt-4   p-2.5 border   md:col-span-12 lg:mt-2  lg:col-span-4 md:mt-26 ">
            {/* Thumbnails */}
            <div
              className={`grid grid-flow-col gap-2 overflow-x-auto  p-2.5 mb-12 ${
                dataProduct != "" &&
                dataProduct &&
                dataProduct.images_url.AssetImagesURL.length <= 5
                  ? "justify-center"
                  : "justify-start md:justify-center"
              }`}
            >
              {dataProduct != "" &&
                dataProduct.images_url.AssetImagesURL.map(
                  (slide, slideIndex) => (
                    <div
                      key={slideIndex}
                      onClick={() => goToSlide(slideIndex)}
                      className="w-16 h-16 mt-2.5"
                    >
                      <img
                        className={`w-full h-full object-cover rounded-md cursor-pointer ${
                          currentIndexSlide === slideIndex
                            ? "shadow-l"
                            : "opacity-30"
                        }`}
                        src={slide}
                        alt=""
                      />
                    </div>
                  )
                )}
            </div>

            <div className="w-11/12 h-auto mx-auto flex flex-col border-b-4 p-2 ">
              <h3
                className={
                  isDisplay
                    ? "text-3xl font-medium md:mt-0"
                    : "text-3xl font-medium md:mt-0 text-red-500"
                }
              >
                {dataProduct != "" && isDisplay
                  ? dataProduct.title
                  : "ทรัพย์นี้ผิดเงื่อนไขการให้บริการของ PostPerty หรือถูกลบออกไปแล้ว"}
              </h3>
              <h4
                className={
                  dataProduct != "" && dataProduct.sell_price === null
                    ? "hidden"
                    : "text-3xl text-green-500 font-medium mt-4 md:mt-0 b"
                }
              >
                {" "}
                {dataProduct != "" && (
                  <FormatPrice number={dataProduct.sell_price} suffix={"THB"} />
                )}{" "}
              </h4>
              <h5 className={dataProduct.rent_price === null ? "hidden" : ""}>
                {dataProduct !== "" && (
                  <FormatPrice
                    number={dataProduct.rent_price}
                    suffix={"THB/เดือน"}
                  />
                )}
              </h5>
              <h5
                className={dataProduct.sellout_price === null ? "hidden" : ""}
              >
                {dataProduct !== "" && (
                  <FormatPrice
                    number={dataProduct.sellout_price}
                    suffix={"/ครั้ง"}
                  />
                )}
              </h5>

              <div className="w-full flex flex-wrap mt-3.5">
                <label
                  htmlFor="my-modal-3"
                  className="btn w-full outline-0 mb-2.5 h-10 bg-postperty-linear-light text-white flex items-center justify-center rounded-full mr-1.5 cursor-pointer hover:scale-105"
                  onClick={handleSendDataToDashboardAdmin}
                >
                  ติดต่อผู้ขาย
                </label>
                {/* <label htmlFor="my-modal-3" className="btn">open modal</label> */}
                <input
                  type="checkbox"
                  id="my-modal-3"
                  className="modal-toggle"
                />
                <div className="modal">
                  <div className="modal-box relative">
                    <label
                      htmlFor="my-modal-3"
                      className="btn btn-sm btn-circle absolute right-2 top-2"
                    >
                      ✕
                    </label>
                    <h3 className="text-lg font-bold">ข้อมูลติดต่อผู้ขาย</h3>

                    {getToken() !== undefined ? (
                      <div>
                        <p className="py-4">
                          เบอร์โทร :{" "}
                          {AssetContactDataFromServer != "" &&
                          AssetContactDataFromServer.PhoneNumber !== ""
                            ? formatPhoneNumber(
                                AssetContactDataFromServer.PhoneNumber
                              )
                            : formatPhoneNumber(
                                ownerContactDataFromServer.phone_number
                              )}
                        </p>
                        {/* เช็คว่า AssetsContact ตอนโพสต์ประกาศ มีหรือไม่ ถ้าไม่มีให้ใช้ Owner Contact คือ ดึงมาจากโปรไฟล์ของ user มาแสดง */}
                        <p className="">
                          Line :{" "}
                          {AssetContactDataFromServer !== "" &&
                          AssetContactDataFromServer.Line !== ""
                            ? AssetContactDataFromServer.Line
                            : ownerContactDataFromServer.line_id}
                        </p>
                        <p className="">
                          Facebook :{" "}
                          {ownerContactDataFromServer !== "" &&
                            !dataProduct.imperfect_asset &&
                            ownerContactDataFromServer.facebook}
                        </p>
                        <p className="">
                          Email :{" "}
                          {ownerContactDataFromServer !== "" &&
                            !dataProduct.imperfect_asset &&
                            ownerContactDataFromServer.email_address}
                        </p>
                        <div className="relative mt-8 flex items-center gap-x-4">
                          <img
                            src={ownerData.profile_image}
                            alt=""
                            className="h-10 w-10 rounded-full bg-gray-50"
                          />
                          <div className="text-sm leading-6">
                            <p className="font-semibold text-gray-900">
                              <a className="flex ">
                                <span className="absolute inset-0" />
                                <p className="mr-1">{ownerData.uname}</p>{" "}
                                {ownerData.verify_level === 3 ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    stroke="#888"
                                    strokeWidth="2"
                                    fill="none"
                                    width="23"
                                    height="23"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    stroke="#888"
                                    strokeWidth="2"
                                    fill="none"
                                    width="23"
                                    height="23"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                )}
                              </a>
                            </p>
                            {/* <p className="text-gray-600">{ownerData.role === "default" ? <span>user</span> : "admin"}</p> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-12 gap-2.5">
                        <p className="col-span-12">
                          คุณต้องเข้าสู่ระบบหรือสมัครสมาชิกเพื่อดูรายละเอียด
                        </p>

                        <div
                          className="col-span-6 "
                          onClick={() => handleSaveLocationURL("/login", true)}
                        >
                          {/* <Link to={`/login`}> */}
                          <div className="w-full h-12 rounded-full bg-quepal flex justify-center items-center text-white mt-2.5 cursor-pointer">
                            เข้าสู่ระบบ
                          </div>
                          {/* </Link> */}
                        </div>
                        <div
                          className="col-span-6 "
                          onClick={() =>
                            handleSaveLocationURL(
                              `/register?ID=${ownerData.uname}`,
                              true
                            )
                          }
                        >
                          {/* <Link to={`/register?ID=${ownerData.uname}`}> */}
                          <div className="w-full h-12 rounded-full bg-sun flex justify-center items-center text-white mt-2.5 cursor-pointer">
                            สมัครสมาชิก
                          </div>
                          {/* </Link> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 w-full gap-x-1">
                  {dataProduct != "" && dataProduct.media.vr_url !== "" ? (
                    <Link to={`${dataProduct.media.vr_url}`}>
                      <div className="w-full  h-10 bg-white text-black border flex items-center justify-center rounded-full mb-6 cursor-pointer hover:scale-105 mr-1.5">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/440/440758.png"
                          className="w-8 h-8 mr-2.5"
                        />
                        ดู 3D
                      </div>
                    </Link>
                  ) : (
                    <div className="hidden">ไม่มี3D</div>
                  )}

                  {dataProduct != "" && dataProduct.media.YoutubeURL !== "" ? (
                    <Link to={`${dataProduct.media.YoutubeURL}`}>
                      <div className="w-full h-10 bg-white text-black  border flex items-center justify-center rounded-full mb-6 cursor-pointer hover:scale-105 mr-1.5">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/1384/1384060.png"
                          className="w-8 h-8 mr-2.5"
                        />
                        ดู วีดีโอ
                      </div>
                    </Link>
                  ) : (
                    <div className="hidden">ไม่มีวีดีโอ</div>
                  )}
                </div>
                <div className="grid grid-cols-3 w-full gap-x-1">
                  {allowShareAsset.AllowShare ? (
                    <div
                      className="w-full h-10 bg-green-500 text-white rounded-full flex items-center justify-center  mb-6 mr-1.5 cursor-pointer hover:scale-105"
                      onClick={() => copyLinkShareAsset(allowShareAsset.Login)}
                    >
                      แชร์ทรัพย์
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (allowShareAsset.AllowShare === false) {
                          Swal.fire(
                            "",
                            "ทรัพย์นีแชร์แล้วไม่ได้ค่าคอม",
                            "success"
                          );
                          copyLinkShareAsset(allowShareAsset.Login);
                        }

                        if (allowShareAsset.Login == false) {
                          copyLinkShareAsset(allowShareAsset.Login);
                        }
                      }}
                      className={
                        dataProduct != "" && dataProduct.imperfect_asset == true
                          ? "hidden"
                          : "w-full h-10 bg-yellow-500 text-white rounded-full flex items-center justify-center  mb-6 mr-1.5 cursor-pointer hover:scale-105"
                      }
                    >
                      แชร์ทรัพย์
                    </div>
                  )}
                  {allowShareAsset.Login && (
                    <div className="w-full h-10 bg-red-500 text-white flex items-center justify-center rounded-full mb-6 mr-1.5 p-2 cursor-pointer hover:scale-105">
                      <AiFillHeart className="w-6 h-6" />
                      <span className="ml-2" onClick={handleAddToCart}>
                        {allowShareAsset.Login ? (
                          <p>ถูกใจ</p>
                        ) : (
                          <p className="hidden"></p>
                        )}
                      </span>
                    </div>
                  )}
                  {/* <div className="cursor-pointer bg-red-500 flex justify-center items-center" onClick={()=>handleCloseNewTab()}>ปิดหน้ารายการ</div> */}

                  <div className="w-auto h-10 bg-red-500 text-white flex items-center justify-center rounded-full mb-6 mr-1.5 p-2 cursor-pointer hover:scale-105">
                    <FiMapPin className="w-6 h-6" />
                    <span
                      className="ml-2"
                      onClick={() =>
                        handleSwitchToGoogleMapAndOpenMarker(dataProduct.id)
                      }
                    >
                      <p>หมุด</p>
                    </span>
                  </div>

                  <div className="w-auto h-10 bg-blue-500 text-white flex items-center justify-center rounded-full mb-6 mr-1.5 p-2 cursor-pointer hover:scale-105">
                    <BiMapAlt className="w-6 h-6" />
                    <span
                      className="ml-2"
                      onClick={() =>
                        handleNavigationToSwitchGoogleMap(
                          dataProduct.location.coordinates
                        )
                      }
                    >
                      <p>นำทาง</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-11/12 h-auto mx-auto grid grid-cols-12 gap-1 mt-2.5">
              <div className="grid col-span-12 col-span-4 max-sm:col-span-6 h-10">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 flex justify-center items-center ">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/2642/2642252.png"
                      alt="bedroom-Icon"
                      className="w-8 h-8"
                    />
                  </div>
                  <div className="col-span-8 flex justify-center items-center">
                    {dataProduct != "" && dataProduct.rooms.Bedroom} ห้อง
                  </div>
                </div>
              </div>

              <div className="grid col-span-12 col-span-4 max-sm:col-span-6 h-10">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 flex justify-center items-center ">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/3565/3565091.png"
                      alt="bedroom-Icon"
                      className="w-6 h-6"
                    />
                  </div>
                  <div className="col-span-8 flex justify-center items-center">
                    {dataProduct != "" && dataProduct.rooms.Bathroom} ห้อง
                  </div>
                </div>
              </div>

              <div className="grid col-span-12 col-span-4 max-sm:col-span-6 h-10">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 flex justify-center items-center ">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/721/721550.png"
                      alt="bedroom-Icon"
                      className="w-6 h-6"
                    />
                  </div>
                  <div className="col-span-8 flex justify-center items-center">
                    {dataProduct != "" && dataProduct.type_post.Type.join(",")}
                  </div>
                </div>
              </div>

              <div
                className={`relative transition-all ease-in-out duration-500  col-span-12 w-full  cursor-pointer ${
                  expanded ? "h-[450px]" : "h-12"
                } mt-[4px]  flex  justify-around items-center bg-half-light-f5 rounded-[10px]`}
                onClick={() => handleDropDownListMember()}
              >
                <h1 className="absolute top-3.5 left-11">สถานที่ใกล้เคียง</h1>
                <img
                  className="absolute top-3.5 right-10 w-5 h-5 transform transition-transform ease-in-out duration-500"
                  src={
                    expanded
                      ? "https://cdn-icons-png.flaticon.com/512/2985/2985150.png"
                      : "https://cdn-icons-png.flaticon.com/512/2985/2985150.png"
                  }
                  alt="arrow"
                  style={expanded ? { transform: "rotate(180deg)" } : {}}
                />

                <div
                  className={`w-full h-full absolute top-10 max-h-[400px] overflow-y-auto ${
                    expanded ? "flex flex-col" : "hidden"
                  }`}
                >
                  {dataProduct &&
                    typeof dataProduct.nearby_location === "object" &&
                    dataProduct.nearby_location != null &&
                    Object.entries(dataProduct.nearby_location).map(
                      ([key, value]) => (
                        <Nearby_location
                          key={key}
                          dataArray={value}
                          title={key}
                        />
                      )
                    )}
                </div>
              </div>
            </div>

            <div className="w-11/12 h-auto mx-auto mt-2.5 ">
              {/* <PreformattedText text={dataProduct != "" && dataProduct.description} maxLength={sampleText.length} /> */}
              <PreformattedText
                text={dataProduct != "" && dataProduct.description}
              />
            </div>
            <label
              htmlFor="my-modal-4"
              className="w-10/12 mx-auto h-12 bg-red-600  text-white flex items-center justify-center rounded-full mb-6 mt-2.5  p-2 cursor-pointer hover:scale-105"
            >
              รายงานทรัพย์
            </label>
            <input type="checkbox" id="my-modal-4" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box relative">
                <label
                  htmlFor="my-modal-4"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                >
                  ✕
                </label>
                <h3 className="text-lg font-bold">รายงานทรัพย์</h3>
                {getToken() !== undefined ? (
                  <div className="h-[500px] relative">
                    <div className="form-control">
                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                          onClick={() =>
                            handleReportAsset("ผู้ขายไม่นัดรับหมายดูทรัพย์")
                          }
                        />
                        <span className="label-text w-full ml-2.5">
                          ผู้ขายไม่นัดรับหมายดูทรัพย์
                        </span>
                      </label>

                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                          onClick={() =>
                            handleReportAsset("ไม่สามารถติดต่อผู้ขายได้")
                          }
                        />
                        <span className="label-text w-full ml-2.5">
                          {" "}
                          ไม่สามารถติดต่อผู้ขายได้
                        </span>
                      </label>

                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                          onClick={() =>
                            handleReportAsset("ผู้ขายแจ้งว่าได้ขายไปแล้ว")
                          }
                        />
                        <span className="label-text w-full ml-2.5">
                          ผู้ขายแจ้งว่าได้ขายไปแล้ว
                        </span>
                      </label>

                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                          onClick={() => handleReportAsset("เนื้อหาไม่เหมาะสม")}
                        />
                        <span className="label-text text-start w-full ml-2.5">
                          เนื้อหาไม่เหมาะสม
                        </span>
                      </label>

                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                          onClick={() => handleReportAsset("พิกัดไม่ตรง")}
                        />
                        <span className="label-text w-full ml-2.5">
                          พิกัดไม่ตรง
                        </span>
                      </label>

                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                          onClick={() =>
                            handleReportAsset("ละเมิดนโยบายการใช้งาน")
                          }
                        />
                        <span className="label-text w-full ml-2.5">
                          ละเมิดนโยบายการใช้งาน
                        </span>
                      </label>

                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-info"
                        />
                        <span className="label-text w-full ml-2.5">อื่นๆ</span>
                      </label>

                      {/*  Detail Report  */}
                      <div className="w-full h-80 p-2  rounded-full mt-2.5 border  ">
                        <h1 className="text-l my-2.5">
                          รายละเอียดรายงาน <span>{lengthOfDetailPost}/180</span>
                        </h1>
                        <textarea
                          onBlur={handleDetailPostLimit}
                          className="w-full h-60 rounded-xl outline-0 bg-half-light-f5 p-4"
                        />
                      </div>
                      {/* Detail Report  */}
                    </div>

                    <label
                      htmlFor="my-modal-4"
                      className="btn outline-none border border-white btn-circle bg-postperty-linear-light w-full rounded-full h-12 text-white  mt-2.5 "
                      onClick={handleReportSendToDashboardAdmin}
                    >
                      ส่งรายงาน
                    </label>
                  </div>
                ) : (
                  <div className="grid grid-cols-12 gap-2.5">
                    <p className="col-span-12">
                      คุณต้องเข้าสู่ระบบหรือสมัครสมาชิกก่อนจึงจะรายงานทรัพย์ได้
                    </p>

                    <div className="col-span-6 ">
                      <Link
                        onClick={() => handleSaveLocationURL("/login", true)}
                      >
                        <div className="w-full h-12 rounded-full bg-quepal flex justify-center items-center text-white mt-2.5 cursor-pointer">
                          เข้าสู่ระบบ
                        </div>
                      </Link>
                    </div>
                    <div className="col-span-6 ">
                      <Link
                        onClick={() =>
                          handleSaveLocationURL(
                            `/register?ID=${ownerData.uname}`,
                            true
                          )
                        }
                      >
                        <div className="w-full h-12 rounded-full bg-sun flex justify-center items-center text-white mt-2.5 cursor-pointer">
                          สมัครสมาชิก
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-36"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;

import { useState, useEffect } from "react";
import GetLatLngForUpload from "../../GetLatLngToUpload";
import Swal from "sweetalert2";
import { Fragment } from "react";
import { Checkbox } from "@material-tailwind/react";
import axios from "axios";
import Cookies from "js-cookie";
import ImagesUploadEdit from "../ImagesUploadEdit";
import { DotLoader } from "react-spinners";
import { useParams } from "react-router-dom";
const getToken = () => {
  const session_id = Cookies.get("jwt");
  return session_id;
};

const EditHouse = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [lengthOfDetailPost, setLengthOfDetailPost] = useState(0);
  const [selectUnitArea, setSelectUnitArea] = useState(null);
  const [selectUseUnitArea, setSelectUseUnitArea] = useState(null);
  const [selectOwnerStatus, setSelectOwnerStatus] = useState(null);
  const [isFirstHand, setIsFirstHand] = useState(null);
  const [isFurniture, setIsFurniture] = useState(null);
  const [allowComment, setAllowComment] = useState(true); // Default to true
  const [isLoadingOldData, setIsLoadingOldData] = useState(true);
  const [upload, setUpload] = useState({
    images: [],
    front_deeds: [],
    back_deeds: [],
    youtube_url: "",
    vr_url: "",
    heading_post: "",
    detail_post: "",
    type_post: [],
    floor_house: null,
    unit_area: null,
    area: null,
    use_unit_area: null,
    use_area: null,
    bedroom: null,
    bathroom: null,
    price: null,
    rent_price_: null,
    sellOut_price: null,
    owner_status: null,
    first_hand: null,
    furniture_included: null,
    line_id: "",
    phone_number: "",
    recovery_phone_number: "",
    excursive_option: [],
    asset_location_latitude: null,
    asset_location_longitude: null,
    // line_id :"",
  });
  const {
    images,
    front_deeds,
    back_deeds,
    youtube_url,
    vr_url,
    heading_post,
    detail_post,
    type_post,
    floor_house,
    unit_area,
    area,
    use_unit_area,
    use_area,
    bedroom,
    bathroom,
    price,
    rent_price_,
    sellOut_price,
    owner_status,
    first_hand,
    furniture_included,
    line_id,
    phone_number,
    recovery_phone_number,
    excursive_option,
    asset_location_latitude,
    asset_location_longitude,
  } = upload;

  console.log(`select unit`, selectUnitArea);



  useEffect(() => {
    if (getToken() === undefined) {
      window.location.replace(`/login`);
    }
  }, []);

  const { id } = useParams();
  useEffect(() => {
    const getOldData = () => {
      axios
        .get(`${process.env.REACT_APP_API}/fetchEditData/product/${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((res) => {
          const oldDataAsset = res.data.asset;
          const allow_comment = oldDataAsset.allowcomment;
          if (allow_comment !== undefined) {
            setAllowComment(allow_comment);
          }
          console.log(oldDataAsset);
          const {
            images_url: { AssetImagesURL, BackDeedsURL, FrontDeedsURL },
            media: { YoutubeURL, vr_url },
            title,
            description,
            type_post: { Type },
            floor,
            land: { living_size, living_unit, total_size, total_unit },
            rooms: { Bathroom, Bedroom },
            sell_price,
            rent_price,
            sellout_price,
            owner_status,
            firsthand,
            furniture,
            contact: { Line, PhoneNumber },
            excursive_option: { Items },
            location: {
              coordinates: { lat, lng },
            },
          } = oldDataAsset;
          console.log(AssetImagesURL);
          // Display Old data asset to user for can edit

          setUpload((currentUpload) => ({
            ...currentUpload,
            images: AssetImagesURL,
            front_deeds: FrontDeedsURL,
            back_deeds: BackDeedsURL,
            youtube_url: YoutubeURL,
            vr_url: vr_url,

            heading_post: title,
            detail_post: description,
            type_post: Type,
            floor_house: floor,
            area: total_size,
            unit_area: total_unit,

            use_area: living_size,
            use_unit_area: living_unit,
            bathroom: Bathroom,
            bedroom: Bedroom,
            price: sell_price,
            rent_price_: rent_price,
            sellOut_price: sellout_price,
            first_hand: firsthand,
            owner_status: owner_status,
            furniture_included: furniture,
            phone_number: PhoneNumber,
            line_id: Line,
            excursive_option: Items,
            asset_location_latitude: lat,
            asset_location_longitude: lng,
          }));


          setAllowComment(allow_comment); // Set allowComment value

          setSelectUnitArea(total_unit);
          setSelectUseUnitArea(living_unit);
          setIsFurniture(furniture);
          setSelectOwnerStatus(owner_status);
          setIsFirstHand(firsthand);
          setIsLoadingOldData(false)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getOldData();
  }, [id]);

  const inputValue = (name) => (e) => {
    setUpload({ ...upload, [name]: e.target.value });
  };

  const toggleAllowComment = () => {
    setAllowComment(!allowComment); // Toggle between true and false
  };

  function formatNumberWithCommas(value) {
    if (typeof value === "string") {
      // Remove commas from the value if it already has them
      value = value.replace(/,/g, "");
    }

    // Parse the value as a number
    const numberValue = parseFloat(value);

    // Check if the parsed value is a valid number
    if (!isNaN(numberValue)) {
      // Format the number with commas
      return numberValue.toLocaleString();
    }

    // Return the original value if it couldn't be formatted
    return value;
  }


  const inputValuePrice = (name) => (e) => {
    let value = e.target.value;

    if (name === "price" && value !== "") {
      value = value.replace(/,/g, ""); // Remove existing commas
      value = formatNumberWithCommas(value); // Format the value with commas
    } else if (name === "rent_price_" && value !== "") {
      value = value.replace(/,/g, ""); // Remove existing commas
      value = formatNumberWithCommas(value); // Format the value with commas
    } else if (name === "sellOut_price" && value !== "") {
      value = value.replace(/,/g, ""); // Remove existing commas
      value = formatNumberWithCommas(value); // Format the value with commas
    }




    setUpload({ ...upload, [name]: value });
  };


  const handlePhotosRealEstate = async (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);

    // await setUpload({ ...upload, images: [{...images, ...newImages}] });
    await setUpload({ ...upload, images: newImages });
  };
  const handleFrontDeedsEstate = async (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);
    await setUpload({ ...upload, front_deeds: newImages });
  };

  const handleBackDeedsEstate = async (e) => {
    const files = e.target.files;
    const newImages = Array.from(files);
    await setUpload({ ...upload, back_deeds: newImages });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const nextPage = (page) => {
    if (page === 1) {
      let validateResult = ValidateDataFrontendPageOne();
      if (validateResult) {
        setPageNumber(pageNumber + 1);
        scrollToTop();
      }
    }

    if (page === 2) {
      let validateResult = ValidateDataFrontendPageTwo();
      if (validateResult) {
        setPageNumber(pageNumber + 1);
        scrollToTop();
      }
    }

    if (page === 3) {
      let validateResult = ValidateDataFrontendPageThree();
      if (validateResult) {
        setPageNumber(pageNumber + 1);
        scrollToTop();
      }
    }
  };

  const setPageByTabTop = (page) => {
    let resultValidateOne = ValidateDataFrontendPageOne();
    let resultValidateTwo = ValidateDataFrontendPageTwo();
    if (page === 1) {
      setPageNumber(page);
    }
    if (page === 2) {
      if (resultValidateOne) {
        setPageNumber(page);
      }
    }
    if (page === 3) {
      if (resultValidateTwo) {
        setPageNumber(page);
      }
    }

    if (page === 4) {
      if (resultValidateTwo) {
        setPageNumber(page);
      }
    }
  };

  const handleDetailPostLimit = (e) => {
    const lengthOfDetail = e.target.value.length;
    const textOfDetail = e.target.value;
    const maxLimitPostLength = 1800;

    if (lengthOfDetail <= maxLimitPostLength) {
      setUpload({ ...upload, detail_post: textOfDetail });
      setLengthOfDetailPost(lengthOfDetail);
    } else {
      Swal.fire("ระวัง!", "ป้อนข้อความได้สูงสุด 1800 ตัวอักษร", "warning");
      // If more than 1800 char remove excess text
      const trimmedText = e.target.value.slice(0, maxLimitPostLength);
      setUpload({ ...upload, detail_post: trimmedText });
      e.target.value = trimmedText;
      setLengthOfDetailPost(trimmedText.length);
    }
  };
  const [sellTypeActive, setSellTypeActive] = useState(false);

  // if don't use this is not work it set state when type_post change. state from server true or false
  useEffect(() => {
    if (type_post.includes("ขาย")) {
      setSellTypeActive(type_post.includes("ขาย"));
    }
  }, [type_post]); // this effect runs whenever type_post changes

  const [statusTypeRentActive, setStatusTypeRentActive] = useState(false);

  // if don't use this line is not work it set state when type_post change. state from server true or false
  useEffect(() => {
    if (type_post.includes("เช่า")) {
      setStatusTypeRentActive(type_post.includes("เช่า"));
    }
  }, [type_post]); // this effect runs whenever type_post changes

  const [statusTypeSellOutActive, setStatusTypeSellOutActive] = useState(false);
  console.log(`test `, statusTypeSellOutActive);
  // if don't use this line is not work it set state when type_post change. state from server true or false
  useEffect(() => {
    if (type_post.includes("เซ้ง")) {
      setStatusTypeSellOutActive(type_post.includes("เซ้ง"));
    }
  }, [type_post]); // this effect runs whenever type_post changes

  const handleTypePost = (value) => {
    if (value === "ขาย") {
      setSellTypeActive(!sellTypeActive);
    }
    if (value === "เช่า") {
      setStatusTypeRentActive(!statusTypeRentActive);
    }
    if (value === "เซ้ง") {
      setStatusTypeSellOutActive(!statusTypeSellOutActive);
    }
    if (type_post.includes(value)) {
      // Remove the item from the array
      const updatedTypePost = type_post.filter((item) => item !== value);
      setUpload({ ...upload, type_post: updatedTypePost });
    } else {
      // Add the item to the array
      const updatedTypePost = [...type_post, value];
      setUpload({ ...upload, type_post: updatedTypePost });
    }
  };

  const handleUnitArea = (unitArea) => {
    setSelectUnitArea(unitArea);
    setUpload({ ...upload, unit_area: unitArea });
  };
  const handleUseAreaUnitArea = (unitArea) => {
    setSelectUseUnitArea(unitArea);
    setUpload({ ...upload, use_unit_area: unitArea });
  };

  const handleBedRoom = (e) => {
    setUpload({ ...upload, bedroom: e.target.value });
  };

  const handleBathRoom = (e) => {
    setUpload({ ...upload, bathroom: e.target.value });
  };

  const handleOwnerStatus = (status) => {
    setSelectOwnerStatus(status);
    setUpload({ ...upload, owner_status: status });
  };

  const handleFirstHand = (status) => {
    setIsFirstHand(status);
    setUpload({ ...upload, first_hand: status });
  };

  const handleFurniture = (status) => {
    setIsFurniture(status);
    setUpload({ ...upload, furniture_included: status });
  };

  const [ExcursiveSwim, setExcursiveSwim] = useState(false);
  const [ExcursiveParking, setExcursiveParking] = useState(false);
  const [ExcursiveCentral, setExcursiveCentral] = useState(false);
  const [ExcursivePets, setExcursivePets] = useState(false);

  // if don't use this is not work it set state when excursive_option change. state from server true or false
  useEffect(() => {
    if (excursive_option.includes("สระว่ายน้ำ")) {
      setExcursiveSwim(excursive_option.includes("สระว่ายน้ำ"));
    }
  }, [excursive_option]); // this effect runs whenever excursive_option changes

  useEffect(() => {
    if (excursive_option.includes("โรงจอดรถ")) {
      setExcursiveParking(excursive_option.includes("โรงจอดรถ"));
    }
  }, [excursive_option]);

  useEffect(() => {
    if (excursive_option.includes("ส่วนกลาง")) {
      setExcursiveCentral(excursive_option.includes("ส่วนกลาง"));
    }
  }, [excursive_option]);

  useEffect(() => {
    if (excursive_option.includes("เลี้ยงสัตว์เลี้ยงได้")) {
      setExcursivePets(excursive_option.includes("เลี้ยงสัตว์เลี้ยงได้"));
    }
  }, [excursive_option]);

  const handleExcursive_Option = (value) => {
    if (value === "สระว่ายน้ำ") {
      setExcursiveSwim(!ExcursiveSwim);
    }
    if (value === "โรงจอดรถ") {
      setExcursiveParking(!ExcursiveParking);
    }
    if (value === "ส่วนกลาง") {
      setExcursiveCentral(!ExcursiveCentral);
    }

    if (value === "เลี้ยงสัตว์เลี้ยงได้") {
      setExcursivePets(!ExcursivePets);
    }

    if (excursive_option.includes(value)) {
      // Remove the item from the array
      const updatedExcursiveOption = excursive_option.filter(
        (item) => item !== value
      );
      setUpload({ ...upload, excursive_option: updatedExcursiveOption });
    } else {
      // Add the item to the array
      const updatedExcursiveOption = [...excursive_option, value];
      setUpload({ ...upload, excursive_option: updatedExcursiveOption });
    }
  };

  const getCookie = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const [isUpdating, setisUpdating] = useState(false);

  const updateDataAsset = () => {
    if (pageNumber === 4) {
      let validateResult = ValidateDataFrontendPageFour();

      if (validateResult) {
        setisUpdating(true);
        if (!isUpdating) {
          axios
            .put(
              `${process.env.REACT_APP_API}/updatepost/update`,
              {
                asset_id: id,
                images, //ต้องใส่ Content-type form Data ด้วย
                // images : ["https://i.ytimg.com/vi/zaYcPPdBkrw/maxresdefault.jpg" , "https://i.ytimg.com/vi/HBrg8lsvo6w/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAFhxpSzbn32Ng6EdLOHfkijVw4CQ"],
                frontdeeds: front_deeds,
                backdeeds: back_deeds,
                youtube_url: youtube_url,
                vr_url: vr_url,
                title: heading_post,
                description: detail_post,
                type_post: type_post,
                floor: floor_house,
                total_unit: unit_area,
                total_size: area,
                living_size: use_area,
                living_unit: use_unit_area,
                bedroom: bedroom,
                bathroom: bathroom,
                sell_price: price,
                rent_price: rent_price_,
                sellout_price: sellOut_price,
                owner_status: owner_status,
                first_hand: first_hand,
                furniture_included: furniture_included,
                contact_phonenumber: phone_number,
                contact_line: line_id,
                excursive_option: excursive_option,
                lat: asset_location_latitude,
                lng: asset_location_longitude,
                allow_comment: allowComment,

              },
              {
                headers: {
                  Authorization: `Bearer ${getCookie()}`,
                  "Content-Type": "multipart/form-data", //Important For Recive image[] ต้องมี
                },
              }
            )
            .then(async (res) => {
              // await handleRemoveDataInIndexedDB();
              console.log(res.data);
              setisUpdating(false);
              await Swal.fire("สำเร็จ", res.data.Success, "success");
              window.location.replace("/home");
            })
            .catch((err) => {
              console.log(err);
              //when unsuccess is reset checkbox
              // setUpload({ ...upload, type_post: [] });
              Swal.fire(
                "ไม่สำเร็จ",
                err.response.data.message || err.response.data.Error,
                "error"
              );
              setisUpdating(false);
            });
        }
      }
    }
  };

  const openImagesUploadEdits = () => {
    setPageNumber(1.5);
  };

  // const handleFileInputChange = async (e) => {
  //     const files = e.target.files;
  //     const newImages = Array.from(files);
  //     // await setUpload({ ...upload, images: [{...images, ...newImages}] });
  //     await setUpload({ ...upload, images: newImages });
  //   };

  // Validate Data Frontend

  // Page 1
  function isValidYouTubeUrl(youtube_url) {
    let url = youtube_url;
    let youtubeRegExp = /^(https?:\/\/)?(www\.)?(m\.)?((youtube\.com)|(youtu\.be))\/.+$/;



    console.log("url", url)

    if (url !== null) {
      if (url.match(youtubeRegExp)) {
        return true;
      } else {
        return false;
      }
    } else if (url == "" || url == null) {
      return true
    }


  }

  function isValidVR_URL(vr_url) {
    let url = vr_url;
    const matterportRegExp = /^https:\/\/(my\.matterport\.com\/show\/\?.*m=.+|matterport\.com\/discover\/space\/.+)$/;
    const cloudPanoRegex = /^https?:\/\/[a-zA-Z0-9_-]+\.cloudpano\.com\/tours\/[a-zA-Z0-9_-]+\/?$/;
    const kuulaRegex = /^https?:\/\/kuula\.co\/[a-zA-Z0-9_-]+\/?$/;
    const pano360Regex = /^https?:\/\/[a-zA-Z0-9_-]+\.pano360\.com\/[a-zA-Z0-9_-]+\/?$/;

    if (url.match(matterportRegExp) || url.match(cloudPanoRegex) || url.match(kuulaRegex) || url.match(pano360Regex)) {
      return true;
    } else {
      return false;
    }
  }
  function ValidateDataFrontendPageOne() {
    // Assuming images, youtube_url and vr_url variables are defined elsewhere

    if (images.length <= 0) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาใส่รูปภาพ", "warning");
      return false;
    } else if (youtube_url !== "" && !isValidYouTubeUrl(youtube_url)) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาใส่ลิงก์ YouTube ที่ถูกต้อง", "warning");
      return false;
    } else if (vr_url !== "" && !isValidVR_URL(vr_url)) {
      Swal.fire(
        "ข้อมูลไม่ถูกต้อง",
        "VR_URL ที่รองรับ Matterport , Cloudpano , Kuula , Pano360",
        "warning"
      );
      return false;
    }
    return true;
  }

  // Page 1

  // Page 2

  function ValidateDataFrontendPageTwo() {
    console.log("Click validate page 2");
    if (heading_post.length <= 0) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุหัวข้อประกาศ", "warning");
      return false;
    } else if (detail_post.length <= 0) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุรายละเอียด", "warning");
      return false;
    } else if (type_post.length === 0) {
      Swal.fire(
        "ข้อมูลไม่ครบ",
        "กรุณาเลือกประเภทโพสต์ (ขาย เช่า เซ้ง)",
        "warning"
      );
      return false;
    } else if (floor_house === null || floor_house <= 0) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "จำนวนชั้นของบ้าน", "warning");
      return false;
    } else if (area <= 0) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "เลขขนาดพื้นที่ไม่ถูกต้อง", "warning");
      return false;
    } else if (unit_area === null) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาเลือกประเภทหน่วยพื้นที่", "warning");
      return false;
    } else if (use_area <= 0) {
      Swal.fire(
        "ข้อมูลไม่ถูกต้อง",
        "เลขขนาดพื้นใช้สอยที่ไม่ถูกต้อง",
        "warning"
      );
      return false;
    } else if (use_unit_area === null) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาเลือกประเภทขนาดพื้นที่ใช้สอย", "warning");
      return false;
    } else if (bedroom === null) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาระบุจำนวนห้องนอน", "warning");
      return false;
    } else if (bathroom === null) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาระบุจำนวนห้องน้ำ", "warning");
      return false;
    } else if (sellTypeActive && price <= 0) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาระบุราคาขาย", "warning");
      return false;
    } else if (statusTypeRentActive && rent_price_ <= 0) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาระบุราคาเช่า", "warning");
      return false;
    } else if (statusTypeSellOutActive && sellOut_price <= 0) {
      Swal.fire("ข้อมูลไม่ครบ", "กรุณาระบุราคาเซ้ง", "warning");
      return false;
    }

    return true;
  }

  // Page 2

  // Page 3

  const ValidateDataFrontendPageThree = () => {
    if (owner_status === null) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุสถานะผู้ขาย", "warning");
      return false;
    } else if (first_hand === null) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุสถานะบ้าน", "warning");
      return false;
    } else if (furniture_included === null) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณาระบุเฟอร์นิเจอร์", "warning");
      return false;
    } else if (phone_number.length > 10) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "กรุณาระบุเบอร์โทรที่ถูกต้อง", "warning");
      return false;
    } else if (phone_number.length > 0 && phone_number.length < 10) {
      Swal.fire("ข้อมูลไม่ถูกต้อง", "กรุณาระบุเบอร์โทรที่ถูกต้อง", "warning");
    } else {
      return true;
    }
  };

  // Page 3

  // Page 4
  const ValidateDataFrontendPageFour = () => {
    if (asset_location_latitude === null) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณากรอกละติจูด", "warning");
      return false;
    } else if (asset_location_longitude === null) {
      Swal.fire("ข้อมูลไม่ครบถ้วน", "กรุณากรอกลองติจูด", "warning");
      return false;
    } else {
      return true;
    }
  };

  // Page 4

  // Validate Data Frontendx

  return (
    <div>

      <div className={isLoadingOldData == false ? "" : "hidden"}>
        {getToken() !== undefined ? (
          <div className="w-11/12  h-auto flex flex-col mt-2.5 mx-auto md:w-8/12">
            {/* Page Control */}
            {/* <div className="grid grid-cols-12 w-11/12 flex justify-center mx-auto h-12 gap-4 ">
                        <div
                            className={`col-span-4 w-full h-full rounded-full border mx-auto flex justify-center items-center ${pageNumber === 1 ? "bg-postperty-linear-light text-white cursor-not-allowed" : "cursor-pointer"}`} onClick={() => setPageByTabTop(1)}>
                            <span>อัพโหลดรูป</span>
                        </div>

                        <div
                            className={`col-span-4 w-full h-full rounded-full border mx-auto flex justify-center items-center ${pageNumber === 2 ? "bg-postperty-linear-light text-white cursor-pointer" : "cursor-pointer"}`} onClick={() => setPageByTabTop(2)}>
                            <span>ข้อมูลโพสต์</span>
                        </div>

                        <div
                            className={`col-span-4 w-full h-full rounded-full border mx-auto flex justify-center items-center ${pageNumber === 3 ? "bg-postperty-linear-light text-white cursor-pointer" : ""}`} onClick={() => setPageByTabTop(3)}>
                            <span>ภาพรวม</span>
                        </div>
                    </div>  */}

            <ul className="steps mt-8">
              <li
                className="step step-primary cursor-pointer"
                onClick={() => setPageByTabTop(1)}
              >
                อัพโหลดรูป
              </li>
              <li
                onClick={() => setPageByTabTop(2)}
                className={
                  pageNumber === 2
                    ? "step step-primary cursor-pointer"
                    : pageNumber === 3
                      ? "step step-primary cursor-pointer"
                      : pageNumber === 4
                        ? "step step-primary cursor-pointer"
                        : "step"
                }
              >
                ข้อมูลโพสต์
              </li>
              <li
                onClick={() => setPageByTabTop(3)}
                className={
                  pageNumber === 3
                    ? "step step-primary"
                    : pageNumber === 4
                      ? "step step-primary cursor-pointer"
                      : "step cursor-pointer"
                }
              >
                ภาพรวม
              </li>

              <li
                onClick={() => setPageByTabTop(4)}
                className={
                  pageNumber === 4
                    ? "step step-primary "
                    : "step  cursor-pointer"
                }
              >
                ตำแหน่ง
              </li>
            </ul>
            {/* Page Control */}
            {pageNumber === 1.5 ? (
              <ImagesUploadEdit
                images={images}
                upload={upload}
                setUpload={setUpload}
                setPageNumber={setPageNumber}
              />
            ) : null}
            {/* Start Page 1 */}
            {pageNumber === 1 ? (
              <div className="w-full mx-auto h-[1700px] flex flex-col mt-12 ">
                <div
                  className="h-56 bg-half-light-f5 max-h-96 w-11/12 rounded-xl mx-auto relative  md:h-96"
                  onClick={openImagesUploadEdits}
                >
                  {images.length > 0 ? (
                    <img
                      className="w-full h-full object-contain rounded-xl"
                      src={
                        images.length > 0
                          ? typeof images[0] === "string" //Check image is old data url from server is true user map image normally but is not create objectURL
                            ? images[0]
                            : URL.createObjectURL(images[0])
                          : ""
                      }
                    />
                  ) : null}
                  {/* <div className="w-full h-full absolute top-0 opacity-0 z-40">
                            <input
                                type="file"
                                name="images"
                                className="w-full h-full"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={handlePhotosRealEstate}
                                multiple
                            />
                        </div> */}
                  <h1 className="absolute inset-0 flex justify-center items-center z-0 text-xl opacity-25">
                    ใส่รูปภาพ
                  </h1>
                </div>

                <div className="w-11/12  mx-auto grid grid-cols-12 gap-2 mt-4 ">
                  {/* Front Deeds */}
                  <div className="h-56 bg-half-light-f5 max-h-96 w-full col-span-6 rounded-xl mx-auto relative   md:h-96">
                    {front_deeds?.length > 0 ? (
                      <img
                        className="w-full h-full object-contain rounded-xl"
                        src={
                          front_deeds.length > 0
                            ? typeof front_deeds[0] === "string"
                              ? front_deeds[0]
                              : URL.createObjectURL(front_deeds[0])
                            : ""
                        }
                      />
                    ) : null}
                    <div className="w-full h-full absolute top-0 opacity-0 z-40">
                      <input
                        type="file"
                        name="images"
                        className="w-full h-full"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleFrontDeedsEstate}
                        multiple
                      />
                    </div>
                    <h1 className="absolute inset-0 flex justify-center items-center z-0 text-l opacity-25 md:text-xl ">
                      โฉนดด้านหน้า
                    </h1>
                  </div>
                  {/* Front Deeds */}

                  {/* Back Deeds */}
                  <div className="h-56 bg-half-light-f5 max-h-96 w-full col-span-6 rounded-xl mx-auto relative  md:h-96">
                    {back_deeds.length > 0 ? (
                      <img
                        className="w-full h-full object-contain rounded-xl"
                        src={
                          back_deeds.length > 0
                            ? typeof back_deeds[0] === "string"
                              ? back_deeds[0]
                              : URL.createObjectURL(back_deeds[0])
                            : ""
                        }
                      />
                    ) : null}
                    <div className="w-full h-full absolute top-0 opacity-0 z-40">
                      <input
                        type="file"
                        name="images"
                        className="w-full h-full"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleBackDeedsEstate}
                        multiple
                      />
                    </div>
                    <h1 className="absolute inset-0 flex justify-center items-center z-0 text-l opacity-25 md:text-xl ">
                      โฉนดด้านหลัง
                    </h1>
                  </div>
                </div>

                <div className="w-11/12  mx-auto  rounded-full mt-2.5">
                  {/* YouTube URL */}
                  <div className="w-full h-12  rounded-full ">
                    <input
                      defaultValue={youtube_url}
                      onChange={inputValue("youtube_url")}
                      type="text"
                      placeholder="Youtube URL"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                  {/* YouTube URL */}

                  {/* VR_URL ที่รองรับ Matterport , Cloudpano , Kuula , Pano360  */}
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={vr_url}
                      onChange={inputValue("vr_url")}
                      type="text"
                      placeholder="VR_URL ที่รองรับ Matterport , Cloudpano , Kuula , Pano360"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                  {/* VR_URL ที่รองรับ Matterport , Cloudpano , Kuula , Pano360  */}
                </div>

                <div
                  className="w-11/12 mt-4 rounded-full mx-auto h-12 flex justify-center items-center bg-postperty-linear-light text-white cursor-pointer hover:opacity-90"
                  onClick={() => nextPage(1)}
                >
                  ถัดไป
                </div>
              </div>
            ) : null}
            {/* End Page 1 */}
            {/* Start Page 2 */}
            {pageNumber === 2 ? (
              <div className="w-11/12 mx-auto  flex flex-col mt-12 h-[1700px] ">
                {/*  Topic Post  */}
                <div className="w-full h-12  rounded-full mt-2.5 ">
                  <input
                    defaultValue={heading_post}
                    onChange={inputValue("heading_post")}
                    type="text"
                    placeholder="หัวข้อประกาศ"
                    className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                  ></input>
                </div>
                {/* Topic Post  */}

                {/*  Detail Post  */}
                <div className="w-full h-96  p-2     rounded-full mt-2.5 border  rounded-xl">
                  <h1 className="text-l my-2.5">
                    รายละเอียดประกาศ <span>{lengthOfDetailPost}/1800</span>
                    <span className="text-red-500">*</span>
                  </h1>
                  <textarea
                    defaultValue={detail_post}
                    onChange={(e) => {
                      // inputValue("detail_post");
                      handleDetailPostLimit(e);
                    }}
                    className="w-full h-80 rounded-xl outline-0 bg-half-light-f5 p-4"
                  />
                </div>
                {/* Detail Post  */}

                {/* Type Post */}
                <div className="w-full h-12 mt-2.5">
                  <div className="flex justify-around w-full h-full bg-half-light-f5 rounded-full p-4">
                    <Fragment>
                      <Checkbox
                        defaultChecked={sellTypeActive}
                        id="typePost_sell"
                        value={`ขาย`}
                        label="ขาย"
                        ripple={true}
                        onClick={() => handleTypePost("ขาย")}
                      />
                      <Checkbox
                        defaultChecked={statusTypeRentActive}
                        id="typePost_rent"
                        value={`เช่า`}
                        label="เช่า"
                        ripple={true}
                        onClick={() => handleTypePost("เช่า")}
                      />
                      <Checkbox
                        defaultChecked={statusTypeSellOutActive}
                        id="typePost_sellout"
                        value={`เซ้ง`}
                        label="เซ้ง"
                        ripple={true}
                        onClick={() => handleTypePost("เซ้ง")}
                      />
                    </Fragment>
                  </div>
                </div>

                {/* Type Post */}

                {/*  Floor  */}
                <div className="w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl">
                  <p>
                    จำนวนชั้นของบ้าน <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={floor_house}
                      onChange={inputValue("floor_house")}
                      type="number"
                      placeholder="จำนวนชั้นของบ้าน"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>
                {/* Floor */}

                {/*  Area  */}
                <div className="w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl">
                  <p>
                    ขนาดพื้นที่ <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={area}
                      onChange={inputValue("area")}
                      type="number"
                      placeholder="ขนาดพื้นที่"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>
                {/* Area */}

                {/* Unit Area */}
                <div className="w-full  h-auto ">
                  <div className="grid grid-cols-2">
                    <div
                      className={`${selectUnitArea === "ตารางเมตร"
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleUnitArea("ตารางเมตร")}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/1684/1684105.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">ตารางเมตร</h1>
                    </div>
                    <div
                      className={`${selectUnitArea === "ตารางวา"
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty text-white cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleUnitArea("ตารางวา")}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/3047/3047211.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">ตารางวา</h1>
                    </div>
                  </div>
                </div>

                {/* Unit Area */}

                {/*  use_area  */}
                <div className="w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl">
                  <p>
                    ขนาดพื้นที่ใช้สอย <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={use_area}
                      onChange={inputValue("use_area")}
                      type="text"
                      placeholder="ขนาดพื้นที่ใช้สอย"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>
                {/* use_area */}

                {/* Useable Unit Area */}
                <div className="w-full  h-auto ">
                  <div className="grid grid-cols-2">
                    <div
                      className={`${selectUseUnitArea === "ตารางเมตร"
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleUseAreaUnitArea("ตารางเมตร")}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/1684/1684105.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">ตารางเมตร</h1>
                    </div>
                    <div
                      className={`${selectUseUnitArea === "ตารางวา"
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty text-white cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleUseAreaUnitArea("ตารางวา")}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/3047/3047211.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">ตารางวา</h1>
                    </div>
                  </div>
                </div>

                {/* Useable Unit Area */}

                {/* Room */}
                <div className="w-full mx-auto h-auto flex flex-col md:flex-row rounded-full p-2 mt-2.5 rounded-xl">
                  <div className="w-full mt-2.5">
                    <select
                      onChange={handleBedRoom}
                      value={bedroom} // set the value from the state
                      className="w-full h-12 bg-half-light-f5 rounded-full text-center outline-0
                            "
                    >
                      <option key={0} value="">
                        เลือกจำนวนห้องนอน
                      </option>
                      <option key={1} value="1">
                        1 ห้องนอน
                      </option>
                      <option key={2} value="2">
                        2 ห้องนอน
                      </option>
                      <option key={3} value="3">
                        3 ห้องนอน
                      </option>
                      <option key={4} value="4">
                        4 ห้องนอน
                      </option>
                      <option key={5} value="5">
                        5 ห้องนอน
                      </option>
                    </select>
                  </div>

                  <div className="w-full mt-2.5">
                    <select
                      onChange={handleBathRoom}
                      value={bathroom}
                      className="w-full h-12 bg-half-light-f5 rounded-full text-center outline-0
                            "
                    >
                      <option key={0} value="">
                        เลือกจำนวนห้องน้ำ
                      </option>
                      <option key={1} value="1">
                        1 ห้องน้ำ
                      </option>
                      <option key={2} value="2">
                        2 ห้องน้ำ
                      </option>
                      <option key={3} value="3">
                        3 ห้องน้ำ
                      </option>
                      <option key={4} value="4">
                        4 ห้องน้ำ
                      </option>
                      <option key={5} value="5">
                        5 ห้องน้ำ
                      </option>
                    </select>
                  </div>
                </div>
                {/* Room */}

                {/* Price */}
                <div
                  className={
                    sellTypeActive
                      ? "w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl"
                      : "hidden"
                  }
                >
                  <p>
                    ราคาขาย <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={price}
                      value={price}
                      onChange={inputValuePrice("price")}
                      type="text"
                      placeholder="0.00"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>

                {/* Rent Price */}

                {/* Rent Price */}

                <div
                  className={
                    statusTypeRentActive
                      ? "w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl"
                      : "hidden"
                  }
                >
                  <p>
                    ราคาเช่า <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={rent_price_}
                      value={rent_price_}
                      onChange={inputValuePrice("rent_price_")}
                      type="text"
                      placeholder="0.00"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>

                {/* Rent Price */}

                {/* Rent Price */}

                <div
                  className={
                    statusTypeSellOutActive
                      ? "w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl"
                      : "hidden"
                  }
                >
                  <p>
                    ราคาเซ้ง <span className="text-red-500">*</span>
                  </p>
                  <div className="w-full h-12  rounded-full mt-2.5 ">
                    <input
                      defaultValue={sellOut_price}
                      value={sellOut_price}
                      onChange={inputValuePrice("sellOut_price")}
                      type="text"
                      placeholder="0.00"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>

                {/* Rent Price */}

                <div
                  className="w-full mt-4 rounded-full mx-auto h-12 flex justify-center items-center bg-postperty-linear-light text-white cursor-pointer hover:opacity-90"
                  onClick={() => nextPage(2)}
                >
                  ถัดไป
                </div>
              </div>
            ) : null}
            {pageNumber === 3 ? (
              <div className="w-11/12 mx-auto flex flex-col mt-12 h-[1700px] ">
                {/* Owner Status */}
                <div className="w-full  h-auto ">
                  <p>
                    สถานะผู้ขาย <span className="text-red-500">*</span>
                  </p>
                  <div className="grid grid-cols-2">
                    <div
                      className={`${selectOwnerStatus === "owner"
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleOwnerStatus("owner")}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/6676/6676705.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">เจ้าของขายเอง</h1>
                    </div>
                    <div
                      className={`${selectOwnerStatus === "agent"
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty text-white cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleOwnerStatus("agent")}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/2989/2989874.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">ตัวแทนขาย</h1>
                    </div>
                  </div>
                </div>

                {/* Owner Status */}

                {/* First Hand */}
                <div className="w-full mt-2.5 h-auto ">
                  <p>
                    สถานะบ้าน <span className="text-red-500">*</span>{" "}
                  </p>
                  <div className="grid grid-cols-2">
                    <div
                      className={`${isFirstHand === true
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleFirstHand(true)}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/1021/1021218.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">บ้านมือหนึ่ง</h1>
                    </div>
                    <div
                      className={`${isFirstHand === false
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty text-white cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleFirstHand(false)}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/1021/1021187.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">บ้านมือสอง</h1>
                    </div>
                  </div>
                </div>

                {/* First Hand */}

                {/* furniture_included */}
                <div className="w-full mx-auto mt-2.5  h-auto ">
                  <p>
                    เฟอร์นิเจอร์ <span className="text-red-500">*</span>
                  </p>
                  <div className="grid grid-cols-2">
                    <div
                      className={`${isFurniture === true
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty text-white cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleFurniture(true)}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/3458/3458061.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">มีเฟอร์นิเจอร์</h1>
                    </div>
                    <div
                      className={`${isFurniture === false
                          ? "rounded-full w-full h-11  mt-3 flex justify-center items-center bg-postperty text-white cursor-no-drop"
                          : "rounded-full w-full h-11 bg-half-light-f5 mt-3 flex justify-center items-center cursor-pointer"
                        }`}
                      onClick={() => handleFurniture(false)}
                    >
                      <img
                        src={`https://cdn-icons-png.flaticon.com/512/463/463612.png`}
                        className="w-6 h-6 "
                      />
                      <h1 className="ml-2">ไม่มีเฟอร์นิเจอร์</h1>
                    </div>
                  </div>
                </div>

                {/* furniture_included */}

                {/*  Phone Number  */}
                <div className="w-full h-12  rounded-full mt-2.5 ">
                  <input
                    defaultValue={phone_number}
                    onChange={inputValue("phone_number")}
                    type="text"
                    placeholder="เบอร์โทรศัพท์"
                    className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                  ></input>
                </div>
                {/* Phone Number  */}

                {/*  Phone Number  */}
                <div className="w-full h-12  rounded-full mt-2.5 ">
                  <input
                    defaultValue={line_id}
                    onChange={inputValue("line_id")}
                    type="text"
                    placeholder="Line ID"
                    className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                  ></input>
                </div>
                {/* Phone Number  */}

                {/* Excursive Option  */}
                <div className="w-full h-48 mt-2.5 ">
                  <p className="my-2.5">อื่นๆ</p>
                  <div className="flex flex-wrap  justify-start w-full h-auto bg-half-light-f5 rounded-2xl p-4">
                    <Fragment>
                      <Checkbox
                        id="swim-pool"
                        defaultChecked={ExcursiveSwim}
                        value={`สระว่ายน้ำ`}
                        label="สระว่ายน้ำ"
                        ripple={true}
                        onClick={() => handleExcursive_Option("สระว่ายน้ำ")}
                      />
                      <Checkbox
                        id="parking"
                        defaultChecked={ExcursiveParking}
                        value={`โรงจอดรถ`}
                        label="โรงจอดรถ"
                        ripple={true}
                        onClick={() => handleExcursive_Option("โรงจอดรถ")}
                      />
                      <Checkbox
                        id="central"
                        defaultChecked={ExcursiveCentral}
                        value={`ส่วนกลาง`}
                        label="ส่วนกลาง"
                        ripple={true}
                        onClick={() => handleExcursive_Option("ส่วนกลาง")}
                      />
                      <Checkbox
                        id="pets"
                        defaultChecked={ExcursivePets}
                        value={`เลี้ยงสัตว์เลี้ยงได้`}
                        label="เลี้ยงสัตว์เลี้ยงได้"
                        ripple={true}
                        onClick={() =>
                          handleExcursive_Option("เลี้ยงสัตว์เลี้ยงได้")
                        }
                      />
                    </Fragment>
                  </div>
                </div>

{/* Allow Comment Toggle Button */}
<div className="w-full mt-2.5 h-auto">
  <p>
    อนุญาตให้คอมเมนต์ <span className="text-red-500">*</span>
  </p>
  <div className="grid grid-cols-2">
    <div
      className={`${
        allowComment
          ? "rounded-full w-full h-11 mt-3 flex justify-center items-center bg-green-500 text-white cursor-no-drop"
          : "rounded-full w-full h-11 mt-3 flex justify-center items-center bg-half-light-f5 cursor-pointer"
      }`}
      onClick={() => toggleAllowComment(true)}
    >
      <img
        src="https://cdn-icons-png.flaticon.com/512/1828/1828817.png"
        className="w-6 h-6"
      />
      <h1 className="ml-2">อนุญาต</h1>
    </div>
    <div
      className={`${
        !allowComment
          ? "rounded-full w-full h-11 mt-3 flex justify-center items-center bg-red-500 text-white cursor-no-drop"
          : "rounded-full w-full h-11 mt-3 flex justify-center items-center bg-half-light-f5 cursor-pointer"
      }`}
      onClick={() => toggleAllowComment(false)}
    >
      <img
        src="https://cdn-icons-png.flaticon.com/512/1828/1828843.png"
        className="w-6 h-6"
      />
      <h1 className="ml-2">ไม่อนุญาต</h1>
    </div>
  </div>
</div>


                {/* Excursive Option */}

                <div
                  className="w-full mt-4 rounded-full mx-auto h-12 flex justify-center items-center bg-postperty-linear-light text-white cursor-pointer hover:opacity-90"
                  onClick={() => nextPage(3)}
                >
                  ยืนยัน
                </div>


                
              </div>
            ) : null}
            {pageNumber === 4 ? (
              <div className="w-full h-1500px">
                <div className="w-full h-500px md:h-700px mt-6 mx-auto  rounded">
                  <GetLatLngForUpload
                    setAssetLocationLatitude={setUpload}
                    setAssetLocationLongitude={setUpload}
                    asset_location_latitude={asset_location_latitude}
                    asset_location_longitude={asset_location_longitude}
                  />
                </div>

                <div className="w-full h-auto  p-2 rounded-full mt-2.5 rounded-xl">
                  <p>ละติจูด</p>
                  <div className="w-full h-12  rounded-full ">
                    <input
                      defaultValue={asset_location_latitude}
                      value={asset_location_latitude}
                      onChange={inputValue("asset_location_latitude")}
                      type="number"
                      placeholder="ละติดจูด"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>

                <div className="w-full h-auto  p-2 rounded-full  rounded-xl">
                  <p>ลองจิจูด</p>
                  <div className="w-full h-12  rounded-full  ">
                    <input
                      defaultValue={asset_location_longitude}
                      value={asset_location_longitude}
                      onChange={inputValue("asset_location_longitude")}
                      type="number"
                      placeholder="ลองจิจูด"
                      className="input w-full h-full outline-0 bg-half-light-f5 text-center  rounded-full"
                    ></input>
                  </div>
                </div>
                {isUpdating ? (
                  <div className="w-full h-auto flex flex-col mt-2.5 justify-center items-center mt-6 mb-2.5">
                    <DotLoader
                      color="#34bfd9"
                      className="w-full h-full"
                      loading={isUpdating}
                      size={60}
                    />
                  </div>
                ) : null}

                <div
                  className="w-full mt-4 rounded-full mx-auto h-12 flex justify-center items-center bg-postperty-linear-light text-white cursor-pointer hover:opacity-90"
                  onClick={updateDataAsset}
                >
                  {isUpdating ? (
                    <span>กำลังอัพเดท...</span>
                  ) : (
                    <span>ยืนยันการอัพเดท</span>
                  )}
                </div>
              </div>
            ) : null}
            {/* End Page 3 */}
          </div>
        ) : (
          <div>{window.location.replace("/login")}</div>
        )}
      </div>

      <div className={isLoadingOldData ? "flex justify-center items-center border border-red-500 h-screen" : "hidden"}>กำลังโหลดข้อมูล...</div>
    </div>
  );
};

export default EditHouse;

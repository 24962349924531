import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import FormatDateTime from "../home/GridLayoutDisplay/FormatDateTime";
import Cookies from "js-cookie";
import { MdDeleteOutline } from 'react-icons/md';
import Swal from "sweetalert2";
import { BiEdit } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyLeadAsset = () => {
  const getToken = () => {
    const session_id = Cookies.get("jwt");
    return session_id;
  };

  const location = useLocation();

  const [LeadAsset, setLeadAsset] = useState([]);
  const [filterTempLeadAsset, setFilterTempLeadAsset] = useState([]);
  const [selectFilterLead, setSelectFilterLead] = useState('pending');
  const [tempDisplayRemoveLeadID, settempDisplayRemoveLeadID] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState('');  // New state for selected type

  useEffect(() => {
    const key = `scrollPosition_${location.pathname}`;
    const storedScrollPositionString = localStorage.getItem(key) || '0';
    const storedScrollPosition = parseInt(storedScrollPositionString, 10);

    const handleScroll = () => {
      localStorage.setItem(key, window.scrollY.toString());
    };

    window.addEventListener('scroll', handleScroll);
    setTimeout(() => window.scrollTo(0, storedScrollPosition), 500);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  useEffect(() => {
    fetchData();
  }, [selectFilterLead, selectedType]);  // Re-fetch data whenever filter or type changes

  const fetchData = () => {
    axios.get(`${process.env.REACT_APP_API}/asset/myleads`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
      params: {
        status: selectFilterLead,
        type: selectedType  // Send selected type to backend
      }
    }).then((res) => {
      const assets = res.data.assets;
      setLeadAsset(assets);
      const filteredAssets = assets.filter(data => data.status === selectFilterLead);
      setFilterTempLeadAsset(filteredAssets);
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleRemoveMyAsset = (leadID) => {
    Swal.fire({
      title: 'ยืนยันการลบโพสต์?',
      text: "คุณต้องการลบโพสต์!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: '#000',
      confirmButtonText: 'ยืนยันการลบโพสต์'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${process.env.REACT_APP_API}/event/deleteLead`, {
          lead_id: leadID
        }, {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }).then(async () => {
          await Swal.fire(
            'สำเร็จ!',
            'ลบโพสต์สำเร็จ',
            'success'
          );
          settempDisplayRemoveLeadID(leadID);
          fetchData();
        }).catch((err) => {
          console.log(err);
        });
      }
    });
  };

  const formatedPhoneNumber = (phonenumber) => {
    if (phonenumber.length === 10) {
      return phonenumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return phonenumber;
  };

  const dataFilter = [
    { label: 'รออนุมัติ', status: 'pending' },
    { label: 'สำเร็จ', status: 'success' },
    { label: 'ไม่สำเร็จ', status: 'failed' },
  ];

  const propertyTypes = [
    { label: 'Townhouse', value: 'townhouse' },
    { label: 'Land', value: 'land' },
    { label: 'Condo', value: 'condo' },
    { label: 'House', value: 'house' },
    { label: 'Commercial', value: 'commercial' },
    { label: 'Other', value: 'other' },
  ];

  const handleFilterData = (label) => {
    setSelectFilterLead(label);
    const filteredData = LeadAsset.filter(data => data.status === label);
    setFilterTempLeadAsset(filteredData);
  };

  // Filter leads by date range
  const filterByDateRange = (assets) => {
    if (startDate && endDate) {
      return assets.filter(asset => {
        const assetDate = new Date(asset.date);
        return assetDate >= startDate && assetDate <= endDate;
      });
    }
    return assets;
  };

  return (
    <div className="bg-white lg:h-screen overflow-auto mt-6 w-3xl">
      <div className="mx-auto px-6 lg:px-8">
        <div className="max-w-7xl mx-auto bg-white">
          <div className="w-full">
            <h2 className="text-4xl font-semibold tracking-tight text-blue-700">Lead ของฉัน</h2>
            <span className="text-gray-600">จำนวน {filterTempLeadAsset.length === 0 ? LeadAsset.length : filterTempLeadAsset.length} โพสต์</span>
            
            {/* Date range picker */}
            <div className="flex space-x-4 mt-4">
              <div>
                <label className="block text-gray-700 font-semibold mb-2">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  isClearable
                  className="border border-gray-300 p-2 rounded-lg w-full"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  dateFormat="yyyy-MM-dd"
                  isClearable
                  className="border border-gray-300 p-2 rounded-lg w-full"
                />
              </div>
            </div>

            {/* Dropdown for Property Type */}
            <div className="mt-4">
              <label className="block text-gray-700 font-semibold mb-2">Property Type</label>
              <select
                value={selectedType}
                onChange={e => setSelectedType(e.target.value)}
                className="border border-gray-300 p-2 rounded-lg w-full"
              >
                <option value="">All</option>
                {propertyTypes.map((type, idx) => (
                  <option key={idx} value={type.value}>{type.label}</option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-12 gap-4 mt-4">
              {dataFilter.map((data, idx) => (
                <motion.div
                  key={idx}
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleFilterData(data.status)}
                  className={`cursor-pointer col-span-4 h-[40px] border rounded-full text-center flex justify-center items-center ${data.status === selectFilterLead ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                >
                  {data.label}
                </motion.div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            {filterByDateRange(filterTempLeadAsset).length > 0 ? filterByDateRange(filterTempLeadAsset).map((post) => (
              <motion.div
                key={post.lead_id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className={`${tempDisplayRemoveLeadID !== post.lead_id ? 'block' : 'hidden'} bg-white shadow-lg rounded-lg p-4`}
              >
                <div className="relative">
                  <Link to={`/home?card=full&aid=${post.lead_id}`}>
                    <img src={post.images_url[0][0]} className="w-full h-48 object-cover rounded-t-lg" alt="Post Image" />
                  </Link>
                  <MdDeleteOutline className="absolute top-2 right-2 text-red-600 bg-white p-1 w-8 h-8 rounded-full cursor-pointer" onClick={() => handleRemoveMyAsset(post.lead_id)} />
                  <Link to={`/edit/lead/${post.lead_id}`}>
                    <BiEdit className="absolute top-2 left-2 text-gray-800 bg-white p-1 w-8 h-8 rounded-full cursor-pointer" />
                  </Link>
                </div>

                <div className="mt-2 text-center">
                  <time className="text-gray-500">
                    {<FormatDateTime isoDateString={post.date} />}
                  </time>
                  <Link to={`/home?card=full&aid=${post.lead_id}`} className="block mt-2 text-blue-600 text-lg font-semibold">
                    สถานะ: {post.status}
                  </Link>
                  <p className="text-gray-600 mt-1">เบอร์โทรศัพท์: {formatedPhoneNumber(post.phone_number)}</p>
                </div>
              </motion.div>
            )) : (
              <h1 className="col-span-12 text-center text-gray-600">คุณยังไม่มี Lead</h1>
            )}
          </div>
        </div>
      </div>
      <div className="w-full h-32"></div>
    </div>
  );
};

export default MyLeadAsset;

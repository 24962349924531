import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const ResetPassword = () => {
  const [upload, setUpload] = useState({
    recover_credential: "",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [showRegisterButton, setShowRegisterButton] = useState(false);
  const { recover_credential } = upload;

  const inputValueFirstStep = (name) => (e) => {
    setUpload({ ...upload, [name]: e.target.value });
  };

  const [otpActive, setOtpActive] = useState(false);
  const [RefCode, setRefCode] = useState("");

  const [resetPasswordUpload, setResetPasswordUpload] = useState({
    otp: "",
    newpassword: "",
    confirmPassword: "",
    refCode: "",
  });

  const { otp, newpassword, confirmPassword, refCode } = resetPasswordUpload;
  const [usernameForAutoLogin, setUsernameForAutoLogin] = useState("");

  const inputValue = (name) => (e) => {
    setResetPasswordUpload({ ...resetPasswordUpload, [name]: e.target.value });
  };

  const handleSendOTP = () => {
    if (newpassword !== confirmPassword) {
      Swal.fire("Error", "Passwords do not match", "error");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API}/recover/sendOTP`, {
        recover_credential,
        newpassword
      })
      .then(async (res) => {
        const RefCode = res.data.RefCode;
        const username = res.data.uname;
        await Swal.fire("SMS Sent", res.data.Success, "success");
        setRefCode(RefCode);
        setOtpActive(true);
        setUsernameForAutoLogin(username);
        setPageNumber(pageNumber + 1);
      })
      .catch((err) => {
        const foundUser = err.response.data.FoundUser;
        if (!foundUser) {
          Swal.fire("", `${err.response.data.Error}`, "error");
          setShowRegisterButton(true);
        }
      });
  };

  useEffect(() => {
    setResetPasswordUpload({ refCode: RefCode });
  }, [RefCode]);

  const autoLogin = () => {
    const setCookies = async (token) => {
      await Cookies.set("jwt", token);
    };
    axios
      .post(
        `${process.env.REACT_APP_API}/auth/login`,
        {
          uname: usernameForAutoLogin,
          password: newpassword,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then(async (res) => {
        const token = res.data.token;
        await setCookies(token);
        window.location.replace("/profiles");
      })
      .catch((err) => {
        window.location.replace("/login");
      });
  };

  const handleChangePassword = () => {
    axios
      .post(`${process.env.REACT_APP_API}/recover/changePassword`, {
        otp: otp,
        newpassword: newpassword,
        refCode: RefCode,
      })
      .then((res) => {
        localStorage.setItem("194aa3da_password", newpassword);
        Swal.fire("Success", res.data.Success, "success");
        autoLogin();
      })
      .catch((err) => {
        Swal.fire("Failed", err.response.data.Error, "error");
      });
  };

  const handleReqOTPAgain = () => {
    setPageNumber(1);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
        {pageNumber === 1 && (
          <div>
            <div className="flex flex-col items-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/6159/6159364.png"
                alt="forgot_password_image"
                className="w-32 mb-4"
              />
              <h2 className="text-2xl font-semibold mb-2">ลืมรหัสผ่าน?</h2>
              <p className="text-gray-600 text-center mb-4">
                กรอกชื่อผู้ใช้ username หรือ เบอร์โทรศัพท์ที่ท่านเคยสมัครไว้. เราจะส่ง otp ไปหาเบอร์โทรศัพท์ของท่านเพื่อขอทำการรีเซ็ทรหัสผ่าน.
              </p>
              <input
                onChange={inputValueFirstStep("recover_credential")}
                type="text"
                placeholder="Phone or Username"
                className="w-full h-12 px-4 mb-4 border rounded-lg text-center"
              />
              <input
                onChange={inputValue("newpassword")}
                type="password"
                placeholder="รหัสผ่านใหม่"
                className="w-full h-12 px-4 mb-4 border rounded-lg text-center"
              />
              <input
                onChange={inputValue("confirmPassword")}
                type="password"
                placeholder="ยืนยันรหัสผ่านใหม่"
                className="w-full h-12 px-4 mb-4 border rounded-lg text-center"
              />
              <button
                onClick={handleSendOTP}
                className="w-full h-12 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                ต่อไป
              </button>
              {showRegisterButton && (
                <button className="w-full h-12 bg-yellow-800 text-white rounded-lg mt-4 hover:bg-yellow-600">
                  ไม่มีบัญชี? สมัครสมาชิก
                </button>
              )}
            </div>
          </div>
        )}

        {pageNumber === 2 && (
          <div>
            <div className="flex flex-col items-center">
              <img
                src="https://cdn-icons-png.flaticon.com/512/1817/1817646.png"
                alt="otp_image"
                className="w-32 mb-4"
              />
              <h2 className="text-2xl font-semibold mb-2">Verify OTP</h2>
              <p className="text-gray-600 text-center mb-4">
                เราได้ส่ง SMS OTP ไปที่เบอร์โทรศัพท์ของคุณแล้ว.
              </p>
              {otpActive && (
                <input
                  onChange={inputValue("otp")}
                  type="number"
                  placeholder={`Enter OTP (Ref: ${RefCode})`}
                  className="w-full h-12 px-4 mb-4 border rounded-lg text-center"
                />
              )}
              <button
                onClick={handleChangePassword}
                className="w-full h-12 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;

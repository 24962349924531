import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Settings = () => {
    const [locationAllowed, setLocationAllowed] = useState(false);

    const handleAllowLocationAccess = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocationAllowed(true);
                    Swal.fire(
                        "สำเร็จ!",
                        "การเข้าถึงตำแหน่งที่ตั้งได้รับอนุญาต",
                        "success"
                    );
                    console.log('Position:', position); // Optional: Log the position for debugging
                },
                (error) => {
                    let errorMessage = '';
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "การเข้าถึงตำแหน่งที่ตั้งถูกปฏิเสธโดยผู้ใช้ กรุณาดูยูทูปสอนเพื่อวิธีแก้ไขอนุญาตการเข้าถึงตำแหน่งของท่าน";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "ไม่สามารถระบุตำแหน่งได้";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "การขอข้อมูลตำแหน่งเกินระยะเวลาที่กำหนด";
                            break;
                        default:
                            errorMessage = "เกิดข้อผิดพลาดในการขอตำแหน่งที่ตั้ง";
                            break;
                    }
                    Swal.fire("เกิดข้อผิดพลาด!", errorMessage, "error");
                }
            );
        } else {
            Swal.fire(
                "ไม่รองรับ",
                "เบราว์เซอร์ของคุณไม่รองรับการเข้าถึงตำแหน่งที่ตั้ง",
                "error"
            );
        }
    };

    const MenuButton = ({ label, onClick, iconSrc, extra }) => (
        <motion.div
            className="bg-white border border-blue-200 rounded-xl p-4 flex flex-col items-center justify-center cursor-pointer shadow-sm hover:shadow-md transition-shadow"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
        >
            <img src={iconSrc} alt={label} className="w-12 h-12 mb-2" />
            <h1 className="text-center text-base font-medium text-blue-600">
                {label}
            </h1>
            {extra && <div className="mt-2">{extra}</div>}
        </motion.div>
    );

    return (
        <div className="min-h-screen bg-blue-50 p-4 flex flex-col items-center">
            <div className="w-full max-w-2xl">
                <h1 className="text-2xl font-semibold text-center text-blue-700 mb-6">
                    การตั้งค่าบัญชี
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <MenuButton
                        label="อนุญาตการเข้าถึงตำแหน่งที่ตั้ง"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/17539/17539781.png"
                        onClick={handleAllowLocationAccess}
                        extra={
                            <Link
                                to="https://youtu.be/Yv21fdWMr-g"
                                target="_blank"
                                className="text-md text-red-500 underline"
                            >
                                วิธีการอนุญาต
                            </Link>
                        }
                    />
                    <MenuButton
                        label="การตั้งค่าความเป็นส่วนตัว"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/7065/7065006.png"
                        onClick={() =>
                            Swal.fire(
                                "ยังไม่พร้อมใช้งาน",
                                "ฟังก์ชันนี้กำลังพัฒนา",
                                "info"
                            )
                        }
                    />
                    <MenuButton
                        label="การตั้งค่าความปลอดภัย"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/2592/2592317.png"
                        onClick={() =>
                            Swal.fire(
                                "ยังไม่พร้อมใช้งาน",
                                "ฟังก์ชันนี้กำลังพัฒนา",
                                "info"
                            )
                        }
                    />
                    <MenuButton
                        label="การตั้งค่าเพิ่มเติม"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/9195/9195918.png"
                        onClick={() =>
                            Swal.fire(
                                "ยังไม่พร้อมใช้งาน",
                                "ฟังก์ชันนี้กำลังพัฒนา",
                                "info"
                            )
                        }
                    />
                    <MenuButton
                        label="ย้อนกลับ"
                        iconSrc="https://cdn-icons-png.flaticon.com/512/2099/2099190.png"
                        onClick={() =>
                            window.location.href = "/profiles"
                        }
                    />
                    {/* Additional buttons can be added here in similar fashion */}
                </div>
            </div>
        </div>
    );
};

export default Settings;

import { HiArrowSmLeft } from "react-icons/hi"
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import {BsFillCheckCircleFill} from "react-icons/bs"
import { Link } from "react-router-dom";
import Snowfall from 'react-snowfall';

export default function NotificationFront() {
    const session_id = Cookies.get("jwt");
    const [dataMessageInbox, setDataMessageInbox] = useState([])
    // const [heldMessage, setHeldMessage] = useState(null);
    const [waitSelectedMessage , setWaitSelectedMessage] = useState(null)
    const [selectedMessageID, setSelectedMessageID] = useState([])
    function parseTimestamp(timestamp) {
        return new Date(timestamp);
    }

    // Function to format the date into DD-MM-YYYY
    function formatDateToDDMMYYYY(date) {
        const day = ("0" + date.getUTCDate()).slice(-2);
        const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
        const year = date.getUTCFullYear();
        return `${day}-${month}-${year}`;
    }

    function getInBoxMessage() {
        axios.get(`${process.env.REACT_APP_API}/inbox/getInbox`, {
            headers: {
                Authorization: `Bearer ${session_id}`
            }
        }).then((res) => {
            const message = res.data.Inbox
            console.log(res.data)
            setDataMessageInbox(message)
        }).catch((err) => {
            console.log(err);
        })
    }


    useEffect(() => {
        getInBoxMessage()
    }, [])

    useEffect(()=>{
        if(session_id === undefined){
            window.location.replace('/login')
        }
    },[])

    function handleClearAllMessageInbox() {
        axios.post(`${process.env.REACT_APP_API}/inbox/deleteMessage`,{
            "messageIds" : selectedMessageID
        } ,{
            headers: {
                Authorization: `Bearer ${session_id}`
            }
        }).then((res) => {
            console.log(res.data)
            getInBoxMessage()

        }).catch((err) => {
            console.log(err);
        })
    }

    function handleRemoveInboxNotification(){
        setWaitSelectedMessage(!waitSelectedMessage)
        setSelectedMessageID([])
    }

    function handleSelectedInbox(id) {
        console.log(id);
    
        if (selectedMessageID.includes(id)) {
    
            const updatedSelectedMessages = selectedMessageID.filter(messageId => messageId !== id);
            setSelectedMessageID(updatedSelectedMessages);
        } else {

            setSelectedMessageID([...selectedMessageID, id]); 
        }
    }
    
    function handleSaveIDMessage(idMessage) {
        sessionStorage.setItem("messageID", idMessage)
    }

    const topicHeading = `การแจ้งเตือน`

    return (
        <div className="w-full h-full flex flex-col md:w-8/12 md:mx-auto p-2">
          {/* <Snowfall snowflakeCount={200}/> */}

            <div className="h-[80px]">
                <div className="grid grid-cols-12 h-full ">

                    <div className="col-span-6  flex flex-col justify-center items-center   ">
                        <span className="text-2xl ml-5 w-full ">{`${topicHeading}`}</span>
                        {/* <p className="text-sm  ml-5 w-full ">{`คุณมีการแจ้งเตือน `+ `${dataMessageInbox.length} รายการ`}</p> */}
                        <p className="text-sm  ml-5 w-full ">คุณมีการแจ้งเตือน <span className="text-postperty font-medium">{`${dataMessageInbox.length} รายการ`}</span></p>


                    </div>
                    <div className="col-span-6  flex items-center justify-end ">
                        {/* <span className="text-sm tex    t-red-500" onClick={handleClearAllMessageInbox}>CLEAR</span> */}
                        <span className={waitSelectedMessage ? "text-sm text-red-500 mr-5" : 'hidden'} onClick={handleClearAllMessageInbox}>{waitSelectedMessage ? 'ลบ' : ''}</span>
                        <span className="text-sm font-bold cursor-pointer mr-5" onClick={handleRemoveInboxNotification}>{waitSelectedMessage ? 'ยกเลิก' : 'แก้ไข'}</span>

                        
                        {/* <img src="https://cdn-icons-png.flaticon.com/512/2931/2931512.png" className="w-16 h-" /> */}
                    </div>
                </div>


            </div>

            {dataMessageInbox.slice().reverse().map((data, index) => {
                const parsedDate = parseTimestamp(data.sent_date);
                const formattedDate = formatDateToDDMMYYYY(parsedDate);
                const readed = data.readed;
                

                const containerVariants = {
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                };

                const itemVariants = {
                    hidden: { opacity: 0, x: -20 },
                    visible: { opacity: 1, x: 0, transition: { type: "spring", duration: 1 } },
                };

                const itemTransition = {
                    delay: index * 0.2, // Delay each item by 0.2 seconds
                };

                return (
                    <motion.div
                    key={data.message_id}
                    // className={`relative w-full h-[100px] p-2.5 ${heldMessage === data.message_id ? '' : ''}`}
                    className={`relative w-full   border-b-[1px]    h-auto p-2.5 ${selectedMessageID.includes(data.message_id)? '' : ''}`}
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    onClick={()=>handleSelectedInbox(data.message_id)}
                    // onMouseDown={() => setHeldMessage(data.message_id)} // Set the held message ID
                    // onMouseUp={() => setHeldMessage(null)} // Clear the held message ID
                >
                    <Link to={waitSelectedMessage ? '' : `/noti/${data.message_id}`} onClick={() => handleSaveIDMessage(data.message_id)}>
                        <motion.div
                            className={selectedMessageID.includes(data.message_id) ? "w-full h-full ml-12 item " : "w-full h-full  " }
                            variants={itemVariants}
                            transition={itemTransition}
                        >
                            <span className="absolute top-2.5 right-5 text-gray-400">{formattedDate}</span>
                            <h1 className="font-bold text-l ">{data.title}</h1>
                            <p className="text-gray-400">{data.description.slice(0, 80)}</p>
                            <span className={readed ? "hidden" : "absolute top-1/2 tranform -translate-y-1/2 right-2 text-3xl font-bold text-red-500"}>
                                .
                            </span>
                        </motion.div>
                    </Link>

                    <div className={selectedMessageID.includes(data.message_id) ? "absolute top-1/2 tranform -translate-x-1/2  left-7 -translate-y-1/2 text-3xl text-postperty shadow-sm	" : 'hidden'}>
                    <BsFillCheckCircleFill/>
                    </div>
                    {/* <div className={selectedMessageID.includes(data.message_id) ? "hidden" : 'absolute top-1/2 tranform -translate-x-1/2  left-10 -translate-y-1/2 text-3xl text-green-500 shadow-sm'}>
                        <img src=""/>
                    </div> */}
                </motion.div>
                );
            })}







            {/* 
                <div className="w-full  h-[600px] border  border-red-500 rounded overflow-y-auto rounded">
                    {dataMessageInbox.map((data) => {
                        const parsedDate = parseTimestamp(data.sent_date);
                        const formattedDate = formatDateToDDMMYYYY(parsedDate);
                        return (
                            <div key={data.message_id} className="relative w-full h-[100px] border ">
                                <span className="absolute top-0 right-2 text-gray-400">{formattedDate}</span>
                                <h1 className="font-bold text-l">{data.title}</h1>
                                <p className="text-gray-400">{data.description}</p>
                            </div>
                        )
                    })}

                    





                </div> */}

            <div className="h-[200px] w-full"></div>


        </div>
    )
}
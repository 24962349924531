import React, { useEffect, useState } from 'react';

const BleDataDisplay = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if ('bluetooth' in navigator) {
          const device = await navigator.bluetooth.requestDevice({
            filters: [{ services: ['your-service-uuid'] }],
          });

          const server = await device.gatt.connect();
          const service = await server.getPrimaryService('your-service-uuid');
          const characteristic = await service.getCharacteristic('your-characteristic-uuid');

          characteristic.addEventListener('characteristicvaluechanged', handleData);

          await characteristic.startNotifications();
        } else {
          setError('Web Bluetooth not supported on this browser.');
        }
      } catch (error) {
        setError(`Error during connection: ${error.message}`);
      }
    };

    fetchData();

    return () => {
      // Disconnect or cleanup if needed
    };
  }, []);

  const handleData = (event) => {
    const receivedValue = event.target.value;
    const stringValue = new TextDecoder().decode(receivedValue);
    // Handle the received data as needed
  };

  return (
    <div>
      <h1>BLE Data Display</h1>
      {error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        /* Render your data or UI here */
        <p>Data will be displayed here.</p>
      )}
    </div>
  );
};

export default BleDataDisplay;

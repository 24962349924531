import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { CookiesProvider } from "react-cookie";
import NavigationModern from "./components/navigation/NavigationNew";
import ComplexNavbar from "./components/navigation/NavigationDesktop";

//Context

// Google Analytics
import ReactGA from "react-ga";

// Pages
import Home from "./components/pages/home/home";
import Contact from "./components/pages/contacts/contact";
import Profiles from "./components/pages/profiles/profiles";

// Edit
import EditProfile from "./components/pages/profiles/EditProfile";
import EditHouse from "./components/post/Edit/house/EditHouse";
import EditCondo from "./components/post/Edit/condo/EditCondo";
import EditCommercial from "./components/post/Edit/commercial/EditCommercial";
import EditLand from "./components/post/Edit/land/EditLand";

import EditLead from "./components/pages/profiles/EditLead";
//Auth
import RegisterNew from "./components/auth/RegisterNew";
import ResetPassword from "./components/auth/ResetPassword";

//Profiles
import InviteFriend from "./components/pages/profiles/InviteFriend";
import Point_commission from "./components/pages/profiles/commission/Point_commission";
import WithDrawMoney from "./components/pages/profiles/commission/WithDrawMoney";
import BanksChoose_PointCommission from "./components/pages/profiles/commission/Banks";
import HistoryCommission from "./components/pages/profiles/commission/HistoryCommission";
import MembersLayer from "./components/pages/profiles/membersLayer";
import MembersLayerData from "./components/pages/profiles/memberLayerDetail";
import MyPost from "./components/pages/profiles/MyPost";

//Product
import ViewProduct from "./components/pages/product/ViewProduct";

//Real Essate
import SelectPostType from "./components/post/SelectPostType/SelectPostType";
import SelectPostAssets from "./components/post/SelectPostType/SelectPostAssets";
import LeadAssets from "./components/pages/profiles/LeadAssets";
import CreateHouse from "./components/post/house/CreateHouse";
import CreateCondo from "./components/post/condo/CreateCondo";
import CreateLand from "./components/post/land/CreateLand";
import CreateCommercial from "./components/post/commercial/CreateComercial";
import CreateTownHouse from "./components/post/townhouse/CreateTownHouse";
import EditTownHouse from "./components/post/Edit/townhouse/EditTownHouse";
import CreateOtherPost from "./components/post/other/otherCreate";
//Other
import CartSystem from "./components/pages/cart/CartSystem";
import Logout from "./components/auth/Logout";
import JoinTeam from "./components/pages/contacts/JoinTeam";
import Mylead from "./components/pages/profiles/MyLead";
import MyShareAsset from "./components/pages/profiles/MyShareAssets";
import Settings from "./components/pages/profiles/settings";
import LandingPage from "./components/pages/index/LandingPage";

//Compare Assets
import HelpSellPostperty from "./components/post/postperty/HelpSell/HelpSellPostperty";

import STL2024 from "./STL";
// Notifications
import NotificationFront from "./components/pages/ืnotification/NotificationFront";
import DetailMessageInbox from "./components/pages/ืnotification/DetailMessageIbvox";
import BleDataDisplay from "./components/pages/home/GridLayoutDisplay/TestBLE";
import { LoginSystem } from "./components/auth/LoginSystem";
import TestMap from "./components/pages/home/GoogleMapAPI/TestMap";
import RealEstateCreatePosts from "./components/posts/class/RealEstateCreatePosts";
import AunityRedirect from "./components/pages/others/AunityRedirect";
//Context Controllers
export const MyContextMapAndGrid = createContext();
export const ControlStateGMapAndGrid = ({ children }) => {
  const [statusCloseMap, SetStatusCloseMap] = useState(false);
  const [statusCloseGrid, setStatusCloseGrid] = useState(false);
  const [statusHalfGridMap, setStatusHalfGridMap] = useState(true);
  const [isFullMapButtonOn, setIsFullMapButtonOn] = useState(false);
  const [isFullGridButtonOn, setIsFullGridButtonOn] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataSearchValue, setDataSearchValue] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  
  return (
    <MyContextMapAndGrid.Provider
      value={{
        statusCloseMap,
        SetStatusCloseMap,
        statusCloseGrid,
        setStatusCloseGrid,
        statusHalfGridMap,
        setStatusHalfGridMap,
        isFullMapButtonOn,
        setIsFullMapButtonOn,
        isFullGridButtonOn,
        setIsFullGridButtonOn,
        searchValue,
        setSearchValue,
        dataSearchValue,
        setDataSearchValue,
        dataDisplay,
        setDataDisplay,
      }}
    >
      {children}
    </MyContextMapAndGrid.Provider>
  );
};

export const GalleryContext = createContext();

export const GalleryProvider = ({children}) =>{
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isClickContactModalDetail ,setIsClickContactModalDetail] = useState(false)
  return(
    <GalleryContext.Provider value={{isGalleryOpen , setIsGalleryOpen }}>
      {children}
    </GalleryContext.Provider>
  )
}
function App() {
  // Google Analytics
  // Initialize Google Analytics
  ReactGA.initialize("G-NRRJP2GK4T");

  // This will log page views as users navigate through your app
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [showDesktop, setShowDesktop] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [hiddenNav, setHiddenNav] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 822) {
        setShowDesktop(true);
      } else {
        setShowDesktop(false);
      }

      if (window.innerWidth < 821) {
        setShowMobileNav(true);
      } else {
        setShowMobileNav(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        // User is scrolling down
        setHiddenNav(true);
      }
      if (currentScrollTop < lastScrollTop) {
        setHiddenNav(false);
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <div className="App">
      <ControlStateGMapAndGrid>
        <GalleryProvider>
        <CookiesProvider>
          <Router>
            {showMobileNav && !hiddenNav && (
              // <div className="animate-moveDownSmooth">
              <NavigationModern />
              // </div>
            )}
            {showDesktop && <ComplexNavbar />}
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/home/:id" element={<ViewProduct />} />
              <Route path="/ble" element={<BleDataDisplay />} />
              <Route path="/testmap" element={<TestMap />} />

              <Route path="/contact" element={<Contact />} />
              <Route path="/jointeam" element={<JoinTeam />} />

              <Route path="/product/:id" element={<ViewProduct />} />

              <Route path="/login" element={<LoginSystem />} />
              <Route path="/register" element={<RegisterNew />} />
              <Route path="/reset_password" element={<ResetPassword />} />

              {/* profile sections */}
              <Route path="/profiles" element={<Profiles />} />
              <Route path="/user/point" element={<Point_commission />} />
              <Route
                path="/user/point/bank"
                element={<BanksChoose_PointCommission />}
              />
              <Route path="/user/point/withdraw" element={<WithDrawMoney />} />
              <Route
                path="/user/point/history"
                element={<HistoryCommission />}
              />

              <Route path="/reference/invite" element={<InviteFriend />} />
              <Route path="/members/layer" element={<MembersLayer />} />
              <Route
                path="/members/layer/desc"
                element={<MembersLayerData />}
              />

              <Route path="/profile/mypost" element={<MyPost />} />
              <Route path="/profile/mylead" element={<Mylead />} />
              <Route path="/profile/myshare" element={<MyShareAsset />} />
              <Route path="/profile/settings" element={<Settings />} />

              <Route path="/editprofile" element={<EditProfile />} />
              <Route path="/cart" element={<CartSystem />} />

              {/* MatchPeople */}

              <Route path="/logout" element={<Logout />} />
              {/* <Route path="/edit/lead" */}
              <Route path="/edit/lead/:id" element={<EditLead />} />

              {/* Notification */}
              <Route path="/noti" element={<NotificationFront />} />
              <Route path="/noti/:messageID" element={<DetailMessageInbox />} />

              <Route path="/stl2024" element={<STL2024 />} />

              {/* Real Essate */}
              <Route
                path="/create/real_estate/:type"
                element={<RealEstateCreatePosts />}
              />

              <Route path="/create/" element={<SelectPostAssets />} />
              <Route path="/create/select" element={<SelectPostType />} />
              <Route path="/lead/assets/" element={<LeadAssets />} />
              <Route path="/create/house" element={<CreateHouse />} />
              <Route path="/edit/house/:id" element={<EditHouse />} />
              <Route path="/create/condo" element={<CreateCondo />} />
              <Route path="/edit/condo/:id" element={<EditCondo />} />
              <Route path="/create/commercial" element={<CreateCommercial />} />
              <Route path="/edit/commercial/:id" element={<EditCommercial />} />
              <Route path="/create/land" element={<CreateLand />} />
              <Route path="/edit/land/:id" element={<EditLand />} />
              <Route path="/create/townhouse" element={<CreateTownHouse />} />
              <Route path="/edit/townhouse/:id" element={<EditTownHouse />} />
              <Route path="/create/others" element={<CreateOtherPost />} />

              <Route path="/aunity/" element={<AunityRedirect />} />

              {/* Postperty */}
              <Route path="/help/postperty" element={<HelpSellPostperty />} />
            </Routes>
          </Router>
        </CookiesProvider>
        </GalleryProvider>
      </ControlStateGMapAndGrid>
    </div>
  );
}

export default App;

import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import { motion } from 'framer-motion';

const MembersLayer = () => {
  const [uName, setUName] = useState("");
  const [MembersLayerData, setMemberLayerData] = useState(null);
  const memberRefID = `postperty.co/register?ID=${uName}`;
  const token = Cookies.get("jwt");

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const { uname } = res.data;
        setUName(uname);
      })
      .catch((error) => console.error(error));
  }, [token]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/mlm/invited`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setMemberLayerData(res.data);
      })
      .catch((error) => console.error(error));
  }, [token]);

  const copyLinkInvite = async () => {
    await navigator.clipboard.writeText(memberRefID);
    Swal.fire("คัดลอกสำเร็จ", "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้", "success");
  };

  const MemberCard = ({ level, count }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="rounded-xl p-4 w-full max-w-md flex justify-between items-center mt-3 bg-white bg-opacity-60 backdrop-filter backdrop-blur-sm shadow-lg"
    >
      <Link to={`/members/layer/desc?data=${level}`} className="flex justify-between items-center w-full">
        <div className="flex items-center space-x-4">
          <div className="bg-blue-600 h-10 w-16 flex justify-center items-center rounded-full text-white font-bold">
            ชั้นที่ {level}
          </div>
          <h1 className="text-blue-900 font-semibold">
            {count !== 0 ? `สมาชิก ${count} คน` : <span className="text-red-400">ไม่มีสมาชิกในชั้นดังกล่าว</span>}
          </h1>
        </div>
        <img
          src="https://cdn-icons-png.flaticon.com/128/8050/8050813.png"
          alt="arrow"
          className="w-6 h-6"
        />
      </Link>
    </motion.div>
  );
  return (
    <motion.div
      className="relative flex flex-col w-full min-h-screen items-center bg-cover bg-center py-8"
      style={{ backgroundImage: `url('https://images.pexels.com/photos/618079/pexels-photo-618079.jpeg')` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="w-full max-w-4xl bg-white bg-opacity-50 shadow-md rounded-xl p-6 flex items-center justify-between mb-8">
        <Link to={`/profiles`} className="flex items-center space-x-2">
          <button className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md">
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              alt="backward"
              className="w-6 h-6 text-blue-600"
            />
          </button>
          <span className="text-blue-600 font-semibold">โปรไฟล์</span>
        </Link>
        <h1 className="text-blue-600 text-xl font-bold">สมาชิกของฉัน</h1>
        <div className="w-10 h-10"></div>
      </div>

      <div className="w-auto max-w-4xl bg-white bg-opacity-40 shadow-md rounded-xl p-6 mb-8 flex flex-col items-center backdrop-filter backdrop-blur-lg mx-auto">
        <h1 className="text-center font-medium text-blue-800">สมาชิกทั้งหมด</h1>
        <div className="w-36 h-24 bg-white-200 flex items-center justify-center text-5xl text-blue-800 mt-2 rounded-xl shadow-inner">
          <div className="flex items-center">
            <h1>{MembersLayerData ? MembersLayerData.Overall_Invited : '0'}</h1>
            <img
              src="https://cdn-icons-png.flaticon.com/128/1322/1322185.png"
              alt="icon"
              className="w-8 h-8 ml-2"
            />
          </div>
        </div>
      </div>

      <div className="w-full max-w-4xl flex flex-col items-center">
        {Array.from({ length: 10 }, (_, i) => (
          MembersLayerData && MembersLayerData.TotalLayer[`L${i + 1}`] !== undefined && (
            <MemberCard
              key={i + 1}
              level={i + 1}
              count={MembersLayerData.TotalLayer[`L${i + 1}`]}
            />
          )
        ))}
      </div>

      <button
        onClick={copyLinkInvite}
        className="mt-5 bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition duration-300"
      >
        คัดลอกลิงค์เชิญ
      </button>
    </motion.div>
  );
};

export default MembersLayer;

import axios from "axios";
import { GrClose } from "react-icons/gr"
const ImagesUpload = ({ images, upload, setUpload, setPageNumber }) => {
    const path = window.location.pathname
    const handlePhotosRealEstate = async (e) => {
        const files = e.target.files;
        const newImages = Array.from(files);
        handleGetPhoneNumberOCR(newImages)
        console.log(newImages)
        await setUpload({ ...upload, images: [...upload.images, ...newImages] });
    };
    const handleGetPhoneNumberOCR = (file) => {
        axios
            .post(`https://ocr.postperty.com/extract_phone_numbers`, {
                file: file,
            }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => {
                console.log(res.data)
                const phone_num = res.data.phone_numbers
                sessionStorage.setItem("phoneNumOCR", phone_num)
                // Set the input value using ID
                document.getElementById('phoneNumberInput').value = phone_num.replaceAll("-", "");
            })
            .catch((err) => {
                console.log(err)
            })
    }
    
        // State นี้ทำหน้าที่ให้ข้อมูลว่า url path ไหนจะใช้ state removeImgUrl *เพื่อที่ระบุ url ที่ต้องการลบส่งกลับไป server
    const StoreImgUrlPathForRemoveImgPutToServer = ["/edit/lead/"]


    const removeImage = async (index) => {
        const newImages = images.filter((image, idx) => idx !== index);
    
        let removingImage = '';
        if (StoreImgUrlPathForRemoveImgPutToServer.some((storeImageRemoving) => path.startsWith(storeImageRemoving))) {
            removingImage = images.find((_, idx) => idx === index)?.toString();
    
            // Add removingImage to removeimg array
            setUpload({ ...upload, images: newImages, removeimg: [...upload.removeimg, removingImage] });
        } else {
            // Condition not met, do not modify removeimg
            setUpload({ ...upload, images: newImages });
        }
    }
    
    

    const backtoMainPageOnce = () => {
        setPageNumber(1);
        scrollToTop();
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Define the paths where you want to hide the div
    const hidePaths = ["/lead/assets/", "/help/postperty", "/edit/lead/"];

    // Check if the current path is in the hidePaths array
    const isHidden = hidePaths.some((hidePath) => path.startsWith(hidePath));


    return (
        <div className="h-auto">
            <div className="relative h-56 bg-half-light-f5 max-h-96 w-11/12 rounded-xl mx-auto  md:h-96">
                {images.length > 0 ? <img
                    className="w-full h-full object-contain rounded-xl "
                    src={
                        images.length > 0
                                ? typeof images[0] === "string" //Check image is old data url from server is true u ser map image normally but is not create objectURL
                          ? images[0]
                          : URL.createObjectURL(images[0])
                        : ""
                    }
                /> : null}
                <div className="w-full h-full absolute top-0 opacity-0 z-40">
                    <input
                        type="file"
                        name="images"
                        className="w-full h-full"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handlePhotosRealEstate}
                        multiple
                    />
                </div>
                <h1 className="absolute inset-0 flex justify-center items-center z-0 text-xl text-black bg">
                    <p className="bg-white opacity-80 rounded-full p-2 w-36 text-center" >ใส่รูปภาพ</p>
                </h1>

            </div>


            {/* Image after */}
            {images.map((image, idx) => (
                <div key={idx} className="relative w-11/12 h-auto mx-auto mt-2.5" >
                    <span className="absolute left-2 bottom-2 backdrop-blur-sm text-black font-thin p-1.5 rounded-full   ">{`รูปภาพที่ ${idx+1}`}</span>
                    <img
                        className="w-full h-full object-contain rounded-xl"
                        src={
                            images.length > 0
                            ? typeof images[idx] === "string" //Check image is old data url from server is true user map image normally but is not create objectURL
                            ? images[idx]
                            : URL.createObjectURL(images[idx])
                          : ""
                        } />
                    <button className="absolute top-2 right-5" onClick={() => removeImage(idx)}><GrClose className="w-8 h-8 p-2  rounded-full bg-white text-white" /></button>
                </div>
            ))}
            <div className={isHidden ? 'hidden' : 'w-full h-[200px]'}>
                <div className="bg-postperty w-11/12 h-12 border mx-auto rounded-full flex justify-center items-center mt-6 cursor-pointer" onClick={backtoMainPageOnce}>
                    ยืนยัน
                </div>
            </div>
        </div>
    )
}

export default ImagesUpload

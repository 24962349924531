import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const cookie = Cookies.get("jwt");
const token = `${cookie}`;

const WithDrawMoney = () => {
  

  const [points, setPoints] = useState(null);
  const [withDrawData , setWithDrawData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/banking/cashoutData`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data
        const {Fullbalance} = data;
        setPoints(Fullbalance);
        setWithDrawData(data);

      })
      .catch(function (error) {
      });
  }, []);

    useEffect(()=>{
      if(withDrawData.length = 0){
        return null;
      }
    },[withDrawData])  


    //SecreateKey is not rea.  trick Hacker
    const secretKeyFake = "E3QO4ohOPBu9KiVbG90lkTQqMTtB4W4FkC3Mv6AtYYFPHOCwmKBojw7zCjS84I5Y"
    function confirmWithDraw(){
      axios.post(`${process.env.REACT_APP_API}/banking/withdraw`,{withDraw: secretKeyFake},{headers:{
        Authorization:`Bearer ${token}`
      }}).then((res)=>{
        Swal.fire(`สำเร็จ` , `รอการตรวจสอบภายใน 24 - 48 ชั่วโมง` ,"success")
      }).catch((err)=>{
        console.log(err)
        const errMsg = err.response.data.Error;
        Swal.fire("ไม่สำเร็จ" , `${errMsg}` , "error")
      })
    }

    function deniedWithDraw(){
      Swal.fire("ยอดเงินขั้นต่ำ" ,"ยอดเงินขั้นต่ำที่ถอนได้ 1,000บาท" , "warning")
    }

  return (
    <div className="flex flex-col w-full h-screen bg-postperty-linear-light items-center">
      <div className="w-96 mt-16 md:w-full">
        <div className="w-96 h-9  relative flex">
          <button
            className="bg-white w-9  h-6  absolute left-9 top top-1 rounded-3xl  "
            onClick={()=>{
              window.location.replace('/user/point')
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271220.png"
              alt="backword"
              className="w-4 h-4  m-auto"
            ></img>
          </button>
          <h1 className="text-white w-full text-center mt-auto mb-auto text-xl">
            ถอนเงิน
          </h1>
        </div>
      </div>

      <div className="bg-half-light w-80 h-28 rounded-2xl mt-14 flex flex-col  md:w-3/5">
        <p className="text-lg text-center w-full mt-4">ยอดเงินสะสมปัจจุบัน</p>
        <h1 className="text-important-green text-4xl font-medium w-full text-center h-14 mt-2">
          {`${points}`} <span className="text-4xl text-black">฿</span>{" "}
          
        </h1>
        
      </div>

      <div className="w-96 h-2/3 bg-half-light rounded-t-3xl mt-20 md:w-3/5">
        <div className="border w-11/12 h-10/12 mx-auto mt-6 rounded-xl shadow text-bold">
        <div className="w-10/12 mx-auto mt-6 h-8 flex justify-between">
          <p>ค่าคอมมิชชั่นก่อนหักภาษี</p>
          <p>{withDrawData.Fullbalance} บาท</p>
        </div>

        <div className=" w-10/12 mx-auto mt-2.5 h-8 flex justify-between">
          <p>มูลค่าหัก ณ ที่จ่าย (3%)</p>
          <p>-{withDrawData.withholdingtaxcost} บาท</p>
        </div>


        <div className=" w-10/12 mx-auto mt-2.5 h-8 flex justify-between">
          <p>ค่าธรรมเนียม</p>
          <p>{withDrawData.feesCost} บาท</p>
        </div>

        <div className=" w-10/12 mx-auto mt-2.5 h-8 flex justify-between border-b-2 mb-2">
          <p className="text-xl">เงินเข้าสุทธิ</p>
          <p className="text-blue-500 text-xl  ">{withDrawData.Cashout} บาท</p>
        </div>
        </div>
        <div className={points < 1000 ? " cursor-pointer rounded-full text-center w-10/12 mx-auto h-12 bg-gray-500 flex justify-center items-center text-white mt-6" : "hidden"} onClick={deniedWithDraw}>ยืนยันการถอนเงิน</div>
        <div className={points >= 1000 ? " cursor-pointer rounded-full text-center w-10/12 mx-auto h-12 bg-postperty-linear-light flex justify-center items-center text-white mt-6" : "hidden"} onClick={confirmWithDraw}>ยืนยันการถอนเงิน</div>
      </div>
      {/* <div className=" h-12 w-10/12">test</div> */}
    </div>
  );
};

export default WithDrawMoney;

import React from 'react';

const FormatPrice = ({ number , suffix }) => {

  const formatNumberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <span>
      {formatNumberWithCommas(number + " " + suffix)} 
    </span>
  );
};

export default FormatPrice;

import { Component } from "react";

class ScrollToTop extends Component {
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
}

// Format a given date object into the desired timestamp format
export const formatTimestamp = (date) => {
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(date);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return `${formattedDate}, ${formattedTime}`;
};

export const formatPhoneNumber = (number) => {
  // Convert to string to handle cases where the input is a number
  const strNumber = String(number);

  // Check if the length of the string is not 10, return original string
  if(strNumber.length !== 10) return strNumber;
  
  // Construct the formatted string
  return `${strNumber.slice(0, 3)}-${strNumber.slice(3, 6)}-${strNumber.slice(6, 10)}`;
}





export default ScrollToTop;
export const libFunction = new ScrollToTop();

import React, { useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from "js-cookie";
import { motion } from 'framer-motion';

const ReportModal = ({ isOpen, onRequestClose, assetId }) => {
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const predefinedReasons = [
    "ผู้ขายไม่นัดรับหมายดูทรัพย์",
    "ไม่สามารถติดต่อผู้ขายได้",
    "ผู้ขายแจ้งว่าได้ขายไปแล้ว",
    "เนื้อหาไม่เหมาะสม",
    "พิกัดตำแหน่งไม่ตรง",
    "ละเมิดนโยบายการใช้งาน"
  ];

  const toggleReason = (reason) => {
    if (selectedReasons.includes(reason)) {
      setSelectedReasons(selectedReasons.filter(item => item !== reason));
    } else {
      setSelectedReasons([...selectedReasons, reason]);
    }
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  const handleSubmit = async () => {
    const jwt = Cookies.get("jwt");
    if (!jwt) {
      Swal.fire("", "กรุณาเข้าสู่ระบบก่อนทำการรายงาน", "warning");
      onRequestClose();
      return;
    }

    if (isOtherSelected && !otherReason) {
      Swal.fire("", "กรุณาระบุเหตุผลในช่องอื่นๆ", "warning");
      return;
    }

    if (isOtherSelected && otherReason) {
      selectedReasons.push(`อื่นๆ: ${otherReason}`);
    }

    const body = {
      reason: selectedReasons,
      assetId: assetId,
    };

    try {
      await axios.post('https://api.postperty.com/api/report/asset', body, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      Swal.fire("Success", "รายงานของคุณถูกส่งเรียบร้อยแล้ว", "success");
      onRequestClose();
    } catch (error) {
      Swal.fire("Error", "ไม่สามารถส่งรายงานได้", "error");
      console.error(error);
    }
  };

  // Define the animation variants for the modal
  const modalVariants = {
    hidden: { opacity: 0, y: "-50%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "-50%" },
  };

  // Define the animation for the shake effect
  const shakeAnimation = {
    scale: [1, 1.05, 1],
    rotate: [0, 1, -1, 0],
    transition: { duration: 0.2 },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Report Modal"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <motion.div
        className="bg-white p-4 rounded-lg w-full max-w-md"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <h2 className="text-4xl font-bold mb-4">รายงานทรัพย์</h2>

        <div className="mb-4">
          {predefinedReasons.map((reason, index) => (
            <motion.div
              key={index}
              className="flex items-center text-xl mb-3"
              whileTap={shakeAnimation} // Apply shake animation on tap
            >
              <input
                type="checkbox"
                id={`reason-${index}`}
                checked={selectedReasons.includes(reason)}
                onChange={() => toggleReason(reason)}
                className="mr-2"
              />
              <label htmlFor={`reason-${index}`} className="text-gray-700">
                {reason}
              </label>
            </motion.div>
          ))}

          <motion.div
            className="flex items-center text-xl mb-3"
            whileTap={shakeAnimation} // Apply shake animation on tap
          >
            <input
              type="checkbox"
              id="reason-other"
              checked={isOtherSelected}
              onChange={() => {
                setIsOtherSelected(!isOtherSelected);
                if (!isOtherSelected) setOtherReason(''); // Reset other reason if unchecked
              }}
              className="mr-2"
            />
            <label htmlFor="reason-other" className="text-gray-700">
              อื่นๆ
            </label>
          </motion.div>

          {isOtherSelected && (
            <textarea
              className="w-full p-2 border rounded-lg"
              placeholder="โปรดระบุเหตุผล (ไม่เกิน 128 ตัวอักษร)"
              value={otherReason}
              onChange={handleOtherReasonChange}
              maxLength="128"
            />
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2"
          >
            ส่งรายงาน
          </button>
          <button
            onClick={onRequestClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
          >
            ยกเลิก
          </button>
        </div>
      </motion.div>
    </Modal>
  );
};

export default ReportModal;

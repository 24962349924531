import { useState } from "react";
import shrimp from "./shrimp.jpg";
import weight from "./weightscale.png";
import axios from "axios";
import Swal from "sweetalert2";

export default function STL2024() {
    const [statusMotor, setStatusMotor] = useState(false);
    const [selectedShrimp, setSelectedShrimp] = useState("ก้ามกราม");

    function controlMotor() {
        if (statusMotor) {
            axios.get("https://onsite.postperty.com/motor_off")
                .then((res) => {
                    Swal.fire("ปิด Motor เรียบร้อย", '', "success");
                })
                .catch((err) => {
                    Swal.fire("เกิดข้อผิดพลาด", '', 'error');
                });
            setStatusMotor(false);
        } else {
            axios.get("https://onsite.postperty.com/motor_on")
                .then((res) => {
                    Swal.fire("เปิด Motor เรียบร้อย", '', "success");
                })
                .catch((err) => {
                    Swal.fire("เกิดข้อผิดพลาด", '', 'error');
                });
            setStatusMotor(true);
        }
    }

    function handleShrimpSelection(type) {
        setSelectedShrimp(type);
    }

    return (
        <main className="w-full h-screen">
            <div className="relative w-10/12 mx-auto h-30 mt-16">
                <div className="w-max h-full">
                    <h1 className="text-2xl font-bold">รายการจำนวนบ่อกุ้ง</h1>
                    <p className="font-medium">จำนวนบ่อกุ้ง 1 รายการ</p>
                </div>
                <div className="absolute right-2 bottom-3">
                    <img className="w-10 h-10" src="https://cdn-icons-png.flaticon.com/512/1827/1827295.png" alt="notify" />
                </div>
            </div>

            <div className="grid grid-cols-12 w-10/12 gap-x-2.5 mx-auto h-14 mt-8">
                <div className="relative flex items-center bg-half-light-f5 col-span-10 w-full h-full border rounded-xl">
                    <img className="w-5 h-5 ml-4" src="https://cdn-icons-png.flaticon.com/512/149/149852.png" />
                    <p className="ml-2.5">ค้นหา</p>
                </div>
                <div className="col-span-2 bg-yellow-800 rounded-xl flex justify-center items-center w-full h-full">
                    <img className="w-6 h-6" src="https://cdn-icons-png.flaticon.com/512/7094/7094575.png" />
                </div>
            </div>

            <div className="w-10/12 h-auto mt-8 mx-auto">
                <h1 className="font-medium text-xl">ประเภทกุ้ง</h1>
                <div className="grid grid-cols-3 gap-x-2.5 mt-2.5">
                    <div
                        className={`w-full h-10 flex justify-center items-center rounded-xl font-bold cursor-pointer ${selectedShrimp === 'ก้ามกราม' ? 'bg-yellow-800 text-white' : 'bg-half-light-f5'}`}
                        onClick={() => handleShrimpSelection("ก้ามกราม")}
                    >
                        ก้ามกราม
                    </div>
                    <div
                        className={`w-full h-10 flex justify-center items-center rounded-xl font-bold cursor-pointer ${selectedShrimp === 'มังกร' ? 'bg-yellow-800 text-white' : 'bg-half-light-f5'}`}
                        onClick={() => handleShrimpSelection("มังกร")}
                    >
                        มังกร
                    </div>
                    <div
                        className={`w-full h-10 flex justify-center items-center rounded-xl font-bold cursor-pointer ${selectedShrimp === 'กุ้งขาว' ? 'bg-yellow-800 text-white' : 'bg-half-light-f5'}`}
                        onClick={() => handleShrimpSelection("กุ้งขาว")}
                    >
                        กุ้งขาว
                    </div>
                </div>
            </div>

            <div className={selectedShrimp == "ก้ามกราม" ? "w-10/12 h-auto mx-auto mt-4" : "hidden"}>
                <div className="relative w-full h-52">
                    <img src={shrimp} className="w-full h-full rounded-l" />
                    <div className="w-14 h-14 flex justify-center items-center bg-white rounded-full absolute bottom-5 left-5 z-50 border" onClick={() => controlMotor()}>
                        <img className="w-8 h-8" src="https://cdn-icons-png.flaticon.com/512/159/159607.png" />
                    </div>
                    <div className={`absolute w-36 text-white font-bold bottom-5 left-9 rounded-full ${statusMotor ? "bg-green-500" : "bg-black"}`}>
                        <p className="w-full h-full text-center ml-4">{statusMotor ? "กำลังทำงาน" : "ไม่ได้ทำงาน"}</p>
                    </div>
                </div>

                <div className="w-full h-16">
                    <p className="font-medium text-xl">บ่อเลี้ยงกุ้งที่ 1</p>
                    <p className="font-medium text-xl">สถานที่ : นครปฐม กำแพงแสน</p>
                </div>

                <div className="grid grid-cols-2">
                    <div className="flex">
                        <img className="w-12 h-12" src="https://cdn-icons-png.flaticon.com/512/4648/4648291.png" />
                        <div className="flex justify-center items-center w-full h-full">
                            <p>8.2</p>
                            <p className="text-yellow-700 ml-2.5">(WARNING)</p>
                        </div>
                    </div>

                    <div className="flex">
                        <img className="w-12 h-12" src={weight} />
                        <div className="flex justify-center items-center w-full h-full">
                            <p>สารคงเหลือ 3 KG</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

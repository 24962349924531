import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import QRCode from "qrcode.react";
import localforage from "localforage";

const Profiles = () => {
  const [profileData, setProfileData] = useState({
    uname: "",
    first_name: "",
    last_name: "",
    profile_image: "",
    phone_number: "",
    point: 0,
  });
  const [uName, setUName] = useState("");
  const [dataDisplayMyPosts, setDataDisplayMyPosts] = useState([]);
  const qrCodeCanvasRef = useRef(null);
  const inviteLink = `postperty.com/register?ID=${uName}`;
  const cookie = Cookies.get("jwt");

  const getToken = () => cookie;

  const handleFileUpload = async (event) => {
    const ImageUpload = event.target.files[0];
    const formData = new FormData();
    formData.append("image", ImageUpload);

    try {
      await axios.put(
        `${process.env.REACT_APP_API}/update/profile-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire("สำเร็จ!", "เปลี่ยนรูปโปรไฟล์สำเร็จ", "success");
      setTimeout(() => window.location.reload(), 2000);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/getDataProfile`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      setProfileData(response.data);
      setUName(response.data.uname);
      localStorage.setItem("profile_user", response.data.profile_image);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMyPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/asset/myassets`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setDataDisplayMyPosts(response.data.assets);
    } catch (error) {
      console.error(error);
    }
  };

  const askLogout = () => {
    Swal.fire({
      title: "ต้องการออกจากระบบ?",
      text: "คุณจะถูกออกจากระบบและไม่สามารถใช้งานฟังก์ชันบางอย่างได้",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0000",
      cancelButtonColor: "#000",
      confirmButtonText: "ยืนยันการออกจากระบบ",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await localforage.removeItem("uploadData");
          Swal.fire("สำเร็จ!", "ออกจากระบบเรียบร้อย", "success");
          Cookies.remove("jwt");
          localStorage.removeItem("token");
          window.location.reload();
        } catch (err) {
          console.error("Error during logout", err);
        }
      }
    });
  };

  useEffect(() => {
    if (!cookie) window.location.replace("/login");
    fetchProfileData();
    fetchMyPosts();
  }, [cookie]);

  useEffect(() => {
    const checkJwtValidity = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/event/checkValidToken`,
          { token: cookie },
          { headers: { Authorization: `Bearer ${cookie}` } }
        );
        if (response.data.relogin) {
          Cookies.set("jwt", response.data.newjwt);
        }
      } catch (err) {
        if (err.response?.data?.success === false) {
          Cookies.remove("jwt");
          window.location.replace("/login");
        }
      }
    };

    checkJwtValidity();
  }, [cookie]);

  const formatPhoneNumber = (number) => {
    if (!number) return "";
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };

  const copyLinkInvite = async () => {
    await navigator.clipboard.writeText(inviteLink);
    Swal.fire("คัดลอกแล้ว", "ลิงก์แนะนำถูกคัดลอกไปยังคลิปบอร์ด", "success");
  };

  const SidebarItem = ({ to, iconSrc, label }) => (
    <Link to={to}>
      <div className="border rounded-xl mb-4 h-14 flex justify-between items-center px-4 cursor-pointer">
        <img src={iconSrc} alt={label} className="w-7 h-7" />
        <h1 className="text-center w-full">{label}</h1>
        <img
          src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
          alt="arrow"
          className="w-3 h-3"
        />
      </div>
    </Link>
  );

  return (
    <div className="">
      {getToken() !== undefined ? (
        <div className="md:grid md:grid-cols-12 h-[1050px] md:h-[890px]">
          <div className="w-full md:col-span-12 lg:col-span-4 overflow-y-auto h-[1050px] bg-white md:mb-4 md:h-[890px] border-r-2">
            <div className="h-auto mb-4">
              <div className="text-center w-80 mb-4 mr-auto ml-auto mt-16 text-xl">
                <div className="flex justify-between">
                  <h1 className="text-[18px]">โปรไฟล์ของฉัน</h1>
                  <Link to={`/editprofile`}>
                    <h1 className="text-[18px] rounded-full w-24 shadow-xl">
                      แก้ไข
                    </h1>
                  </Link>
                </div>
                <div className="my-12 h-28 flex justify-around">
                  <div className="relative" onClick={handleFileUpload}>
                    <form>
                      <input
                        type="file"
                        accept="image/*"
                        className="border w-full h-full opacity-0 top-0 absolute right-0"
                        onChange={handleFileUpload}
                      />
                      <img
                        src={`${profileData.profile_image}`}
                        alt="img"
                        className="w-28 h-28 rounded-full"
                      />
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/44/44413.png"
                        alt="edit-photo-icon"
                        className="w-6 h-6 absolute bottom-0 right-3 bg-white shadow-xl rounded-full p-1"
                      />
                    </form>
                  </div>
                  <div className="flex flex-col w-30">
                    <div className="mt-auto mb-auto relative">
                      <h1 className="text-start">
                        {profileData.first_name + " " + profileData.last_name}
                      </h1>
                      <p className="text-sm text-start">
                        <div className="relative w-full rounded-xl h-20 mt-2.5 bg-half-light-f5 mx-auto">
                          <p className="text-sm w-full text-center absolute left-1/2 transform -translate-x-1/2 top-2">
                            User ID ของคุณคือ
                          </p>
                          <h1 className="absolute top-[30px] left-1/2 transform -translate-x-1/2 text-2xl rounded-full p-2">
                            {uName}
                          </h1>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-78 h-auto">
                  <h1 className="text-bold mt-7 w-full text-start ml-2">
                    ข้อมูลติดต่อ{" "}
                    <span className="text-sm">
                      (สมาชิกอื่นไม่สามารถเห็นข้อมูลนี้)
                    </span>
                  </h1>
                  <div className="flex justify-around w-full h-12 items-center mt-5 border-b mb-6">
                    <p className="text-sm">เบอร์โทรศัพท์ที่ใช้ลงทะเบียน</p>
                    <p className="text-base">
                      {formatPhoneNumber(profileData.phone_number)}
                    </p>
                  </div>
                </div>
                <div className="w-78 h-8 lg:hidden">
                  <p className="text-xl text-start">ทางลัด</p>
                </div>
              </div>
            </div>
            <div className="w-80 max-sm:h-half-infinity h-auto ml-auto mr-auto">
              <div className="h-auto mb-7">
                <SidebarItem
                  to="/user/point"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/10693/10693084.png"
                  label="คอมมิชชั่น"
                />
                <SidebarItem
                  to="/profile/mypost"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/2333/2333043.png"
                  label="ประกาศของฉัน"
                />
                <SidebarItem
                  to="/profile/mylead"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/10473/10473571.png"
                  label="Lead ของฉัน"
                />
                <SidebarItem
                  to="/reference/invite"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/880/880594.png"
                  label="แนะนำเพื่อน"
                />
                <SidebarItem
                  to="/members/layer"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/9361/9361796.png"
                  label="สมาชิกของฉัน"
                />
                <SidebarItem
                  to="/profile/myshare"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/2099/2099122.png"
                  label="การแชร์ทรัพย์"
                />
                <SidebarItem
                  to="/profile/settings"
                  iconSrc="https://cdn-icons-png.flaticon.com/512/10024/10024002.png "
                  label="การตั้งค่า"
                />
              </div>
              <button
                onClick={askLogout}
                className="text-red-600 w-full flex justify-center items-center"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2529/2529508.png"
                  className="w-4 h-4"
                  alt="logout"
                />
                <h1 className="ml-2.5">ออกจากระบบ</h1>
              </button>
            </div>
          </div>
          <div className="col-span-8 opacity-90">
            <div className="hidden h-full lg:grid lg:grid-cols-12">
              <div className="col-span-8 rounded w-full h-auto flex flex-col items-center p-2.5">
                <div className="w-full bg-half-light h-72 rounded-xl grid grid-cols-12">
                  <div className="col-span-5 bg-slate-50">
                    <div className="w-full h-full flex justify-center items-center mt-28 md:mt-0">
                      <div className="w-full h-full rounded-xl">
                        <div className="w-full flex flex-col justify-center items-center h-60 mt-9">
                          <div ref={qrCodeCanvasRef}>
                            <QRCode
                              value={inviteLink}
                              bgColor="#fff"
                              size={170}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-7 h-72 bg-slate-50">
                    <div className="w-full h-full">
                      <div className="h-full flex justify-center items-center rounded-xl">
                        <div>
                          <div className="flex flex-col w-64 h-30 mx-auto text-center">
                            <h2 className="text-5xl font-bold text-black md:text-2xl">
                              แนะนำเพื่อน
                            </h2>
                            <h1 className="text-5xl font-bold text-black md:text-2xl">
                              POSTPERTY
                            </h1>
                          </div>
                          <div className="w-80 h-23 flex mr-auto ml-auto justify-center mt-14">
                            <button
                              onClick={copyLinkInvite}
                              className="text-base text-black-400 lg:w-11/12 h-14 rounded-full mr-3 border outline-1"
                            >
                              <h1>{inviteLink}</h1>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full bg-slate-50 mt-2.5 h-500px rounded-xl overflow-y-auto">
                  <div className="grid grid-cols-12">
                    {dataDisplayMyPosts.length > 0 &&
                      dataDisplayMyPosts.map((data, index) => (
                        <div
                          className="col-span-4 w-full max-h-[220px]"
                          key={index}
                        >
                          <Link
                            to={`/edit${
                              data.type === "house"
                                ? "/house"
                                : data.type === "condo"
                                ? "/condo"
                                : data.type === "land"
                                ? "/land"
                                : data.type === "commercial"
                                ? "/commercial"
                                : ""
                            }/${data.id}`}
                          >
                            <img
                              className="object-cover w-full h-full"
                              src={data.images_url.AssetImagesURL[0]}
                              alt="1"
                            />
                          </Link>
                        </div>
                      ))}
                    {dataDisplayMyPosts.length === 0 && (
                      <div className="flex justify-center items-center col-span-12 mt-2.5">
                        <p>คุณยังไม่มีโพสต์ที่ประกาศ</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:col-span-4 w-full h-auto flex flex-col items-center">
                <div className="w-full bg-slate-50 h-96 rounded-xl">
                  <div className="w-full h-28 rounded-2xl flex flex-col">
                    <p className="text-2xl text-center w-full mt-4">
                      ยอดเงินสะสมปัจจุบัน
                    </p>
                    <h1 className="text-important-green text-4xl font-medium w-full text-center h-14 mt-2">
                      {profileData.point} <span className="text-4xl text-black">฿</span>{" "}
                    </h1>
                  </div>
                  <div className="w-full h-34 bg-half-light rounded-t-3xl">
                    <div className="w-11/12 mx-auto flex flex-col">
                      <h1 className="font-medium text-base">ช่องทางการรับเงิน</h1>
                      <div className="w-full h-14 bg-half-light-2 mt-2 rounded-xl">
                        <Link to={`/user/point/bank`}>
                          <div className="h-full justify-around flex items-center">
                            <img
                              src="https://www.kasikornbank.com/SiteCollectionDocuments/about/img/logo/logo.png"
                              alt="bank-logo"
                              className="w-7 h-7"
                            />{" "}
                            <p className="font-medium text-center">เลือกช่องทาง</p>
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
                              alt="arrow"
                              className="w-3 h-3"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="mx-auto w-full flex flex-col">
                      <h1 className="font-medium text-base mt-2 ml-4">ยืนยันตัวตน</h1>
                    </div>
                    <div className="w-11/12 mx-auto flex flex-col">
                      <div className="w-full h-14 bg-half-light-2 mt-2 rounded-xl">
                        <Link to={`/user/point/history`}>
                          <div className="h-full justify-around flex items-center">
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/3503/3503786.png"
                              alt="id-card-icon"
                              className="w-7 h-7"
                            />{" "}
                            <p className="font-medium text-center">ตรวจสอบประวัติ</p>
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
                              alt="arrow"
                              className="w-3 h-3"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full bg-half-light-f5 mt-2.5 h-96 rounded-xl">
                  <h1 className="text-center text-xl mt-2.5">ประวัติธุรกรรม</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[300px] md:h-0"></div>
        </div>
      ) : (
        <div>{window.location.replace("/login")}</div>
      )}
    </div>
  );
};

export default Profiles;


// import Swal from "sweetalert2";
// import { Link } from "react-router-dom";
// import Cookies from "js-cookie";
// import axios from "axios";
// import { useState, useEffect, useRef } from "react";
// import QRCode from "qrcode.react";
// import localforage from "localforage";
// import Snowfall from 'react-snowfall';

// const Profiles = () => {
//   const getToken = () => {
//     const session_id = Cookies.get("jwt");
//     return session_id;
//   };

//   const changeProfileImage = () => {
//     console.log("change Profile");
//   };

//   const askLogout = () => {
//     Swal.fire({
//       title: "ต้องการออกจากระบบ ?",
//       text: "การที่คุณออกจากระบบจะไม่สามารถใช้งานฟังก์ชั่นบางอย่างได้!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#FF0000",
//       cancelButtonColor: "#000",
//       confirmButtonText: "ยืนยันการออกจากระบบ!",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         const removeUploadData = async () => {
//           try {
//             await localforage.removeItem("uploadData");
//           } catch (err) {
//             console.error("Error removing upload data", err);
//           }
//         };

//         await removeUploadData()
//         Swal.fire("สำเร็จ!", "ออกจากระบบเรียบร้อย", "success");
//         Cookies.remove("jwt");
//         localStorage.removeItem('token')
//         window.location.reload();
//       }
//     });
//   };

//   // const [profileData,setProfileData] = useState({})
//   const [profileData, setProfileData] = useState({
//     uname: "",
//     first_name: "",
//     last_name: "",
//     profile_image: "",
//     point: 0,
//   });

//   useEffect(() => {
//     const cookie = Cookies.get("jwt");
//     const token = `${cookie}`;

//     axios
//       .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         console.log(res.data);
//         const { uname, first_name, last_name, profile_image, phone_number } =
//           res.data;
//         setProfileData({
//           uname,
//           first_name,
//           last_name,
//           profile_image,
//           phone_number,
//         });

//         localStorage.setItem("profile_user" , profile_image)
//         // console.log(res.data);
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   }, []);

//   const formatPhoneNumber = (number) => {
//     if (!number) return "";
//     const formattedNumber = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
//     return formattedNumber;
//   };

//   const handleFileUpload = async (event) => {
//     // await setFile(event.target.files[0]);
//     const ImageUpload = event.target.files[0];
//     console.log(ImageUpload);
//     const formData = new FormData();
//     formData.append("image", ImageUpload);
//     try {
//       axios.put(`${process.env.REACT_APP_API}/update/profile-image`, formData, {
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       Swal.fire("สำเร็จ!", "เปลี่ยนรูปโปรไฟล์สำเร็จ", "success");
//       setTimeout(() => {
//         window.location.reload();
//       }, 5000); // 5000 milliseconds (5 seconds) delay
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   // QR Code

//   useEffect(() => {
//     const cookie = Cookies.get("jwt");
//     const token = `${cookie}`;
//     axios
//       .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         const { uname } = res.data;
//         setUName(uname);
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   }, []);

//   const qrCodeCanvasRef = useRef(null);
//   const [uName, setUName] = useState("");
//   const inviteLink = `postperty.com/register?ID=${uName}`;
//   const copyLinkInvite = async () => {
//     await navigator.clipboard.writeText(inviteLink);
//     Swal.fire(
//       "คัดลอกสำเร็จ",
//       "สามารถนำลิงค์นี้แชร์ให้เพื่อนของคุณได้",
//       "success"
//     );
//   };

//   // QR Code

//   // My Post

//   const [dataDisplayMyPosts, setDataDisplayMyPosts] = useState([]);
//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_API}/asset/myassets`, {
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then((res) => {
//         const data = res.data.assets;
//         console.log(data);
//         setDataDisplayMyPosts(data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   // My Post

//   // Check Login

//   const session_id = Cookies.get("jwt");
//   useEffect(() => {
//     if (session_id === undefined) {
//       window.location.replace("/login");
//     }
//   }, []);

//   // Check Login

//   let [points, setPoints] = useState(0.0);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_API}/user/getDataProfile`, {
//         headers: {
//           Authorization: `Bearer ${cookie}`,
//         },
//       })
//       .then((res) => {
//         console.log(res.data);
//         const { point } = res.data;
//         setPoints(point);
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   }, []);
//   const cookie = Cookies.get("jwt");



//   //Check jwt valid 

//   useEffect(() => {
//     axios.post(`${process.env.REACT_APP_API}/event/checkValidToken`, {
//       token: cookie
//     }, {
//       headers: {
//         Authorization: `Bearer ${cookie}`
//       }
//     }).then((res) => {
      
//       const relogin = res.data.relogin
//       const newToken = res.data.newjwt
//       if(relogin){
//         Cookies.set("jwt", newToken);
//       }

//     }).catch((err) => {
//       const statusValid = err.response.data.success;
//       console.log(statusValid)
//       if (statusValid === false) {
//         Cookies.remove("jwt");
//         window.location.replace('/login');
//       }
//     })
//   }, [])


  


//   return (
//     <div className="">
//       {getToken() !== undefined ? (
//         <div className="md:grid md:grid-cols-12   h-[1050px]  md:h-[890px]">
//           {/* <Snowfall snowflakeCount={200}/> */}
//           <div className=" w-full  md:col-span-12 lg:col-span-4  overflow-y-auto h-[1050px]   bg-white  md:mb-4 md:h-[890px] border-r-2 ">
//             <div className="h-auto mb-4">
//               <div className="text-center w-80  mb-4 mr-auto ml-auto mt-16 text-xl">
//                 <div className="flex justify-between">
//                   <h1 className="text-[18px]">โปรไฟล์ของฉัน</h1>
//                   <Link to={`/editprofile`}>
//                     <h1 className="text-[18px]   rounded-full w-24    shadow-xl ">
//                       แก้ไข
//                     </h1>
//                   </Link>
//                 </div>
//                 <div className="my-12 h-28 flex justify-around">
//                   <div className="relative" onClick={changeProfileImage}>
//                     <form>
//                       <input
//                         type="file"
//                         accept="image/*"
//                         className="border  w-full h-full opacity-0 top-0 absolute right-0"
//                         onChange={handleFileUpload}
//                       />
//                       {/* <button type="submit">Upload</button> */}
//                       <img
//                         src={`${profileData.profile_image}`}
//                         alt="img"
//                         className="w-28 h-28 rounded-full"
//                       ></img>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/44/44413.png"
//                         alt="edit-photo-icon"
//                         className="w-6 h-6 absolute bottom-0 right-3 bg-white shadow-xl rounded-full p-1"
//                       ></img>
//                     </form>
//                   </div>

//                   <div className="flex flex-col w-30   ">
//                     <div className=" mt-auto mb-auto relative ">
//                       <h1 class="text-start">{profileData.first_name + profileData.last_name}</h1>
//                       <p className="text-sm text-start">
//                         <div className="relative w-full rounded-xl  h-20  mt-2.5  bg-half-light-f5 mx-auto">
//                           <p class="text-sm w-full  text-center absolute left-1/2 tranform -translate-x-1/2 top-2 ">User ID คุณคือ</p>
//                           <h1 class="absolute top-[30px] left-1/2 tranform -translate-x-1/2 text-2xl  rouned-full p-2">{uName}</h1>
//                         </div>
//                       </p>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="w-78 h-auto">
//                   <h1 className="text-bold mt-7 w-full text-start ml-2">
//                     ข้อมูลติดต่อ <span className="text-sm">(สมาชิกอื่น ไม่สามารถเห็นข้อมูลนี้)</span>
//                   </h1>
//                   <div className="flex justify-around w-full h-12 items-center mt-5 border-b mb-6">
//                     <p className="text-sm">เบอร์โทรศัพท์ที่ใช้ลงทะเบียน</p>
//                     <p className=" text-base">
//                       {formatPhoneNumber(profileData.phone_number)}
//                     </p>
//                     {/* <img
//                     src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                     alt="arrow"
//                     className="w-3 h-3"
//                   ></img> */}
//                   </div>
//                 </div>

//                 <div className="w-78 h-8 lg:hidden">
//                   <p className="text-xl  text-start">ทางลัด</p>
//                 </div>
//               </div>
//             </div>
//             <div className="w-80 max-sm:h-half-infinity h-auto ml-auto mr-auto  ">
//               <div className=" h-auto mb-7">

//                 <Link to={`/user/point`}>
//                   <div className=" border rounded-xl mb-4  h-14 flex justify-center items-center mt-4">
//                     <div className="flex justify-around   w-full h-full items-center ">
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/10693/10693084.png"
//                         alt="my_post"
//                         className="w-7 h-7   "
//                       ></img>
//                       <h1 className=" w-1/3">คอมมิชชั่น</h1>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                         alt="arrow"
//                         className="w-3 h-3"
//                       ></img>
//                     </div>
//                   </div>
//                 </Link>

//                 <Link to={`/profile/mypost`}>
//                   <div className=" border rounded-xl mb-4  h-14 flex justify-center items-center mt-4">
//                     <div className="flex justify-around   w-full h-full items-center ">
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/2333/2333043.png"
//                         alt="my_post"
//                         className="w-7 h-7   "
//                       ></img>
//                       <h1 className="text-center w-2/5">ประกาศของฉัน</h1>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                         alt="arrow"
//                         className="w-3 h-3"
//                       ></img>
//                     </div>
//                   </div>
//                 </Link>

//                 <Link to={`/profile/mylead`}>
//                   <div className=" border rounded-xl mb-4  h-14 flex justify-center items-center mt-4">
//                     <div className="flex justify-around   w-full h-full items-center ">
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/10473/10473571.png"
//                         alt="my_post"
//                         className="w-7 h-7"
//                       ></img>
//                       <h1 className="text-center w-2/5">Lead ของฉัน</h1>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                         alt="arrow"
//                         className="w-3 h-3"
//                       ></img>
//                     </div>
//                   </div>
//                 </Link>

//                 <Link to="/reference/invite">
//                   <div className=" border rounded-xl  h-14 flex justify-center items-center ">
//                     <div className="flex justify-around    w-full h-full items-center ">
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/880/880594.png"
//                         alt="invite-friend"
//                         className="w-7 h-7   "
//                       ></img>
//                       <h1 className=" w-1/3">แนะนำเพื่อน</h1>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                         alt="arrow"
//                         className="w-3 h-3"
//                       ></img>
//                     </div>
//                   </div>
//                 </Link>

//                 <Link to={`/members/layer`}>
//                   <div className=" border rounded-xl  h-14 flex justify-center items-center mt-4">
//                     <div className="flex justify-around   w-full h-full items-center ">
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/9361/9361796.png"
//                         alt="invite-friend"
//                         className="w-7 h-7   "
//                       ></img>
//                       <h1 className=" w-1/3">สมาชิกของฉัน</h1>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                         alt="arrow"
//                         className="w-3 h-3"
//                       ></img>
//                     </div>
//                   </div>
//                 </Link>
//                 <Link to={`/profile/myshare`}>
//                   <div className=" border rounded-xl  h-14 flex justify-center items-center mt-4 cursor-pointer" >
//                     <div className="flex justify-around  w-full h-full items-center ">
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/2099/2099122.png"
//                         alt="invite-friend"
//                         className="w-7 h-7   "
//                       ></img>
//                       <h1 className=" w-1/3">การแชร์ทรัพย์</h1>
//                       <img
//                         src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                         alt="arrow"
//                         className="w-3 h-3"
//                       ></img>
//                     </div>
//                   </div>
//                 </Link>

//                 {/* <div className=" border rounded-xl  h-14 flex justify-center items-center mt-4 cursor-pointer" onClick={isDeveloping}>
//                   <div className="flex justify-around  w-full h-full items-center ">
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/512/2550/2550251.png"
//                       alt="invite-friend"
//                       className="w-7 h-7   "
//                     ></img>
//                     <h1 className=" min-w-1/3 w-auto">อุปกรณ์ที่ล็อกอิน</h1>
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                       alt="arrow"
//                       className="w-3 h-3"
//                     ></img>
//                   </div>
//                 </div> */}
//                 {/* 
//                 <div className=" border rounded-xl  h-14 flex justify-center items-center mt-4 cursor-pointer" onClick={isDeveloping}>
//                   <div className="flex justify-around  w-full h-full items-center ">
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/512/665/665049.png"
//                       alt="invite-friend"
//                       className="w-7 h-7   "
//                     ></img>
//                     <h1 className=" w-1/3">เกี่ยวกับเรา</h1>
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                       alt="arrow"
//                       className="w-3 h-3"
//                     ></img>
//                   </div>
//                 </div> */}
//               </div>

//               <button onClick={askLogout} className="text-red-600  w-full flex justify-center items-center">
//                 <img src="https://cdn-icons-png.flaticon.com/512/2529/2529508.png" className="w-4 h-4" />
//                 <h1 className="ml-2.5">ออกจากระบบ</h1>
//               </button>
//             </div>
//           </div>

//           <div className="col-span-8    opacity-90">
//             <div className="hidden h-full lg:grid lg:grid-cols-12  ">
//               <div className="col-span-8  rounded w-full h-auto flex flex-col  items-center p-2.5">
//                 <div className="w-full bg-half-light  h-72 rounded-xl grid grid-cols-12  ">
//                   <div className="col-span-5  bg-slate-50  ">
//                     <div className="w-full  h-full flex justify-center items-center mt-28  md:mt-0  ">
//                       <div className="w-full h-full    rounded-xl">
//                         <div className="w-full flex flex-col justify-center items-center h-60 mt-9">
//                           <div ref={qrCodeCanvasRef}>
//                             <QRCode
//                               value={inviteLink}
//                               bgColor="#fff"
//                               size={170}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-span-7 h-72 bg-slate-50">
//                     <div className="w-full h-full">
//                       <div className="h-full flex justify-center items-center  rounded-xl">
//                         <div className="">
//                           <div className="flex flex-col w-64 h-30 mx-auto text-center">
//                             <h2 className="text-5xl font-bold text-black md:text-2xl">
//                               แนะนำเพื่อน
//                             </h2>
//                             <h1 className="text-5xl font-bold text-black md:text-2xl">
//                               POSTPERTY
//                             </h1>
//                           </div>
//                           <div className="w-80 h-23 flex mr-auto ml-auto justify-center mt-14">
//                             <button
//                               onClick={copyLinkInvite}
//                               className="text-base text-black-400  lg:w-11/12 h-14 rounded-full mr-3 border outline-1 "
//                             >
//                               <h1>{inviteLink}</h1>
//                             </button>
//                             {/* <button
//                             className="w-24 h-15 bg-white  rounded-full text-2xl"
//                             onClick={copyLinkInvite}
//                           >
//                             <MdContentCopy className="mr-auto ml-auto" />
//                           </button> */}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="w-full bg-slate-50  mt-2.5 h-500px rounded-xl overflow-y-auto ">
//                   <div className="grid grid-cols-12">
//                     {dataDisplayMyPosts.length > 0 &&
//                       dataDisplayMyPosts.map((data, index) => (
//                         <div className="col-span-4 w-full max-h-[220px]" key={index}>
//                           <Link
//                             to={`/edit${data.type === "house"
//                               ? "/house"
//                               : data.type === "condo"
//                                 ? "/condo"
//                                 : data.type === "land"
//                                   ? "/land"
//                                   : data.type === "commercial"
//                                     ? "/commercial"
//                                     : ""
//                               }/${data.id}`}
//                           >
//                             <img
//                               className="object-cover w-full h-full"
//                               src={data.images_url.AssetImagesURL[0]}
//                               alt="1"
//                             />
//                           </Link>
//                         </div>
//                       ))}
//                     {dataDisplayMyPosts.length === 0 ? (
//                       <div className="flex justify-center items-center col-span-12 mt-2.5">
//                         <p>คุณยังไม่มีโพสต์ที่ประกาศ</p>
//                       </div>
//                     ) : null}
//                   </div>
//                 </div>
//               </div>
//               <div className="md:col-span-4  w-full h-auto flex flex-col  items-center">
//                 <div className="w-full bg-slate-50 h-96 rounded-xl">
//                   <div className="w-full  h-28 rounded-2xl flex flex-col ">
//                     <p className="text-2xl text-center w-full mt-4">
//                       ยอดเงินสะสมปัจจุบัน
//                     </p>
//                     <h1 className="text-important-green text-4xl font-medium w-full text-center h-14 mt-2">
//                       {points} <span className="text-4xl text-black">฿</span>{" "}
//                     </h1>
//                   </div>

//                   <div className="w-full h-34  bg-half-light rounded-t-3xl">
//                     <div className="w-11/12  mx-auto flex flex-col">
//                       <h1 className="font-medium text-base">
//                         ช่องทางการรับเงิน
//                       </h1>
//                       <div className="w-full h-14 bg-half-light-2  mt-2 rounded-xl">
//                         <Link to={`/user/point/bank`}>
//                           <div className=" h-full justify-around  flex items-center">
//                             <img
//                               src="https://www.kasikornbank.com/SiteCollectionDocuments/about/img/logo/logo.png"
//                               alt="bank-logo"
//                               className="w-7 h-7   "
//                             ></img>{" "}
//                             <p className="font-medium text-center ">
//                               เลือกช่องทาง
//                             </p>
//                             <img
//                               src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                               alt="arrow"
//                               className="w-3 h-3"
//                             ></img>
//                           </div>
//                         </Link>
//                       </div>
//                     </div>

//                     <div className=" mx-auto w-full flex flex-col ">
//                       <h1 className="font-medium text-base mt-2 ml-4">
//                         ยืนยันตัวตน
//                       </h1>

//                     </div>

//                     <div className="w-11/12 mx-auto  flex flex-col">
//                       <div className="w-full h-14 bg-half-light-2 mt-2 rounded-xl">
//                         <Link to={`/user/point/history`}>
//                           <div className=" h-full justify-around  flex items-center">
//                             <img
//                               src="https://cdn-icons-png.flaticon.com/512/3503/3503786.png"
//                               alt="id-card-icon"
//                               className="w-7 h-7   "
//                             ></img>{" "}
//                             <p className="font-medium text-center ">
//                               ตรวจสอบประวัติ
//                             </p>
//                             <img
//                               src="https://cdn-icons-png.flaticon.com/512/271/271228.png"
//                               alt="arrow"
//                               className="w-3 h-3"
//                             ></img>
//                           </div>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="w-full bg-half-light-f5  mt-2.5 h-96 rounded-xl">
//                   <h1 className="text-center text-xl mt-2.5">ประวัติธุรกรรม</h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="w-full h-[300px] md:h-0">

//           </div>
//         </div>
//       ) : (
//         <div>{window.location.replace("/login")}</div>
//       )}
//     </div>
//   );
// };

// export default Profiles;
